import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Function from '../../../utils/functions';

const RadioGroupAdapter = props => {
  const {
    items,
    name,
    label,
    error,
    defaultValue,
    viewMode,
    formik,
    onFieldChange,
    ...rest
  } = props;

  const { t } = useTranslation();
  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? formik.values[name] : '';
  const displayLabel = label ? label + ": " : "";

  const selectedItem = items.find(element => element.value === formik.values[name]);

  return (
    <>
      {
        viewMode ?
          <div className="view-wrapper">
            <span className="label">{displayLabel}</span>
            <span className="value">
              { selectedItem ? t(selectedItem.name) : "" }
            </span>
          </div> :
          <FormControl component="fieldset" error={isInvalid}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              name={name}
              defaultValue={defaultValue}
              value={fieldValue}
              onChange={e => {
                formik.handleChange(e);
                if (onFieldChange) {
                  onFieldChange(e);
                }
              }}
              {...rest}
            >
              {items.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary"/>}
                  label={t(item.name)}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
      }
    </>
  );
};

export default RadioGroupAdapter;
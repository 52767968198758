import { Route, Redirect } from 'react-router-dom';
import { checkAuth } from './auth';

export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    checkAuth()
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )} />
);

export const ToAuthenticateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    !checkAuth()
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  )} />
);
export function checkAuth() {
  return getToken() || getAWSToken() ? true : false;
}

export function getToken() {
  return localStorage.getItem('token');
}

export function getAWSToken() {
  return localStorage.getItem('amplify-signin-with-hostedUI');
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function setToken(token) {
  localStorage.setItem('token', token);
}

export function setUser(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getUser() {
  const user = localStorage.getItem('user');

  return user ? JSON.parse(user) : false;
}

export function getAWSUser(payload) {
  // set role
  var groups = payload.signInUserSession?.accessToken?.payload["cognito:groups"] ?? [];
  const getRole = () => {
    if (groups.includes('admin')) {
      return 'admin';
    }

    if (groups.includes('trader')) {
      return 'trader';
    }

    return 'user';
  };
  payload.role = getRole();

  // set userId
  var userId = payload.attributes ? payload.attributes["custom:user_id"] : false;
  payload.userId = userId;

  return payload;
}
import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectTopics,
  reducer,
  saga
} from '../Topics/Topics.reducer';

import {
  Button,
  Grid,
  Card,
  CardContent,
  Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Topics.scss';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'Topics';

const Topics = props => {
  const {
    topics,
    limit,
    loadTopicsRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTopicsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = React.useState(0);
  const itemPerPage = limit ? limit : 6;
  var totalPages = Number(topics.length / itemPerPage);
  totalPages = totalPages > Math.round(totalPages) ? Math.round(totalPages) + 1 : Math.round(totalPages);

  return (
    <>
      <section className={key}>
        <Grid container className="grid">
          {topics
          .slice(page * itemPerPage, page * itemPerPage + itemPerPage)
          .map((item, index) => {
            const type = Functions.getConstant(Constants.Topic.Type, item.type_code).this;

            return (

              <Grid className="item" key={index} item md={4}>

                <Button href={"/topic/" + item.topic_id}>
                  <Card>
                    <CardContent>
                      <div className="image">
                        <img alt="topics" src={Functions.getS3URL(item.image)} />
                      </div>
                      <Grid container>
                        <Grid className={"tag " + type.className} item xs>
                          {t(type.name)}
                        </Grid>
                        <Grid className="date" item xs>
                          {Functions.formatDateTime(new Date(item.updated_at), 'yyyy/MM/dd')}
                        </Grid>
                      </Grid>
                      <div className="description">{item.title}</div>
                    </CardContent>
                  </Card>
                </Button>

              </Grid>
            );
          })}
        </Grid>

        {
          !limit &&
          <div className='pagination-wrapper'>
            <Pagination
              count={totalPages}
              color="primary"
              onChange={(e, page) => {
                setPage(page - 1);
              }}
            />
          </div>
        }
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  topics: makeSelectTopics(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(Topics);
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectIsToggled,
  reducer,
  saga,
} from './AppLayout.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Footer from '../../components/Footer/Footer';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import './AppLayout.scss';

const key = 'AppLayout';

const theme = createTheme({
  palette: {
    primary: {
      main: '#203864'
    },
    default: {
      main: '#e0e0e0',
      contrastText: '#203864',
    }
  }
});

const AppLayout = props => {
  const { injectElement } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  return (
    <>
      <section className={key + " main"}>
        <ThemeProvider theme={theme}>
          <Header anchor="right" {...props} />
          <Sidebar variant="home" {...props} />
          {injectElement}
          <Footer homeVariant={true} />
        </ThemeProvider>
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isToggled: makeSelectIsToggled(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AppLayout);
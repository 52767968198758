// @flow
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  logoutRequest,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';

const key = 'AppLayout';

// Action Creators
export const actionCreators = createActions(
  'SET_TOGGLE',
  'LOGOUT_CHILD_REQUEST',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  }
);

export const {
  setToggle,
  logoutChildRequest,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  isToggled: false,
};

export const reducer = handleActions({
  [setToggle]: (state) => ({
    ...state,
    isToggled: !state.isToggled,
  }),
  [logoutChildRequest]: (state) => ({
    ...state,
  }),
  [showDialogChildRequest]: (state, { payload }) => ({
    ...state,
    values: payload,
  }),
}, initialState);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectIsToggled = () =>
  createSelector(
    originSelector,
    state => state.isToggled,
  );

export { 
  originSelector,
  makeSelectIsToggled,
};

// Redux Saga
export function* logoutChild() {
  yield put(logoutRequest());
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(logoutChildRequest, logoutChild);
  yield takeLatest(showDialogChildRequest, showDialogChild);
}
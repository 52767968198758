import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';
import {
  makeSelectUser,
  loadUserRequest,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'AccountEditPage';

// Action Creators
export const actionCreators = createActions(
  'SET_VIEW_MODE_REQUEST',
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  },
);

export const {
  setViewModeRequest,
  submitRequest,
  submitResponse,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
};

export const reducer = handleActions(
  {
    [setViewModeRequest]: (state, { payload }) => ({
      ...state,
      viewMode: payload,
    }),
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        accessToken: payload.result ? payload.data.access_token: false,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectViewMode,
};

export function* submit() {
  try {
    var values = yield select(makeSelectValues());
    const user = yield select(makeSelectUser());

    if (values.avatar) {
      // upload avatar
      const avatarKey = yield call(
        Functions.presignedUploadS3,
        Constants.General.S3Directory.Profile,
        values.file_avatar
      );
      values.avatar = avatarKey;
    }

    // store
    values.avatar = values.avatar ?
      values.avatar :
      user.userData.avatar;
    const data = yield call(
      Functions.makeRequestCall,
      'PUT',
      'users/' + user.userData.user_id,
      {
        body: values
      }
    );

    if (data.result) {
      yield put(loadUserRequest());
      yield put(showDialogChildRequest(values.successPayload));
      yield put(setViewModeRequest(true));
    }

    // handle response
    yield put(submitResponse(data));

  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(submitRequest, submit);
  yield takeLatest(showDialogChildRequest, showDialogChild);
}
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectOperation,
  makeSelectTrader,
  reducer,
  saga,
} from './UnfollowPage.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './UnfollowPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import PriceField from '../../components/PriceField/PriceField';
import MT5Accounts from '../../components/MT5Accounts/MT5Accounts';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import * as Auths from '../../utils/auth';

const key = 'UnfollowPage';

const UnfollowPage = props => {
  const {
    loading,
    match,
    operation,
    loadTraderRequest,
  } = props;

  const { t } = useTranslation();
  
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTraderRequest(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noTransferSteps = [
    t(key + '.0.step0'),
    t(key + '.0.step1'),
    t(key + '.0.step2'),
  ];
  const noTransferPanels = [
    <Panel0Element
      {...props}
    />,
    <Panel1Element
      {...props}
    />,
    <Panel2Element
      {...props}
    />,
  ];
  const transferBetweenSteps = [
    t(key + '.1.step0'),
    t(key + '.1.step1'),
    t(key + '.1.step2'),
    t(key + '.1.step3'),
    t(key + '.1.step4'),
  ];
  const transferBetweenPanels = [
    <Panel0Element
      {...props}
    />,
    <Panel3Element
      {...props}
    />,
    <Panel4Element
      {...props}
    />,
    <Panel5Element
      {...props}
    />,
    <Panel2Element
      {...props}
    />,
  ];
  const withdrawSteps = [
    t(key + '.2.step0'),
    t(key + '.2.step1'),
    t(key + '.2.step2'),
    t(key + '.2.step3'),
    t(key + '.2.step4'),
  ];
  const withdrawPanels = [
    <Panel0Element
      {...props}
    />,
    <Panel6Element
      {...props}
    />,
    <Panel7Element
      {...props}
    />,
    <Panel8Element
      {...props}
    />,
    <Panel2Element
      {...props}
    />,
  ];

  var steps = noTransferSteps;
  var panels = noTransferPanels;
  switch (operation) {
    case "a":
      steps = noTransferSteps;
      panels = noTransferPanels;
      break;
    case "b":
      steps = transferBetweenSteps;
      panels = transferBetweenPanels;
      break;
    case "c":
      steps = withdrawSteps;
      panels = withdrawPanels;
      break;
    default:
      break;
  }

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="follow">
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={steps}
            stepPanels={panels}
            notBackStep={1}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

UnfollowPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  operation: makeSelectOperation(),
  user: makeSelectUser(),
  trader: makeSelectTrader(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(UnfollowPage);

const Panel0Element = props => {
  const {
    match,
    formData,
    setOperationRequest,
    nextStepRequest,
  } = props;

  const { t } = useTranslation();
  var rawFollowing = Auths.getUser()?.followings?.filter(x => x.trader_id === match.params.id)[0];
  var following = rawFollowing ? rawFollowing : {};
  following.details = Auths.getUser()?.accounts?.filter(x => x.mt5_login === following.account)[0];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      action: "a",
      trader_id: following.trader_id,
      following: following,
      from_account: following?.account,
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel0_0')}</p>

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label=""
            name="action"
            color="primary"
            items={[
              { name: t('no_tranfer_fund'), value: "a" },
              { name: t('transfer_fund'), value: "b" },
              { name: t('withdraw_fund'), value: "c" },
            ]}
            formik={formik}
            onFieldChange={(event) => setOperationRequest(event.target.value)}
          /> 
        </div>

        <div className="action-group">
          <Button
            className="backButton"
            color="default"
            variant="contained"
            onClick={()=> {window.top.close();}}
          >
            {t('cancel')}
          </Button>
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = props => {
  const {
    trader,
    formData,
    submitRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      trader_id: trader?.trader_id,
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel1_0')}</p>

        <p>{t('nickname')}</p>
        <p className="number">{formData.following?.trader_nickname}</p>
        <p>{t('account')}</p>
        <p className="number">{formData.following?.account}</p>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_application')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel2Element = props => {
  const {
    trader,
    formData,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <form className="main-form">
        <p>{t(key + '.panel2_0')}</p>
        <p>{t(key + '.panel2_1')}</p>
        <p>{t(key + '.panel2_2')}{trader.nickname}</p>
        <p>{t(key + '.panel2_3')}{formData.following?.account}</p>
        {
          formData.action === "b" &&
          <>
            <p>{t(key + '.panel2_4')}{formData.from_account}</p>
            <p>{t(key + '.panel2_5')}{formData.to_account}</p>
            <p>{t(key + '.panel2_6')}{formData.amount_formatted_value}</p>
            <p>{t(key + '.panel2_7')}</p>
            <p>{t(key + '.panel2_8')}</p>
          </>
        }
        {
          formData.action === "c" &&
          <>
            <p>{t(key + '.panel2_9')}{formData.amount_formatted_value}</p>
            {
              formData.withdrawal_destination_code === Constants.General.WithdrawalDestination.Bitwallet.code ?
              <>
                <p>{t(key + '.panel2_10')}{formData.bitwallet_mail}</p>
              </> :
              <>
                <p>{t(key + '.panel2_11')}{formData.bank_name}</p>
                <p>{t(key + '.panel2_12')}{formData.branch_name}</p>
                <p>{t(key + '.panel2_13')}{formData.deposit_type}</p>
                <p>{t(key + '.panel2_14')}{formData.account_number}</p>
                <p>{t(key + '.panel2_15')}{formData.account_holder}</p>
              </>
            }
            <p>{t(key + '.panel2_16')}</p>
            <p>{t(key + '.panel2_17')}</p>
          </>
        }

        <p>{t(key + '.panel2_18')}</p>
        <p>{t(key + '.panel2_19')}</p>

        <div className="action-group">
          <Button
            onClick={()=> {window.location.replace('/ranking')}}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>

    </>
  );
};
const Panel3Element = props => {
  const {
    formData,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      to_account: accounts[0]?.mt5_login ? accounts[0]?.mt5_login : 0,
    }, formData),
    validationSchema: Yup.object({
      // to_account: Yup.mixed()
      //   .required(t('Validation.required'))
      //   .notOneOf(
      //     [formData.from_account],
      //     t(key + '.m_0')
      //   ),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  const alert = formik.errors.to_account ? {
    type: "error",
    message: formik.errors.to_account,
  } : false;
  
  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <Adapter.AlertAdapter alert={alert} />
        </div>
        <p>{t(key + '.panel3_0')}</p>
        <p className="number">{formData.following?.account}</p>
        <p>{t(key + '.panel3_1')}</p>

        <MT5Accounts
          formik={formik}
          fieldName={"to_account"}
        />

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel4Element = props => {
  const {
    formData,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      amount_unit: "￥",
      amount: "",
    }, formData),
    validationSchema: Yup.object({
      amount: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
       nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel4_0')}</p>

        <p>{t(key + '.panel4_1')}</p>
        <p className="number">{formData.following?.account}</p>
        <p>{t(key + '.panel4_2')}</p>
        <p className="number">{Functions.thousandSeparator(formData.following?.details?.mt5_balance)}</p>
        <br />

        <p>{t(key + '.panel4_3')}</p>
        <p className="number">{formData.to_account}</p>

        <div className="form-input price-input">
          <PriceField
            label={t('transfer_amount')}
            name="amount"
            formik={formik}
            descriptions={[
              t(key + '.panel4_4'),
              t(key + '.panel4_5'),
            ]}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel5Element = props => {
  const {
    trader,
    formData,
    submitRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel5_0')}</p>

        <p>{t(key + '.panel5_1')}</p>
        <p className="number">{trader.nickname}</p>
        <p>{t(key + '.panel5_2')}</p>
        <p className="number">{formData.from_account}</p>
        <br />

        <p>{t(key + '.panel5_3')}</p>
        <p className="number">{formData.to_account}</p>
        <br />
        <p>{t(key + '.panel5_4')}</p>
        <p className="number">{formData.amount_formatted_value}</p>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_application')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel6Element = props => {
  const {
    formData,
    confirming,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      withdrawal_destination_code: Constants.General.WithdrawalDestination.Bitwallet.code,
      bitwallet_mail: "",
      bank_name: "",
      branch_name: "",
      deposit_type: "",
      account_number: "",
      account_holder: "",
    }, formData),
    validationSchema: Yup.object({
      withdrawal_destination_code: Yup
        .string()
        .required(t('Validation.required')),
      bitwallet_mail: Yup
        .string()
        .when('withdrawal_destination_code', {
          is: Constants.General.WithdrawalDestination.Bitwallet.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      bank_name: Yup
        .string()
        .when('withdrawal_destination_code', {
          is: Constants.General.WithdrawalDestination.BankAccount.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      branch_name: Yup
        .string()
        .when('withdrawal_destination_code', {
          is: Constants.General.WithdrawalDestination.BankAccount.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      deposit_type: Yup
        .string()
        .when('withdrawal_destination_code', {
          is: Constants.General.WithdrawalDestination.BankAccount.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      account_number: Yup
        .string()
        .when('withdrawal_destination_code', {
          is: Constants.General.WithdrawalDestination.BankAccount.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      account_holder: Yup
        .string()
        .when('withdrawal_destination_code', {
          is: Constants.General.WithdrawalDestination.BankAccount.code,
          then: Yup.string().required(t('Validation.required'))
        }),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel6_0')}</p>

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t(key + '.f_withdrawal_destination')}
            name="withdrawal_destination_code"
            color="primary"
            row
            items={Functions.getConstant(Constants.General.WithdrawalDestination).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        {
          formik.values.withdrawal_destination_code === Constants.General.WithdrawalDestination.Bitwallet.code ?
          <>
            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="text"
                label={t(key + '.f_bitwallet_mail')}
                name="bitwallet_mail"
                variant="filled"
                placeholder="youremail@email.com"
                viewMode={confirming}
                formik={formik}
              />
            </div>
          </> :
          <>
            <p>{t(key + '.panel6_1')}</p>
            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('bank_name')}
                name="bank_name"
                variant="filled"
                placeholder={t('ex_bank_name')}
                viewMode={confirming}
                formik={formik}
              />
            </div>
            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('branch_name')}
                name="branch_name"
                variant="filled"
                placeholder={t('ex_branch_name')}
                viewMode={confirming}
                formik={formik}
              />
            </div>
            <div className="form-input width-25">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('deposit_type')}
                name="deposit_type"
                variant="filled"
                placeholder={t('ex_deposit_type')}
                viewMode={confirming}
                formik={formik}
              />
            </div>
            <div className="form-input width-25">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('account_number')}
                name="account_number"
                variant="filled"
                placeholder="9401234"
                viewMode={confirming}
                formik={formik}
              />
            </div>
            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('account_holder')}
                name="account_holder"
                variant="filled"
                placeholder={t('ex_account_holder')}
                viewMode={confirming}
                formik={formik}
              />
            </div>
          </>
        }
        
        <div className="form-input">
          <p>{t(key + '.panel6_2')}</p>
          <Link href="https://gs-ltd.hk/ja/faq/" target="_blank">https://gs-ltd.hk/ja/faq/</Link>
          <p>{t(key + '.panel6_3')}</p>
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {confirming ? t('v_application') : t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel7Element = props => {
  const {
    formData,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      amount_unit: "￥",
      amount: "",
    }, formData),
    validationSchema: Yup.object({
      amount: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel7_0')}</p>
        
        <p>{t(key + '.panel7_1')}</p>
        <p className="number">{formData.from_account}</p>

        <p>{t(key + '.panel7_2')}</p>
        <p className="number">{Functions.thousandSeparator(formData.following?.details?.mt5_balance)}</p>

        <div className="form-input price-input">
          <PriceField
            label={t('withdrawal_amount')}
            name="amount"
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel8Element = props => {
  const {
    trader,
    formData,
    submitRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel8_0')}</p>

        <p>{t(key + '.panel8_1')}</p>
        <p className="number">{trader.nickname}</p>
        <p>{t(key + '.panel8_2')}</p>
        <p className="number">{formData.amount_formatted_value}</p>
        {
          formData.withdrawal_destination_code === Constants.General.WithdrawalDestination.Bitwallet.code ?
          <>
            <p>{t(key + '.panel8_3')}{formData.bitwallet_mail}</p>
          </> :
          <>
            <p>{t(key + '.panel8_4')}{formData.bank_name}</p>
            <p>{t(key + '.panel8_5')}{formData.branch_name}</p>
            <p>{t(key + '.panel8_6')}{formData.deposit_type}</p>
            <p>{t(key + '.panel8_7')}{formData.account_number}</p>
            <p>{t(key + '.panel8_8')}{formData.account_holder}</p>
          </>
        }

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_application')}
          </Button>
        </div>
      </form>
    </>
  );
};
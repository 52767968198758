import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';
import {
  setActiveStep,
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
  chargeFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import * as Functions from '../../utils/functions';
import * as Auths from '../../utils/auth';

const key = 'AdditionalAccountCreatePage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'NEXT_STEP_REQUEST',
  'BACK_STEP_REQUEST',
  'SET_CONFIRM_REQUEST',
  'SHOW_DIALOG_CHILD_REQUEST',
  'SET_STEP_REQUEST',
  {
    prefix: key,
  },
);

export const {
  submitRequest,
  submitResponse,
  nextStepRequest,
  backStepRequest,
  setConfirmRequest,
  showDialogChildRequest,
  setStepRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
  confirming: false,
};

export const reducer = handleActions(
  {
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [nextStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [backStepRequest]: (state) => ({
      ...state,
    }),
    [setConfirmRequest]: (state, { payload }) => ({
      ...state,
      confirming: payload,
    }),
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [setStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );
const makeSelectConfirming = () =>
  createSelector(
    originSelector,
    state => state.confirming,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectViewMode,
  makeSelectConfirming,
};

export function* submit() {
  try {
    // charge data
    const values = yield select(makeSelectValues());
    yield put(chargeFormData(values));

    var formData = yield select(makeSelectFormData());
  
    // store
    formData.email = Auths.getUser().email;
    const data = yield call(
      Functions.makeRequestCall,
      'POST',
      'accounts/additional-account/',
      {
        body: formData
      }
    );

    if (data.result) {
      yield put(nextStepRequest());
    }
  
    yield put(submitResponse(data));
  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* nextStep() {
  const values = yield select(makeSelectValues());
  yield put(chargeFormData(values));

  var activeStep = yield select(makeSelectActiveStep());
  yield put(setActiveStep(activeStep + 1));

  window.scrollTo(0, 0);
}

export function* backStep() {
  var activeStep = yield select(makeSelectActiveStep());
  if (activeStep === 2) {
    yield put(setConfirmRequest(false));
  }
  yield put(setActiveStep(activeStep - 1));
  
  window.scrollTo(0, 0);
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* setStep() {
  const values = yield select(makeSelectValues());
  yield put(setActiveStep(values));
}

export function* saga() {
  yield takeLatest(submitRequest, submit);
  yield takeLatest(nextStepRequest, nextStep);
  yield takeLatest(backStepRequest, backStep);
  yield takeLatest(showDialogChildRequest, showDialogChild);
  yield takeLatest(setStepRequest, setStep);
}
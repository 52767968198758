import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  reducer,
  saga,
} from '../MT5Accounts/MT5Accounts.reducer';

import { useTranslation } from 'react-i18next';

import './MT5Accounts.scss';
import * as Auths from '../../utils/auth';
import * as Functions from '../../utils/functions';
import * as Adapter from '../../components/adapters';

const key = 'MT5Accounts';

const MT5Accounts = props => {
  const {
    formik,
    fieldName,
    onFieldChange,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];

  return (
    <>
      <section className={key}>
        <Adapter.TableAdapter 
          columns={[
            {
              id: 'mt5_login',
              numeric: false,
              disablePadding: false,
              label: t("account"),
            },
            {
              id: 'mt5_balance',
              numeric: false,
              disablePadding: false,
              label: t("balance"),
              customRender: (value, row) => {
                return (
                  <>
                    {Functions.thousandSeparator(value)}
                  </>
                );
              }
            },
            {
              id: 'following',
              numeric: false,
              disablePadding: false,
              label: t("followings"),
              customRender: (value, row) => {
                return (
                  <>
                    {
                      value ?
                      value.trader_nickname :
                      t("Message.unfollowed")
                    }
                  </>
                );
              }
            },
          ]}
          rows={accounts}
          usePagination
          paginationThreshold={5}
          idField="mt5_login"
          radioSelection={fieldName}
          formik={formik}
          onFieldChange={onFieldChange}
        />
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(MT5Accounts);
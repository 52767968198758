import React from 'react';
import {
  TextField,
  IconButton,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';

import * as Function from '../../../utils/functions';

const TextFieldAdapter = props => {
  const {
    name,
    label,
    onFieldChange,
    error,
    formik,
    viewMode,
    type,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleSetShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    event.target.blur();
    // var value = event.target.value;
    // event.target.value = '';
    // event.target.value = value;
  };
  const PasswordIcon = () => {
    return (
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleSetShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    );
  };

  const isPasswordField = type === 'password';
  const passwordType = showPassword ? 'text' : 'password';

  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? formik.values[name] : '';
  const displayLabel = label ? label + ": " : "";

  return (
    <>
      {
        viewMode ?
          <div className="view-wrapper">
            <span className="label">{displayLabel}</span>
            <span className="value">{fieldValue}</span>
          </div> :
          <TextField
            name={name}
            label={label}
            value={fieldValue}
            onChange={e => {
              formik.handleChange(e);
              if (onFieldChange) {
                onFieldChange(e);
              }
            }}
            error={isInvalid}
            helperText={helperText}
            InputProps={{
              endAdornment: isPasswordField ? <PasswordIcon /> : <></>,
              type: isPasswordField ? passwordType : type,
            }}
            fullWidth
            {...rest}
          />
      }
    </>
  );
};

export default TextFieldAdapter;
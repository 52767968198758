import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  makeSelectFormData,
  chargeFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import {
  setActiveStep,
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';

import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'RegisterPage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'NEXT_STEP_REQUEST',
  'BACK_STEP_REQUEST',
  'SET_CONFIRM_REQUEST',
  {
    prefix: key,
  },
);

export const {
  submitRequest,
  submitResponse,
  nextStepRequest,
  backStepRequest,
  setConfirmRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  confirming: false,
};

export const reducer = handleActions(
  {
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
    }),
    [submitResponse]: {
      next: (state) => ({
        ...state,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload.alert,
        loading: false,
      }),
    },
    [nextStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [backStepRequest]: (state) => ({
      ...state,
    }),
    [setConfirmRequest]: (state, { payload }) => ({
      ...state,
      confirming: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectConfirming = () =>
  createSelector(
    originSelector,
    state => state.confirming,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectConfirming,
};

export function* submit() {
  try {
    // charge data
    const values = yield select(makeSelectValues());
    yield put(chargeFormData(values));

    var formData = yield select(makeSelectFormData());

    // upload documents
    var identificationKeys = []
    for (let i = 0; i < formData.files_identification.length; i++) {
      let key = yield call(
        Functions.presignedUploadS3,
        Constants.General.S3Directory.Document,
        formData.files_identification[i]
      );
      identificationKeys.push(key);
    }
    var confirmDocumentKeys = []
    for (let i = 0; i < formData.files_confirm_document.length; i++) {
      let key = yield call(
        Functions.presignedUploadS3,
        Constants.General.S3Directory.Document,
        formData.files_confirm_document[i]
      );
      confirmDocumentKeys.push(key);
    }

    // store data
    const password = Functions.generatePassword();
    formData.identifications = identificationKeys;
    formData.confirm_documents = confirmDocumentKeys;
    formData.password = password;
    console.log(formData);
    const data = yield call(
      Functions.makeRequestCall,
      'POST',
      'users',
      {
        body: formData,
      }
    );

    if (data.result) {
      yield put(nextStepRequest());
    }
  
    yield put(submitResponse(data));
  } catch (e) {
    e.alert = {
      type: 'throw',
      code: "register_failed",
      message: "登録が失敗しました。入力メールアドレスを確認してください。",
    }
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* nextStep() {
  const values = yield select(makeSelectValues());
  yield put(chargeFormData(values));

  var activeStep = yield select(makeSelectActiveStep());
  yield put(setActiveStep(activeStep + 1));

  window.scrollTo(0, 0);
}
export function* backStep() {
  var activeStep = yield select(makeSelectActiveStep());
  if (activeStep === 2) {
    yield put(setConfirmRequest(false));
  }
  yield put(setActiveStep(activeStep - 1));

  window.scrollTo(0, 0);
}

export function* saga() {
  yield takeLatest(submitRequest, submit);
  yield takeLatest(nextStepRequest, nextStep);
  yield takeLatest(backStepRequest, backStep);
}
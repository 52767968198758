import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';

import request from '../../utils/request';
import * as Functions from '../../utils/functions';

const key = 'AdminUserShowPage';

// Action Creators
export const actionCreators = createActions(
  'SET_VIEW_MODE_REQUEST',
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'LOAD_USER_REQUEST',
  'LOAD_USER_RESPONSE',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  },
);

export const {
  setViewModeRequest,
  submitRequest,
  submitResponse,
  loadUserRequest,
  loadUserResponse,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
  user: false,
};

export const reducer = handleActions(
  {
    [setViewModeRequest]: (state, { payload }) => ({
      ...state,
      viewMode: payload,
    }),
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        accessToken: payload.result ? payload.data.access_token: false,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [loadUserRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      user: false,
    }),
    [loadUserResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          return {
            ...state,
            user: payload.data,
            loading: false,
          };
        }
        return {
          ...state,
          user: false,
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          user: false,
          alert: payload,
          loading: false,
        };
      },
    },
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

const makeSelectUser = () =>
  createSelector(originSelector, state => state.user);

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectViewMode,
  makeSelectUser,
};

export function* submit() {
  try {

    const values = yield select(makeSelectValues());
    const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/login';
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(values),
    };

    // hit api
    const data = yield call(request, requestURL, requestOptions);

    // handle response
    yield put(submitResponse(data));

  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* loadUser() {
  try {
    const values = yield select(makeSelectValues());
    const data = yield call(
      Functions.makeRequestCall,
      'GET',
      'users/' + values,
      {}
    );

    yield put(loadUserResponse(data));
  } catch (e) {
    console.log('response', e);
    yield put(loadUserResponse(e));
  }
}


export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(submitRequest, submit);
  yield takeLatest(showDialogChildRequest, showDialogChild);
  yield takeLatest(loadUserRequest, loadUser);
}
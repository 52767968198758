import React from 'react';
import {
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
} from '@mui/material';

import * as Function from '../../../utils/functions';

const RadioAdapter = props => {
  const {
    name,
    label,
    value,
    error,
    formik,
    viewMode,
    onFieldChange,
    labelClassName,
    ...rest
  } = props;

  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const displayLabel = label ? label + ": " : "";

  const isChecked = String(formik.values[name]) === String(value) ? true : false;

  const WrapperElement = () => {

    const RadioElement = () => {
      return (
        <Radio
          name={name}
          color="primary"
          checked={isChecked}
          value={value}
          onChange={e => {
            formik.handleChange(e);
            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
          {...rest}
        />
      )
    }

    return (
      label ?
      <FormControl
        error={isInvalid}
      >
        <FormControlLabel
          className={labelClassName}
          control={RadioElement}
          label={label ? label : ""}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl> :
      <RadioElement />
    );
  }

  return (
    <>
      {
        viewMode ?
        <div className="view-wrapper">
          <span className="label">{displayLabel}</span>
          <span className="value">{ Boolean(formik.values[name]) }</span>
        </div> :
        <WrapperElement />
      }
    </>
  );
};

export default RadioAdapter;
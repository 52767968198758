import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';

import * as Function from '../../../utils/functions';

const SelectAdapter = props => {
  const {
    name,
    label,
    error,
    viewMode,
    items,
    formik,
    onFieldChange,
    variant,
    ...rest
  } = props;

  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? formik.values[name] : '';
  const displayLabel = label ? label + ": " : "";

  const selectedItem = items.find(element => element.value === formik.values[name]);

  return (
    <>
      {
        viewMode ?
          <div className="view-wrapper">
            <span className="label">{displayLabel}</span>
            <span className="value">
              { selectedItem ? selectedItem["label"] : "" }
            </span>
          </div> :
          <FormControl variant={variant} error={isInvalid} fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              name={name}
              value={fieldValue}
              onChange={e => {
                formik.handleChange(e);
                if (onFieldChange) {
                  onFieldChange(e);
                }
              }}
              {...rest}
            >
              {items.map((item, index) => (
                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
      }
    </>
  );
};

export default SelectAdapter;
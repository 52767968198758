import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  makeSelectFormData,
  makeSelectUser,
  chargeFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';
import {
  setActiveStep,
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';

import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import * as Auths from '../../utils/auth';

const key = 'TraderCreatePage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'LOAD_MT5_LOGINS_REQUEST',
  'LOAD_MT5_LOGINS_RESPONSE',
  'NEXT_STEP_REQUEST',
  'BACK_STEP_REQUEST',
  'SET_CONFIRM_REQUEST',
  'SHOW_DIALOG_CHILD_REQUEST',
  'SET_STEP_REQUEST',
  {
    prefix: key,
  },
);

export const {
  submitRequest,
  submitResponse,
  loadMt5LoginsRequest,
  loadMt5LoginsResponse,
  nextStepRequest,
  backStepRequest,
  setConfirmRequest,
  showDialogChildRequest,
  setStepRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  mt5Logins: [],
  viewMode: true,
  confirming: false,
};

export const reducer = handleActions(
  {
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
    }),
    [submitResponse]: {
      next: (state) => ({
        ...state,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload.alert,
        loading: false,
      }),
    },
    [loadMt5LoginsRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      mt5Logins: [],
    }),
    [loadMt5LoginsResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          return {
            ...state,
            mt5Logins: payload.data,
            loading: false,
          };
        }
        return {
          ...state,
          mt5Logins: [],
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          mt5Logins: [],
          alert: payload,
          loading: false,
        };
      },
    },
    [nextStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [backStepRequest]: (state) => ({
      ...state,
    }),
    [setConfirmRequest]: (state, { payload }) => ({
      ...state,
      confirming: payload,
    }),
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [setStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectMt5Logins = () =>
  createSelector(
    originSelector,
    state => state.mt5Logins,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

const makeSelectConfirming = () =>
  createSelector(
    originSelector,
    state => state.confirming,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectMt5Logins,
  makeSelectViewMode,
  makeSelectConfirming,
};

export function* submit() {
  try {
    // charge data
    const values = yield select(makeSelectValues());
    yield put(chargeFormData(values));

    const user = yield select(makeSelectUser());
    var formData = yield select(makeSelectFormData());

    // upload documents
    const avatarKey = yield call(
      Functions.presignedUploadS3,
      Constants.General.S3Directory.Profile,
      formData.file_avatar
    );

    // store data
    formData.trader_id = user.userData.user_id;
    formData.email = user.userData.email;
    formData.avatar = avatarKey;
    const respose = yield call(
      Functions.makeRequestCall,
      'POST',
      'traders',
      {
        body: formData,
      }
    );

    if (respose.result) {
      // fetch mt5 data
      yield call(
        Functions.makeRequestCall,
        'POST',
        'mt5-fetch/' + formData.trader_id,
        {
          useSkippedRequest: true,
          body: formData,
        }
      );

      yield put(nextStepRequest());
    }
  
    yield put(submitResponse(respose));
  } catch (e) {
    e.alert = {
      type: 'throw',
      message: "登録が失敗しました。",
    }
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* loadMt5Logins() {
  try {
    const user = Auths.getUser();
    
    var response = yield call(
      Functions.makeRequestCall,
      'GET',
      'mt5-login?email=' + user.email,
      {}
    );

    yield put(loadMt5LoginsResponse(response));
  } catch (e) {
    console.log('response', e);
    yield put(loadMt5LoginsResponse(e));
  }
}

export function* nextStep() {
  const values = yield select(makeSelectValues());
  yield put(chargeFormData(values));

  var activeStep = yield select(makeSelectActiveStep());
  yield put(setActiveStep(activeStep + 1));

  window.scrollTo(0, 0);
}

export function* backStep() {
  var activeStep = yield select(makeSelectActiveStep());
  if (activeStep === 1) {
    yield put(setConfirmRequest(false));
  }
  yield put(setActiveStep(activeStep - 1));
  
  window.scrollTo(0, 0);
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* setStep() {
  const values = yield select(makeSelectValues());
  yield put(setActiveStep(values));
}

export function* saga() {
  yield takeLatest(submitRequest, submit);
  yield takeLatest(loadMt5LoginsRequest, loadMt5Logins);
  yield takeLatest(nextStepRequest, nextStep);
  yield takeLatest(backStepRequest, backStep);
  yield takeLatest(showDialogChildRequest, showDialogChild);
  yield takeLatest(setStepRequest, setStep);
}
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectTrader,
  reducer,
  saga,
} from './FollowPage.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './FollowPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Auths from '../../utils/auth';
import PriceField from '../../components/PriceField/PriceField';
import MT5Accounts from '../../components/MT5Accounts/MT5Accounts';

const key = 'FollowPage';

const FollowPage = props => {
  const {
    loading,
    match,
    loadTraderRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTraderRequest(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="follow">
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
              t(key + '.step3'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}  
              />,
              <Panel2Element
                {...props}
              />,
              <Panel3Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

FollowPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  user: makeSelectUser(),
  trader: makeSelectTrader(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(FollowPage);

const Panel0Element = props => {
  const {
    formData,
    nextStepRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      account: accounts[0]?.mt5_login ? accounts[0]?.mt5_login : 0,
    }, formData),
    validationSchema: Yup.object({
      // account: Yup
      // .string()
      // .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel0_0')}</p>

        <MT5Accounts
          formik={formik}
          fieldName={"account"}
        />

        <div className="action-group">
          <Button
            onClick={() => { window.top.close(); }}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t("v_cancel")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("v_next")}
          </Button>
        </div>

      </form>
    </>
  );
};
const Panel1Element = props => {
  const {
    formData,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      amount_unit: "￥",
      amount: "",
    }, formData),
    validationSchema: Yup.object({
      amount: Yup
      .string()
      .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel1_0')}</p>

        <p>{t("account")}</p>
        <p className="number">{formData.account}</p>

        <div className="form-input half-width">
          <PriceField
            label={t(key + '.f_amount')}
            name="amount"
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t("v_back")}
          </Button>

          <Button type="submit" variant="contained" color="primary">
            {t("v_next")}
          </Button>
        </div>

      </form>
    </>
  );
};
const Panel2Element = props => {
  const {
    user,
    trader,
    formData,
    submitRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();
  
  const formik = useFormik({
    initialValues: Functions.fillFormData({
      agreement: false,
      trader_id: trader?.trader_id,
    }, formData),
    validationSchema: Yup.object({
      agreement: Yup
        .bool()
        .isTrue(t('Validation.required')),
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="agreement">
          <Grid container className="wrapper">
            <Grid className="item" item sm>
              <strong>Limited Power of Attorney Form 限定委任状（LPOA）フォーム</strong>
            </Grid>
            <Grid className="item logo" item sm>
              <img width={200} src="/images/agreement_logo.png" alt="agreement_logo" />
            </Grid>
          </Grid>

          <br />
          <p>Managed Account Authorization and Risk Disclosure</p>
          <p>運用口座の委任およびリスク開示</p>
          <br />
          <p>
            All Customers who designate an Authorized Trading Agent,
            defined as any person other than Customer, who is given the ability to control,
            manage or direct trading decisions in the Account (as defined below),
            must read and agree to the following document by checking the checkbox before Authorization
            will be granted by GLOBAL SEEK LIMITED LIMITED.
          </p>
          <p>
            お客様が、ご自身以外の第三者を、以下で定義されるように、
            口座において売買決定を管理、運用、あるいは指示できる権利を与えられた取引代理人として指名される場合、
            GLOBAL SEEK LIMITED による委任承認のために、
            以下の文書をお読みの上、チェックボックスにチェックして同意する必要があります。
          </p>
          <p>The Undersigned Customer(s) authorizes:</p>
          <p>お客様は以下の通り委任します：</p>
          <br />
          <p>
            I/we designate Trading Agent {trader?.nickname} as
            Agent and attorney attorney-in -fact for the purpose of buying and selling
            margined foreig foreign currency lots for the undersigned Customer’s Account
            (the “Account”), and at the Customer’s sole risk through GLOBAL SEEK LIMITED ,
            (hereafter referred as “ GST ”). The Customer hereby indemnifi es and holds harmless GST for losses,
            indebtedness, an and all other liabilities that arise directly or indirectly therefore.
          </p>
          <p>
            お客様は、取引代理人の {trader?.nickname} を、
            お客様のみのリスクで、お客様の口座（以下では「口座」とする）において
            GLOBAL SEEK LIMITED (以下では「 GST 」とする)を通した外国為替証拠金を売買する目的で、
            代理人として指定します。お客様は本契約により、ここから直接または間接に生じる損失、
            負債、その他全ての不利益を補償し、GST に何ら損害が与ばないことに同意します
          </p>
          <br />
          <p>
            Customer acknowledges and understands that GST is hereby being given authorization
            to follow the instructions of the authorized Customer’s Trading Agent (the “Agent”)
            in every respect with regard to the Customer’s foreign currency trading Account with
            GST except for the non non-trading withdrawal of any money, securities, collateral or
            any other asset that is in the name of the Customer. GST will not in any way be liable
            or responsible for any buy or sell trading actions done on the Customer’s behalf by the
            Agent or for any losses incurred by the Agent for the Custome r’s Account. Further, GST
            does not endorse the Agent nor does it vouch for the background, or experience of the Customer’s
            Trading Agent, nor d oes GST endorse any operating methods used by the Customer’s Trading Agent,
            including when trading for the Customer’s Account.
          </p>
          <p>
            お客様は本契約により、お客様名義の金銭、証券、担保、その他の資産の取引と関係ない出金を除き、
            お客様の外国為替証拠金取引の口座に関わる全てにおいて、 GST がお客様の委任した取引代理人（以下では「代理人」とする）
            の指示に従う権限を与えられたと承諾および理解します。 GST は、代理人がお客様に代わって実行した売買に対して、
            また代理人によって生じたお客様の口座の損失に対して、一切責任を負いません。 GST は、お客様の代理人を支持したり、
            代理人の経歴や経験を保証したりいたしません。また GST は、お客様の口座のために売買する場合を含め、
            代理人が用いる運用手法を支持することもいたしません。
          </p>
          <br />
          <p>
            Customer acknowledges and understands that GST may provide the Trading Agent with monetary
            compensation for purchases and sales done on behalf of the Customer’s Account on a per trade basis.
            As such, Customer acknowledges that such purchases and sales by the Agent may create a conflict of interest,
            as the Agent may have a financial incentive to trade, including on behalf of the Customer’s Account.
            Customer acc epts this conflict by GST and Trading Agent and waives all objections to such an arrangement.
          </p>
          <p>
            お客様は、取引代理人がお客様の口座のために実行した売買を理由に、GST が代理人に取引に基づく金銭的報酬を支払う場合があることを、
            承諾および理解します。このため、お客様は、お客様の口座のための取引も含め、
            代理人が取引に金銭的誘引を持つ場合があるため、代理人による売買が利益相反をもたらす可能性があることを承諾します。
            お客様は、GST と取引代理人によるこの利益相反を容認し、そうした取り決めに対する異議申し立てを全て放棄します。
          </p>
          <br />
          <p>
            Customer acknowledges, assents and understands that the above authorized Trading Agent will
            have access and will be given copies of any statements, notices and correspondence
            relating to Customer’s Account and that the Trading Agent is hereby given authorization
            to acknowledge and approve the correctness of any such statements and other documentation
            on be half of the Customer. GST strongly suggests that the Customer should scrutinize all
            activity and Account informati on and should contact GST immediatel y with any questions.
            GST will endeavor to provide the Customer with o nline access to all Account information including,
            but not limited to, the Cash on Account, Unrealized Profit and Loss on Open Positions, Account Value,
            as well as a record of all prior trade details including but not limited to Realized Profit and Loss,
            the Date of the Transaction, Amount and Deal Price.
          </p>
          <p>
            お客様は、上記で委任された取引代理人が、お客様の口座に関する全ての口座明細書、通知、および書簡にアクセスを有し、
            複写を受け取る、また取引代理人は本契約により、お客様に代わってそうした口座明細書など文書の正確さを承諾および承認する
            権限を与えられることを承諾、同意、および理解します。 GST は、全ての口座取引および情報を精査し、
            質問がある場合は即座に GST に連絡するよう強くお勧めします。 GST は、現金残高、持ち高の評価損益、
            口座残高を含む（ただし、これに限定されるものではない）全ての口座情報へのオンライン・アクセスを、
            また実現損益、取引日時、取引量、および売買金額を含む（ただし、これに限定されるものではない）従来の全取引情報の記録を、
            お客様に提供するよう努めます。
          </p>
          <br />
          <p>
            Customer acknowledges and understands that trading in margined foreign exchange is very
            risky and may result in losses that equal to or exceed the amount of margin deposited with GST.
            Customer should only trade or give authorization to trade on Customer’s behalf, Risk Capital,
            which is defined as that capital which if lost would not alter the lifestyle of Customer,
            Customer’s family and/or the solvency of the corporation for which Customer or Customer’s Authorized Trading Agent trades.
          </p>
          <p>
            お客様は、外国為替証拠金取引は非常にリスクが高く、GST に預け入れた証拠金の額以上に損失が膨らむ可能性があることを
            承諾および理解します。お客様またはお客様が委任した代理人の取引は、
            リスク・キャピタルのみを使うべきです。リスク・キャピタルとは、たとえ失ってもお客様やお客様のご家族の生活様式を、
            および（もしくは）お客様またはお客様が委任した取引代理人の代表する法人の支払能力を変えることのない資本として定義されます。
          </p>
          <br />
          <p>
            Customer acknowledges that neither GST nor any Trading Agent scan guarantee profits or avoid the
            risk of loss or, under some circumstances, even limit the extent of the potential loss under.
            Customer is solely and exclusively responsible for understanding the trading objectives and risks
            of the Trading Agent and acknowl edges and agrees that GST is not responsible for any transactions
            that may or may not conform to those objectives.
          </p>
          <p>
            お客様は、GSTおよび取引代理人が、利益を保証することや損失リスクを回避することはできないこと、
            また状況によっては、可能性のある損失規模を小さく制限することさえできないことを、
            承諾します。お客様は、取引代理人の取引目的およびリスクの理解に唯一かつ排他的に責任を負い、
            GST がその目的に一致するかどうか不明の取引に責任を負わないことに、承諾および同意いたします。
          </p>
          <br />
          <p>
            Customer acknowledges and understands that Trading Agent Authorization will
            cease only upon written revocation by the Customer or by the Trading Agent who
            is given the Authorization. Revocation for whatever reason shall not effect or limit
            the obligations and liability resulting from transactions or Contracts that were initiated
            before the effective date of revocation.
          </p>
          <p>
            お客様は、取引代理人への委任が、お客様もしくは委任された取引代理人の書面による解消要求においてのみ
            停止されることを、承諾および理解します。いかなる理由による解消も、解消の発効日前に開始された取引もし
            くは契約から生じた義務および債務に対して、影響もしくは制限しません。
          </p>
          <br />
          <p>
            By signing this Limited Power of Attorney, the Customer acknowledges that Customer has
            carefully read, understand and agree to the Limited Power of Attorney, Managed Account
            Authorization document and provisions contained therein.
          </p>
          <p>
            この限定委任状に署名することにより、お客様は、お客様が限定委任状、運用口座の委任文書、
            およびそこに含まれる規定を注意して読み、理解し、同意したことを承諾します。
          </p>
          <br />
          <p>
            Performance Fee: {trader?.success_fee}% of the monthly profit
          </p>
          <p>
            Account: {formData.account}
          </p>
          <p>
            口座: {formData.account}
          </p>
          <p>
            Investment Amount: {formData.amount_formatted_value}
          </p>
          <p>
            投資額: {formData.amount_formatted_value}
          </p>
          <p>
            Caution: Performance Fee is calculated by High water mark.
          </p>
          <p>
            運用報酬：1か月の利益のうち {trader?.success_fee}%
          </p>
          <p>
            注意：運用報酬はハイウォーターマーク方式に基づいて計算されます。
          </p>
          <p>
            Account Holders Name: {user?.userData.first_name + user?.userData.last_name}
          </p>
          <p>
            Date: {Functions.formatDateTime(new Date(), 'yyyy/MM/dd')}
          </p>
        </div>

        <div className="form-input">
          <Adapter.CheckboxAdapter
            name="agreement"
            label={t(key + '.f_agreement')}
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t("v_back")}
          </Button>

          <Button type="submit" variant="contained" color="primary">
            {t("v_application")}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel3Element = props => {
  const {
    trader,
    formData,
  } = props;

  const { t } = useTranslation();
  
  return (
    <>
      <form className="main-form">
        <p>{t(key + '.panel3_0')}</p>
        <p>{t(key + '.panel3_1')}</p>
        <p>{t(key + '.panel3_2')}{trader?.nickname}</p>
        <p>{t(key + '.panel3_3')}{trader?.success_fee}%</p>
        <p>{t(key + '.panel3_4')}{formData.account}</p>
        <p>{t(key + '.panel3_5')}{formData.amount_formatted_value}</p>
        <p>{t(key + '.panel3_6')}</p>
        <p>{t(key + '.panel3_7')}</p>

        <div className="action-group">
          <Button
            onClick={()=> {window.location.replace('/ranking')}}
            variant="contained"
            color="primary"
          >
            {t("v_close")}
          </Button>
        </div>
      </form>
    </>
  );
};
import React from 'react';

const NotFoundPage = props => {
  const { loading } = props;

  return (
    <>
      {
        loading &&
        <div className="wrapper">
            Not Found
        </div>
      }
    </>
  );
};

export default NotFoundPage;
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';

import * as Functions from '../../utils/functions';
import * as Auths from '../../utils/auth';

const key = 'LanguageSelector';

// Action Creators
export const actionCreators = createActions(
  'LOAD_TRADERS_REQUEST',
  'LOAD_TRADERS_RESPONSE',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  },
);

export const {
  loadTradersRequest,
  loadTradersResponse,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
  traders: [],
  followingTraders: [],
};

export const reducer = handleActions(
  {
    [loadTradersRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      traders: [],
      followingTraders: [],
    }),
    [loadTradersResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          return {
            ...state,
            traders: payload.data.traders,
            followingTraders: payload.data.followingTraders,
            loading: false,
          };
        }
        return {
          ...state,
          traders: [],
          followingTraders: [],
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          traders: [],
          followingTraders: [],
          alert: payload,
          loading: false,
        };
      },
    },
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

const makeSelectTraders = () =>
  createSelector(
    originSelector,
    state => state.traders
  );

const makeSelectFollowingTraders = () =>
  createSelector(
    originSelector,
    state => state.followingTraders
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectViewMode,
  makeSelectTraders,
  makeSelectFollowingTraders,
};

export function* loadTraders() {
  try {
    // hit API
    var response = yield call(
      Functions.makeRequestCall,
      'GET',
      'traders',
      {
        query: {
          limit: 10
        }
      }
    );

    // handle data
    var currentUser = Auths.getUser();
    var traders = response.data;
    var followingTraders = [];
    traders.forEach(function (trader) {
      // user folowings
      if (currentUser.followings.includes(trader.trader_id)) {
        followingTraders.push(trader);

        // followed
        trader.followed = true;
      }
      else {
        trader.followed = false;
      }

      // mt5
    });
    
    response.data = {
      traders: traders,
      followingTraders: followingTraders,
    };

    yield put(loadTradersResponse(response));
  } catch (e) {
    console.log('response', e);
    yield put(loadTradersResponse(e));
  }
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(loadTradersRequest, loadTraders);
  yield takeLatest(showDialogChildRequest, showDialogChild);
}
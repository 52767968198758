import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  reducer,
  saga,
} from './TraderFollowerPage.reducer';
import {
  makeSelectLoading as makeSelectLoadingAuth,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TraderFollowerPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import Avatar from '../../components/Avatar/Avatar';
import * as Adapter from '../../components/adapters';

const key = 'TraderFollowerPage';

const TraderFollowerPage = props => {
  const {
    loading,
    user,
  } = props;

  const { t } = useTranslation();
  const followers = user ? user.userData.followers : [];

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      id: 'user_id',
      numeric: true,
      disablePadding: true,
      label: " ",
      customRender: (value, row, index) => {
        return (
          <div>
            {index + 1}
          </div>
        )
      }
    },
    {
      id: 'from_avatar',
      numeric: false,
      disablePadding: false,
      label: ' ',
      customRender: (value) => {
        return (
          <Avatar path={value} />
        )
      }
    },
    {
      id: 'from_username',
      numeric: false,
      disablePadding: true,
      label: t('nickname'),
    },
    {
      id: "account",
      label: t('account'),
    },
  ];

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>

          <Adapter.TableAdapter
            columns={columns}
            rows={followers}
            usePagination
            paginationThreshold={5}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

TraderFollowerPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  alertAuth: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingAuth: makeSelectLoadingAuth(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TraderFollowerPage);
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';

import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import * as Auths from '../../utils/auth';

const key = 'TraderEditPage';

// Action Creators
export const actionCreators = createActions(
  'SET_VIEW_MODE_REQUEST',
  'LOAD_TRADER_REQUEST',
  'LOAD_TRADER_RESPONSE',
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  },
);

export const {
  setViewModeRequest,
  loadTraderRequest,
  loadTraderResponse,
  submitRequest,
  submitResponse,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
  trader: false,
};

export const reducer = handleActions(
  {
    [setViewModeRequest]: (state, { payload }) => ({
      ...state,
      viewMode: payload,
    }),
    [loadTraderRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      trader: false,
    }),
    [loadTraderResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          return {
            ...state,
            trader: payload.data,
            loading: false,
          };
        }
        return {
          ...state,
          trader: false,
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          trader: false,
          alert: payload,
          loading: false,
        };
      },
    },
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        accessToken: payload.result ? payload.data.access_token: false,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

const makeSelectTrader = () =>
  createSelector(originSelector, state => state.trader);

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectViewMode,
  makeSelectTrader,
};

export function* loadTrader() {
  try {
    const trader = Auths.getUser();

    var response = yield call(
      Functions.makeRequestCall,
      'GET',
      'traders/' + trader.user_id,
      {}
    );

    yield put(loadTraderResponse(response));
  } catch (e) {
    console.log('response', e);
    yield put(loadTraderResponse(e));
  }
}

export function* submit() {
  try {
    var values = yield select(makeSelectValues());
    const trader = yield select(makeSelectTrader());

    if (values.avatar) {
      // upload avatar
      const avatarKey = yield call(
        Functions.presignedUploadS3,
        Constants.General.S3Directory.Profile,
        values.file_avatar
      );
      values.avatar = avatarKey;
    }

    // store
    values.email = trader.email;
    values.avatar = values.avatar ?
      values.avatar :
      trader.avatar;
    const data = yield call(
      Functions.makeRequestCall,
      'PUT',
      'traders/' + trader.trader_id,
      {
        body: values
      }
    );

    if (data.result) {
      yield put(showDialogChildRequest(values.successPayload));
      yield put(setViewModeRequest(true));
    }

    // handle response
    yield put(submitResponse(data));

  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(loadTraderRequest, loadTrader);
  yield takeLatest(submitRequest, submit);
  yield takeLatest(showDialogChildRequest, showDialogChild);
}
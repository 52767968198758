import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../../utils/injectors';
import {
  actionCreators,
  makeSelectActiveStep,
  reducer,
  saga,
} from './StepperAdapter.reducer';

import {
  Stepper,
  StepLabel,
  Step,
  Button,
  Container,
} from '@mui/material';

import './StepperAdapter.scss';

const key = "StepperAdapter";

const StepperAdapter = props => {
  const {
    steps,
    stepPanels,
    activeStep,
    setActiveStep,
    notBackStep,
    useActions,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    var targetForm = document.querySelector('.stepper-form');
    if (targetForm) {
      targetForm.dispatchEvent(new Event(
        'submit',
        { cancelable: true, bubbles: true }
      ));
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <section className={key}>
        <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label} className="step">
              <StepLabel className="step-label">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Container className="instructions">
          {stepPanels[activeStep]}
        </Container>
        {
          !useActions || activeStep === steps.length - 1 ?
            <></> :
            <div className="action-group text-center">
              <Button
                disabled={activeStep === 0 || notBackStep === activeStep}
                onClick={handleBack}
                className="backButton"
                color="default"
              >
                戻る
              </Button>
              <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
                次へ
              </Button>
            </div>
        }
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  activeStep: makeSelectActiveStep(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(StepperAdapter);
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  reducer,
  saga,
} from './LoginPage.reducer';
import {
  makeSelectLoading as makeSelectLoadingAuth,
  makeSelectAlert as makeSelectAlertAuth
} from '../../containers/AuthProvider/AuthProvider.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './LoginPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';

const key = 'LoginPage';

const LoginPage = props => {
  const {
    loading,
    alert,
    submitRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email(t('Validation.invalid_email'))
        .required(t('Validation.required')),
      password: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="login">
          <h3>{t(key + '.title')}</h3>

          <form className="main-form" onSubmit={formik.handleSubmit}>
            <div className="form-input">
              <Adapter.AlertAdapter alert={alert} />
            </div>

            <div className="form-input">
              <Adapter.TextFieldAdapter
                type="text"
                label={t("email")}
                name="email"
                placeholder="youremail@email.com"
                variant="filled"
                formik={formik}
              />
            </div>

            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="password"
                label={t("password")}
                name="password"
                variant="filled"
                formik={formik}
              />
            </div>
            
            <div className="form-input">
              <Link href="/forgot" target="_blank">
                {t("forgot_password")}
              </Link>

            </div>
            
            <div className="action-group">
              <Button
                className="backButton"
                variant="contained"
                color="default"
                onClick={()=> {window.top.close();}}
              >
                {t("cancel")}
              </Button>
            
              <Button type="submit" variant="contained" color="primary">
                {t("login")}
              </Button>
            </div>
          </form>

        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

LoginPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  alertAuth: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingAuth: makeSelectLoadingAuth(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  alertAuth: makeSelectAlertAuth(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(LoginPage);
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  makeSelectUser,
  reducer,
  saga,
} from './AdminUserShowPage.reducer';

import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Link,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdminUserShowPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';

const key = 'AdminUserShowPage';

const AdminUserShowPage = props => {
  const {
    loading,
    user,
    match,
    viewMode,
    loadUserRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadUserRequest(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: user.first_name + user.last_name,
      username: user.username,
      email: user.email,
      head_phone_number: user.head_phone_number,
      phone_number: user.phone_number,
      nation_code: user.nation_code,
      postal_code: user.postal_code,
      prefecture: user.prefecture,
      municipality: user.municipality,
      address: user.address,
      account: user.mt5_login,
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .required(t('Validation.required')),
      head_phone_number: Yup
        .string()
        .required(t('Validation.required')),
      phone_number: Yup
        .string()
        .required(t('Validation.required')),
      nation_code: Yup
        .string()
        .required(t('Validation.required')),
      postal_code: Yup
        .string()
        .required(t('Validation.required')),
      prefecture: Yup
        .string()
        .required(t('Validation.required')),
      municipality: Yup
        .string()
        .required(t('Validation.required')),
      address: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
    enableReinitialize: true,
  });

  const identifications = user?.identifications ? user.identifications : [];
  const confirmDocuments = user?.confirm_documents ? user.confirm_documents : [];
  const accounts = user?.mt5_accounts ? user.mt5_accounts : [];

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <form className="main-form" onSubmit={formik.handleSubmit}>
            <Grid container className="wrapper">
              <Grid className="item" item md={3}>
                <Adapter.FileAdapter
                  name="avatar"
                  label=""
                  accept="image/*"
                  url={Functions.getS3URL(user.avatar)}
                  viewMode={viewMode}
                  formik={formik}
                />
              </Grid>
              <Grid className="item" item md={9}>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('fullname')}
                    name="fullname"
                    variant="filled"
                    viewMode={true}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('email')}
                    name="email"
                    variant="filled"
                    placeholder="youremail@email.com"
                    viewMode={true}
                    formik={formik}
                  />
                </div>

                <div className="form-input half-width">
                  <PhoneNumber
                    viewMode={viewMode}
                    formik={formik}
                  />

                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('username')}
                    name="username"
                    variant="filled"
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input half-width">
                  <Adapter.SelectAdapter
                    name="nation_code"
                    label={t('residence_country')}
                    variant="filled"
                    items={Functions.getConstant(Constants.User.Nation).inputList}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input half-width">
                  <Adapter.NumberFieldAdapter
                    label={t('postal_code')}
                    name="postal_code"
                    variant="filled"
                    placeholder="100-8791"
                    maskFormat="###-#####"
                    viewMode={viewMode}
                    formik={formik}
                    fullWidth
                  />
                </div>

                <div className="form-input half-width">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('prefecture')}
                    name="prefecture"
                    variant="filled"
                    placeholder={t('ex_prefecture')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('municipality')}
                    name="municipality"
                    variant="filled"
                    placeholder={t('ex_municipality')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('address')}
                    name="address"
                    variant="filled"
                    placeholder={t('ex_address')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  {
                    identifications.map((item, index) => (
                      <div key={index}>
                        <Link href={Functions.getS3URL(item)} target="_blank" underline="always">
                          {
                            user.account_type_code === Constants.User.AccountType.Corporation.code ?
                            t('identification_c') + (index + 1) :
                            t('identification_i') + (index + 1)
                          }
                        </Link>
                      </div>
                    ))
                  }
                </div>

                <div className="form-input">
                  {
                    confirmDocuments.map((item, index) => (
                      <div key={index}>
                        <Link href={Functions.getS3URL(item)} target="_blank" underline="always">
                          {
                            user.account_type_code === Constants.User.AccountType.Corporation.code ?
                            t('confirm_document_c') + (index + 1) :
                            t('confirm_document_i')  + (index + 1)
                          }
                        </Link>
                      </div>
                    ))
                  }
                </div>

                <div className="form-input">
                  {t('account_number') + ": "}
                  {
                    accounts.map((item, index) => (
                      <span key={index}>
                        {item}{" "}
                      </span>
                    ))
                  }
                </div>
        
              </Grid>
            </Grid>

          </form>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdminUserShowPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdminUserShowPage);
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  makeSelectTopics,
  reducer,
  saga,
} from './AdminTopicEditPage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdminTopicEditPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'AdminTopicEditPage';

const AdminTopicEditPage = props => {
  const {
    loading,
    loadTopicsRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTopicsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
              t(key + '.step3'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
              <Panel2Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdminTopicEditPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
  topics: makeSelectTopics(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdminTopicEditPage);

const Panel0Element = props => {
  const {
    formData,
    topics,
    nextStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      topic_id: topics[0]?.topic_id,
    }, formData),    
    validationSchema: Yup.object({
      topic_id: Yup
        .string()
        .required(t('Validation.required'))
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  const columns = [
    {
      id: 'id',
      excluded: true,
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: t('publish_date'),
      customRender: (value, row) => {
        return (
          <>
            {Functions.formatDateTime(new Date(value), 'yyyy/MM/dd')}
          </>
        );
      }
    },
    {
      id: 'type_code',
      numeric: false,
      disablePadding: false,
      label: t('kind'),
      customRender: (value, row) => {
        const type = Functions.getConstant(Constants.Topic.Type, value).this;

        return (
          <>
            <span className={"tag " + type.className}>
              {t(type.name)}
            </span>
          </>
        );
      }
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: t('title'),
    },
  ];

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel0_0')}</p>

        <Adapter.TableAdapter 
          columns={columns}
          rows={topics}
          usePagination
          paginationThreshold={50}
          idField="topic_id"
          radioSelection="topic_id"
          formik={formik}
        />

        <div className="action-group">
          <Button type="submit" variant="contained" color="primary">
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = props => {
  const {
    confirming,
    formData,
    topics,
    submitRequest,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const topic = topics.filter(x => x.topic_id === formData.topic_id)[0]

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      type_code: topic?.type_code,
      title: topic?.title,
      content: topic?.content,
      image_old: topic?.image,
    }, formData),  
    validationSchema: Yup.object({
      type_code: Yup
        .string()
        .required(t('Validation.required')),
      title: Yup
        .string()
        .required(t('Validation.required')),
      image: Yup
        .mixed(),
      content: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      if (!confirming) {
        setConfirmRequest(true);
        nextStepRequest(values);
      } else {
        submitRequest(values);
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>

        {
          !confirming ?
            <p>{t(key + '.panel1_0')}</p> :
            <p>{t(key + '.panel1_1')}</p>
        }

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('title')}
            name="title"
            variant="filled"
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.FileAdapter
            name="image"
            label={t('image')}
            accept="image/*"
            viewMode={confirming}
            formik={formik}
            url={Functions.getS3URL(topic?.image)}
            descriptions={[
              t(key + '.f_image_0'),
              t(key + '.f_image_1'),
            ]}
          />
        </div>

        <div className="form-input">
          <Adapter.TextareaAdapter
            name="content"
            label={t('body')}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t('v_back')}
          </Button>

          <Button
              type="submit"
              variant="contained"
              color="primary"
          >
            { confirming ? t('v_publish') : t('v_next') }
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel2Element = () => {
  const { t } = useTranslation();

  return (
    <>
      <form className="main-form">
        <p>{t(key + '.panel2_0')}</p>

        <div className="action-group">
          <Button
            onClick={() => { window.location.reload() }}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>
    </>
  );
};
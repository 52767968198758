import React from 'react';
import { ReactReduxContext } from 'react-redux';
import invariant from 'invariant';
import createReducer from './reducers';
import { isEmpty, isFunction, isString, conformsTo, isObject } from 'lodash';

const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
const DAEMON = '@@saga-injector/daemon';
const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

function checkStore(store) {
    const shape = {
      dispatch: isFunction,
      subscribe: isFunction,
      getState: isFunction,
      replaceReducer: isFunction,
      runSaga: isFunction,
      injectedReducers: isObject,
      injectedSagas: isObject,
    };
    invariant(
      conformsTo(store, shape),
      '(app/utils...) injectors: Expected a valid redux store',
    );
}

// Reducer Injectors
function injectReducerFactory(store, isValid) {
    return function injectReducer(key, reducer) {
        if (!isValid) checkStore(store);

        invariant(
            isString(key) && !isEmpty(key) && isFunction(reducer),
            '(app/utils...) injectReducer: Expected `reducer` to be a reducer function',
        );

        // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
        if (
            Reflect.has(store.injectedReducers, key) &&
            store.injectedReducers[key] === reducer
        )
            return;

        store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
        store.replaceReducer(createReducer(store.injectedReducers));
    };
}

function getReducerInjectors(store) {
    checkStore(store);

    return {
        injectReducer: injectReducerFactory(store, true),
    };
}

// Saga Injectors
const allowedModes = [RESTART_ON_REMOUNT, DAEMON, ONCE_TILL_UNMOUNT];

const checkKey = key =>
    invariant(
        isString(key) && !isEmpty(key),
        '(app/utils...) injectSaga: Expected `key` to be a non empty string',
    );

const checkDescriptor = descriptor => {
    const shape = {
        saga: isFunction,
        mode: mode => isString(mode) && allowedModes.includes(mode),
    };
    invariant(
        conformsTo(descriptor, shape),
        '(app/utils...) injectSaga: Expected a valid saga descriptor',
    );
};

function injectSagaFactory(store, isValid) {
    return function injectSaga(key, descriptor = {}, args) {
        if (!isValid) checkStore(store);

        const newDescriptor = {
            ...descriptor,
            mode: descriptor.mode || DAEMON,
        };
        const { saga, mode } = newDescriptor;

        checkKey(key);
        checkDescriptor(newDescriptor);

        let hasSaga = Reflect.has(store.injectedSagas, key);

        if (process.env.NODE_ENV !== 'production') {
            const oldDescriptor = store.injectedSagas[key];
            // enable hot reloading of daemon and once-till-unmount sagas
            if (hasSaga && oldDescriptor.saga !== saga) {
                oldDescriptor.task.cancel();
                hasSaga = false;
            }
        }

        if (
            !hasSaga ||
            (hasSaga && mode !== DAEMON && mode !== ONCE_TILL_UNMOUNT)
        ) {
            /* eslint-disable no-param-reassign */
            store.injectedSagas[key] = {
                ...newDescriptor,
                task: store.runSaga(saga, args),
            };
            /* eslint-enable no-param-reassign */
        }
    };
}

function ejectSagaFactory(store, isValid) {
    return function ejectSaga(key) {
        if (!isValid) checkStore(store);

        checkKey(key);

        if (Reflect.has(store.injectedSagas, key)) {
            const descriptor = store.injectedSagas[key];
            if (descriptor.mode && descriptor.mode !== DAEMON) {
                descriptor.task.cancel();
                // Clean up in production; in development we need `descriptor.saga` for hot reloading
                if (process.env.NODE_ENV === 'production') {
                    // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
                    store.injectedSagas[key] = 'done'; // eslint-disable-line no-param-reassign
                }
            }
        }
    };
}

function getSagaInjectors(store) {
    checkStore(store);

    return {
        injectSaga: injectSagaFactory(store, true),
        ejectSaga: ejectSagaFactory(store, true),
    };
}

// use injector
const useInjectReducer = ({ key, reducer }) => {
    const context = React.useContext(ReactReduxContext);
    React.useEffect(() => {
        getReducerInjectors(context.store).injectReducer(key, reducer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const useInjectSaga = ({ key, saga, mode }) => {
    const context = React.useContext(ReactReduxContext);
    React.useEffect(() => {
        const injectors = getSagaInjectors(context.store);
        injectors.injectSaga(key, { saga, mode });

        return () => {
            injectors.ejectSaga(key);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};


export { useInjectReducer, useInjectSaga };
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

const key = 'ForgotPage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_EMAIL_REQUEST',
  'SUBMIT_EMAIL_RESPONSE',
  'SUBMIT_PASSWORD_REQUEST',
  'SUBMIT_PASSWORD_RESPONSE',
  {
    prefix: key,
  },
);

export const {
  submitEmailRequest,
  submitEmailResponse,
  submitPasswordRequest,
  submitPasswordResponse,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  accessToken: false,
  mailSent: false,
  email: false,
};

export const reducer = handleActions(
  {
    [submitEmailRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
      email: payload.email,
    }),
    [submitEmailResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        alert: payload.alert,
        loading: false,
        mailSent: true,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [submitPasswordRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitPasswordResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        alert: payload.alert,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectMailSent = () =>
  createSelector(
    originSelector,
    state => state.mailSent,
  );

const makeSelectEmail = () =>
  createSelector(
    originSelector,
    state => state.email,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectMailSent,
  makeSelectEmail,
};

export function* submitEmail() {
  try {
    const values = yield select(makeSelectValues());
    const data = yield call(
      [Auth, 'forgotPassword'],
      values.email,
    );

    if (data) {
      var payload = data;
      payload.alert = {
        type: 'success',
        code: "forgot_sent",
        message: '入力されたメールアドレス宛にコード（6桁の数字）を送信しました。以下にそのコードを入力し、新しいパスワードを設定してください。',
      }
      yield put(submitEmailResponse(payload));
    }

  } catch (e) {
    e.alert = {
      type: 'throw',
      message: e.message,
    }
    yield put(submitEmailResponse(e));
    console.log('Error', e);
  }
}

export function* submitPassword() {
  try {
    const values = yield select(makeSelectValues());
    const email = yield select(makeSelectEmail());
    const data = yield call(
      [Auth, 'forgotPasswordSubmit'],
      email,
      values.code,
      values.password,
    );

    if (data) {
      var payload = {};
      payload.alert = {
        type: 'success',
        code: "password_reset",
        message: 'パスワードをリセットしました。',
        payload: data,
      }
      yield put(submitPasswordResponse(payload));
    }

  } catch (e) {
    e.alert = {
      type: 'throw',
      message: e.message,
    }
    yield put(submitPasswordResponse(e));
    console.log('Error', e);
  }
}

export function* saga() {
  yield takeLatest(submitEmailRequest, submitEmail);
  yield takeLatest(submitPasswordRequest, submitPassword);
}
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectUsers,
  reducer,
  saga,
} from './AdminUserListPage.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdminUserListPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import Avatar from '../../components/Avatar/Avatar';

const key = 'AdminUserListPage';

const AdminUserListPage = props => {
  const {
    loading,
    users,
    loadUsersRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadUsersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      id: 'mt5_login',
      numeric: true,
      disablePadding: false,
      label: t('number'),
    },
    {
      id: 'avatar',
      numeric: false,
      disablePadding: false,
      label: ' ',
      customRender: (value) => {
        return (
          <Avatar path={value} />
        )
      }
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('fullname'),
      customRender: (value, row) => {
        return (
          <div className="name">
            <Link className="link" target="_blank" href={"/admin/user/" + row.user_id}>
              {row.first_name + row.last_name}
            </Link>
          </div>
        )
      }
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('email'),
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: t('created_at'),
      customRender: (value, row) => {
        return (
          <div>
            {Functions.formatDateTime(new Date(value), 'yyyy/MM/dd')}
          </div>
        )
      }   
    },
  ];

  const injectElement = (
    <>
      <Helmet>
      <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.TableAdapter 
            columns={columns}
            rows={users}
            usePagination
            paginationThreshold={50}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdminUserListPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  users: makeSelectUsers(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdminUserListPage);
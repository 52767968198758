/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_WvIVFHMcN",
    "aws_user_pools_web_client_id": "3altn3dmit91mibku8uvbcnl1u",
    "oauth": {
        "domain": "gst-investment.auth.ap-northeast-1.amazoncognito.com"
    }
};


export default awsmobile;

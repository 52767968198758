import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectTrader,
  makeSelectUser,
  makeSelectDeals,
  makeSelectPairsRates,
  makeSelectTransactions,
  makeSelectDailies,
  reducer,
  saga,
} from './TraderPage.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Grid,
  Button,
  Link
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  PieSeries,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { useTranslation } from 'react-i18next';

import './TraderPage.scss';
import Avatar from '../../components/Avatar/Avatar';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'TraderPage';

const TraderPage = props => {
  const {
    loading,
    match,
    trader,
    deals,
    pairsRates,
    dailies,
    loadTraderRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTraderRequest(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="profile">
          <Grid container className="grid head">
            <Grid className="item title" item md={6}>
              <h4>{t(key + '.title')}</h4>
            </Grid>
            <Grid className="item back" item md={6}>
              <Link href="/ranking" underline="always">{t('trader_ranking')} ＞</Link>
            </Grid>
          </Grid>

          <h5 className="sub-title">{t('profile')}</h5>
          <Grid container className="grid">
            <Grid className="item avatar-wrapper" item md={3}>
              <Avatar path={trader.avatar} />
              <div className="name">{trader.nickname}</div>
              {/* <div className="followers">
                <span className="label">{t('followers')}</span>
                <span className="number">{trader?.followers?.length}</span>
              </div> */}
            </Grid>

            <Grid className="item description-wrapper" item md={9}>
              <pre className="description">
                {trader.message}
              </pre>
            </Grid>
          </Grid>
        </Container>

        <Container className="performance">
          <h5 className="sub-title">{t('performance')}</h5>
          <Container>
            <Adapter.TabAdapter
              initialIndex={0}
              data={[
                  {
                    id: 1,
                    label: t('profit_loss'),
                    panel: (
                      <>
                        <Grid container>
                          <Grid className="item title" item md={12}>
                            <LineChartElement
                              data={dailies}
                              name={t('profit_loss')}
                              valueField="mt5_balance"
                              argumentField="date"
                            />
                          </Grid>
                        </Grid>
                      </>
                    )
                  },
                  {
                    id: 2,
                    label: t('currency_pair'),
                    panel: <PieChartElement data={pairsRates} />
                  },
              ]}
            />
          </Container>

        </Container>

        <Container className="details">
          <Container>
            <div className="stats">
              <Grid container className="grid">

                <Grid className="item" item md={10}>
                  <Grid container className="grid">

                    <Grid className="item" item md={3}>
                      <div className="info">
                        <label>{t('required_fund')}</label>
                        <p>¥{Functions.thousandSeparator(trader.required_fund)}</p>
                      </div>
                      <div className="info">
                        <label>{t('success_fee')}</label>
                        <p>{trader.success_fee}%</p>
                      </div>
                    </Grid>

                    <Grid className="item" item md={3}>
                      <div className="info">
                        <label>{t('investment_profit_rate')}</label>
                        <p>{trader.investment_return_rate}%</p>
                      </div>
                      <div className="info">
                        <label>{t('trading_period')}</label>
                        <p>{trader.total_trading_period ? trader.total_trading_period : 0}{t('days')}</p>
                      </div>
                    </Grid>

                    <Grid className="item" item md={3}>
                      <div className="info">
                        <label>{t('profit_pip')}</label>
                        <p>{Functions.makeAbbreviation(trader.total_daily_profit)}</p>
                      </div>
                      <div className="info">
                        <label>{t('trading_count')}</label>
                        <p>{trader.total_trading_count ? trader.total_trading_count : 0}{t('times')}</p>
                      </div>
                    </Grid>

                    <Grid className="item" item md={3}>
                      <div className="info">
                        <label>{t('max_decline_rate')}</label>
                        <p>{trader.max_decline_rate ? trader.max_decline_rate : 0}%</p>
                      </div>
                      <div className="info">
                        <label>{t('trading_success_rate')}</label>
                        <p>{trader.winning_rate ? trader.winning_rate : 0}％</p>
                      </div>
                    </Grid>

                  </Grid>
                </Grid>

                <Grid className="item" item md={2}>
                  {
                    trader.followed ?
                    <Button
                      className="action btn-danger"
                      href={"/unfollow/" + trader.trader_id}
                      size="large"
                      variant="contained"
                      target="_blank"
                    >
                      {t('release')}  －
                    </Button> :
                    <Button
                      className="action follow"
                      href={"/follow/" + trader.trader_id}
                      size="large"
                      variant="contained"
                      color="primary"
                      target="_blank"
                    >
                      {t('follow')} ＋
                    </Button>
                  }
                </Grid>

              </Grid>
            </div>

            <Adapter.TableAdapter 
              columns={[
                {
                  id: 'mt5_time',
                  numeric: false,
                  disablePadding: false,
                  label: t('trading_time'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {Functions.formatDateTime(new Date(value), 'yyyy-MM-dd HH:mm')}
                      </>
                    )
                  }
                },
                {
                  id: 'mt5_entry',
                  numeric: false,
                  disablePadding: false,
                  label: t('open') + '/' + t('close'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {
                          value === 0 ?
                          t('open') :
                          t('close')
                        }
                      </>
                    )
                  }
                },
                {
                  id: 'mt5_symbol',
                  numeric: false,
                  disablePadding: false,
                  label: t('currency'),
                },
                {
                  id: 'mt5_action',
                  numeric: false,
                  label: t('type'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {Functions.getConstant(Constants.MT5.Deals.Action, value).this.label}
                      </>
                    )
                  }
                },
                {
                  id: 'mt5_price_sl',
                  numeric: false,
                  disablePadding: false,
                  label: 'S/L',
                },
                {
                  id: 'mt5_price_tp',
                  numeric: false,
                  disablePadding: false,
                  label: 'T/P',
                },
                {
                  id: 'mt5_storage',
                  numeric: false,
                  disablePadding: false,
                  label: t('swap'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {Functions.thousandSeparator(value)}
                      </>
                    );
                  }
                },
                {
                  id: 'mt5_profit',
                  numeric: false,
                  disablePadding: false,
                  label: t('profit'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {Functions.thousandSeparator(value)}
                      </>
                    );
                  }
                },
                // {
                //   id: 'total_profit',
                //   numeric: false,
                //   disablePadding: false,
                //   label: t('total_pip'),
                //   customRender: (value, row) => {
                //     return (
                //       <>
                //         {Functions.thousandSeparator(value)}
                //       </>
                //     );
                //   }
                // },
              ]}
              rows={deals}
              usePagination
              paginationThreshold={50}
              sort={{
                order: 'desc',
                orderBy: 'mt5_time',
              }}
            />
            
          </Container>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

TraderPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  trader: makeSelectTrader(),
  user: makeSelectUser(),
  deals: makeSelectDeals(),
  pairsRates: makeSelectPairsRates(),
  transactions: makeSelectTransactions(),
  dailies: makeSelectDailies(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TraderPage);

const LineChartElement = props => {
  const {
    data,
    valueLabelSuffix,
  } = props;

  const legendStyles = () => ({
    root: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'row',
    },
  });
  const legendLabelStyles = theme => ({
    label: {
      paddingTop: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
  });
  const legendItemStyles = () => ({
    item: {
      flexDirection: 'column',
    },
  });
  const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
  );
  const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
  );
  const legendItemBase = ({ classes, ...restProps }) => (
    <Legend.Item className={classes.item} {...restProps} />
  );
  const ArgumentAxisLabel = ({ classes, ...restProps }) => {
    const itemDay = Functions.formatDateTime(new Date(restProps.text), 'dd');
    const expectDays = ["01", "15"]
    const isDisplay = expectDays.includes(itemDay);

    return (
      <ArgumentAxis.Label 
        className={"argument-axis-label"}
        text={isDisplay ? restProps.text : ""}
        x={restProps.x}
        y={restProps.y}
        dy={"2em"}
        textAnchor={restProps.textAnchor}
      />
    );
  };
  const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
  const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
  const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);
  const ValueLabel = (props) => {
    const { text } = props;
    const suffix = valueLabelSuffix ? valueLabelSuffix : "";

    return (
      <ValueAxis.Label
        {...props}
        text={text + suffix}
      />
    );
  };

  return (
    <Chart
      data={data}
      className="chart"
    >
      <ArgumentAxis
        tickFormat={() => tick => tick}
        labelComponent={ArgumentAxisLabel}
      />
      <ValueAxis
        max={50}
        labelComponent={ValueLabel}
      />

      <LineSeries
        {...props}
      />
      <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
    </Chart>
  );
};

const PieChartElement = props => {
  const { data } = props;

  const legendStyles = () => ({
    root: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'row',
    },
  });
  const legendLabelStyles = theme => ({
    label: {
      paddingTop: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
  });
  const legendItemStyles = () => ({
    item: {
      flexDirection: 'column',
    },
  });
  const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
  );
  const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
  );
  const legendItemBase = ({ classes, ...restProps }) => (
    <Legend.Item className={classes.item} {...restProps} />
  );
  const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
  const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
  const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);

  return (
    <Chart
      data={data}
    >
      <PieSeries
        valueField="rate"
        argumentField="symbol"
      />
      <Animation />

      <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
    </Chart>
  );
};

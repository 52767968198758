import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectTopic,
  reducer,
  saga,
} from './TopicPage.reducer';
import {
  makeSelectLoading as makeSelectLoadingAuth,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Grid,
  Link
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TopicPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'TopicPage';

const TopicPage = props => {
  const {
    match,
    loading,
    topic,
    loadTopicRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTopicRequest(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const type = Functions.getConstant(Constants.Topic.Type, topic.type_code).this;

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        
        <Container className="topic">

          <div className="details">
            <Grid container className="grid head">
              <Grid className="text-center" item md={2}>
                <span className={"tag " + type.className}>{t(type.name)}</span>
              </Grid>
              <Grid className="date text-right" item md={2}>
                {Functions.formatDateTime(new Date(topic.updated_at), 'yyyy/MM/dd')}
              </Grid>
              <Grid className="item back" item md={8}>
                <Link href="/topics" underline="always">{t('topics_list')} ＞</Link>
              </Grid>
            </Grid>
          </div>

          <br></br>
          
          <div className="content">
            <h2>{topic.title}</h2>

            <div className="text-center">
              <div className='image'>
                <img style={{maxWidth: 900 + 'px'}} src={Functions.getS3URL(topic.image)} alt="img" />
              </div>
            </div>

            <br></br>

            <pre>
              {topic.content}
            </pre>
          </div>
        </Container>

      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

TopicPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingAuth: makeSelectLoadingAuth(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  topic: makeSelectTopic(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TopicPage);
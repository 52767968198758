import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  reducer,
  saga,
} from './RegisterPage.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Link,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './RegisterPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';

const key = 'RegisterPage';

const RegisterPage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
    
        <Container className="register">
          <h3>{t(key + '.title')}</h3>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
              t(key + '.step3'),
              t(key + '.step4'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
              <Panel2Element
                {...props}
              />,
              <Panel3Element
                {...props}
              />,
            ]}
          />
        </Container>

      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

RegisterPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  confirming: makeSelectConfirming(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(RegisterPage);

const IndividualElement = props => {
  const {
    viewMode,
    formik,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="individual form-group-input">
        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('first_name')}
            name="first_name"
            variant="filled"
            placeholder={t('ex_first_name')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('last_name')}
            name="last_name"
            variant="filled"
            placeholder={t('ex_last_name')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('first_name_kana')}
            name="first_name_kana"
            variant="filled"
            placeholder={t('ex_first_name_kana')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('last_name_kana')}
            name="last_name_kana"
            variant="filled"
            placeholder={t('ex_last_name_kana')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>
      </div>
    </>
  )
};
const CorporationElement = props => {
  const {
    viewMode,
    formik,
  } = props;

  const { t } = useTranslation();
  const tPrefix = t('representative');

  return (
    <>
      <div className="corporation form-group-input">
        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('company_name')}
            name="company_name"
            variant="filled"
            placeholder={t('ex_company_name')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={tPrefix + t('first_name')}
            name="first_name"
            variant="filled"
            placeholder={t('ex_first_name')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={tPrefix + t('last_name')}
            name="last_name"
            variant="filled"
            placeholder={t('ex_last_name')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={tPrefix + t('first_name_kana')}
            name="first_name_kana"
            variant="filled"
            placeholder={t('ex_first_name_kana')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={tPrefix + t('last_name_kana')}
            name="last_name_kana"
            variant="filled"
            placeholder={t('ex_last_name_kana')}
            viewMode={viewMode}
            formik={formik}
          />
        </div>
      </div>
    </>
  )
};

const Panel0Element = props => {
  const {
    formData,
    nextStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      agreement: false,
    }, formData),
    validationSchema: Yup.object({
      agreement: Yup
        .bool()
        .isTrue(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="agreement">
          <div className="danger">
            <p>{t(key + '.panel0_0')}</p>
            <p className="tab-1">{t(key + '.panel0_1')}</p>
            <p className="tab-1">▶{t(key + '.panel0_2')}</p>
            <p className="tab-2">■{t(key + '.panel0_3')}</p>
            <p className="tab-2">■{t(key + '.panel0_4')}</p>
            <p className="tab-1">▶{t(key + '.panel0_5')}</p>
            <p className="tab-2">■{t(key + '.panel0_6')}</p>
            <p className="tab-2">■{t(key + '.panel0_7')}</p>
          </div>

          <div className="secondary">
            <p>{t(key + '.panel0_8')}</p>
            <p className="tab-1">{t(key + '.panel0_9')}</p>
            <p className="tab-1">{t(key + '.panel0_10')}</p>
            <p>{t(key + '.panel0_11')}</p>
            <p className="tab-1">
              ■<Link href="https://gs-ltd.hk/ja/privacy-policy/" target="_blank" underline="always">{t('privacy_policy')}</Link>
            </p>
            <p className="tab-1">
              ■<Link href="https://gs-ltd.hk/ja/trading-execution-risk/" target="_blank" underline="always">{t('trading_risk')}</Link>
            </p>
            <p className="tab-1">
              ■<Link href="https://gs-ltd.hk/ja/aml-policy/" target="_blank" underline="always">{t('aml_policy')}</Link>
            </p>
            <p className="tab-1">
              ■<Link href="https://gs-ltd.hk/risk-warning/" target="_blank" underline="always">{t('risk_warning')}</Link>
            </p>
          </div>
        </div>

        <Adapter.CheckboxAdapter
          formik={formik}
          label={t(key + '.f_agreement')}
          name="agreement"
          type="checkbox"
          labelClassName="agreement-label"
        />

        <div className="action-group">
          <Button
            onClick={() => { window.top.close(); }}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {t("v_next")}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = props => {
  const {
    formData,
    confirming,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      account_type_code: Constants.User.AccountType.Individual.code,
      first_name: "",
      last_name: "",
      first_name_kana: "",
      last_name_kana: "",
      username: "",
      email: "",
      head_phone_number: "",
      phone_number: "",
      nation_code: Constants.User.Nation.Japan.code,
      postal_code: "",
      prefecture: "",
      municipality: "",
      address: "",
      company_name: "",
    }, formData),
    validationSchema: Yup.object({
      account_type_code: Yup
        .string()
        .required(t('Validation.required')),
      first_name: Yup
        .string()
        .when('account_type_code', {
          is: Constants.User.AccountType.Individual.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      last_name: Yup
        .string()
        .when('account_type_code', {
          is: Constants.User.AccountType.Individual.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      first_name_kana: Yup
        .string()
        .when('account_type_code', {
          is: Constants.User.AccountType.Individual.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      last_name_kana: Yup
        .string()
        .when('account_type_code', {
          is: Constants.User.AccountType.Individual.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      company_name: Yup
        .string()
        .when('account_type_code', {
          is: Constants.User.AccountType.Corporation.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      username: Yup
        .string()
        .required(t('Validation.required')),
      email: Yup
        .string()
        .required(t('Validation.required'))
        .email(t('Validation.invalid_email')),
      head_phone_number: Yup
        .string()
        .required(t('Validation.required')),
      phone_number: Yup
        .string()
        .required(t('Validation.required')),
      nation_code: Yup
        .string()
        .required(t('Validation.required')),
      postal_code: Yup
        .string()
        .required(t('Validation.required')),
      prefecture: Yup
        .string()
        .required(t('Validation.required')),
      municipality: Yup
        .string()
        .required(t('Validation.required')),
      address: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
      if (!confirming) {
        setConfirmRequest(true);
      }
    },
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <Adapter.RadioGroupAdapter
            row
            label={t('account_type')}
            name="account_type_code"
            color="primary"
            items={Functions.getConstant(Constants.User.AccountType).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        {
          formik.values.account_type_code === Constants.User.AccountType.Individual.code ?
            <IndividualElement
              viewMode={confirming}
              formik={formik}
            /> :
            <CorporationElement
              viewMode={confirming}
              formik={formik}
            />
        }

        <div className="form-input form-group-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('username')}
            name="username"
            variant="filled"
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t(key + '.f_email')}
            name="email"
            variant="filled"
            placeholder="youremail@email.com"
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input form-group-input half-width">
          <PhoneNumber
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input form-group-input half-width">
          <Adapter.SelectAdapter
            name="nation_code"
            label={t('residence_country')}
            variant="filled"
            items={Functions.getConstant(Constants.User.Nation).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input half-width">
          <Adapter.NumberFieldAdapter
            label={t('postal_code')}
            name="postal_code"
            variant="filled"
            placeholder="100-8791"
            maskFormat="###-#####"
            viewMode={confirming}
            formik={formik}
            fullWidth
          />
        </div>

        <div className="form-input half-width">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('prefecture')}
            name="prefecture"
            variant="filled"
            placeholder={t('ex_prefecture')}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('municipality')}
            name="municipality"
            variant="filled"
            placeholder={t('ex_municipality')}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('address')}
            name="address"
            variant="filled"
            placeholder={t('ex_address')}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t('v_back')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel2Element = props => {
  const {
    alert,
    formData,
    backStepRequest,
    submitRequest,
  } = props;

  const { t } = useTranslation();
  
  const formik = useFormik({
    initialValues: Functions.fillFormData({
      identification: "",
      confirm_document: "",
    }, formData),
    validationSchema: Yup.object({
      identification: Yup
        .mixed()
        .required(t('Validation.required')),
      confirm_document: Yup
        .mixed()
        .required(t('Validation.required')),
        // .test(
        //   "fileSize",
        //   "File too large",
        //   value => value && value.size <= FILE_SIZE
        // )
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  const isIndividual = formData.account_type_code === Constants.User.AccountType.Individual.code;

  return (
    <>
      <div className="upload">
        <form className="stepper-form main-form" onSubmit={formik.handleSubmit}>
          <div className="form-input">
              <Adapter.AlertAdapter alert={alert} />
          </div>

          <div className="form-input">
            <Adapter.FileAdapter
              name="identification"
              label={isIndividual ? t('identification_i') : t('identification_c')}
              accept="image/*"
              multiple
              formik={formik}
              descriptions={
                [
                  t(key + '.f_identification_0'),
                  t(key + '.f_identification_1'),
                  t(key + '.f_identification_2'),
                  t(key + '.f_identification_3'),
                ]
              }
            />
          </div>

          <div className="form-input">
            <Adapter.FileAdapter
              name="confirm_document"
              label={isIndividual ?  t('confirm_document_i') :  t('confirm_document_c')}
              accept="image/*"
              multiple
              formik={formik}
              descriptions={
                isIndividual ? [
                  t(key + '.f_confirm_document_0'),
                  t(key + '.f_confirm_document_1'),
                  t(key + '.f_confirm_document_2'),
                  t(key + '.f_confirm_document_3'),
                ] :
                [
                  t(key + '.f_confirm_document_4'),
                  t(key + '.f_confirm_document_5'),
                  t(key + '.f_confirm_document_6'),
                ]
              }
            />
          </div>

          <div className="action-group">
            <Button
              onClick={backStepRequest}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('v_back')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('v_application')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
const Panel3Element = () => {

  const { t } = useTranslation();

  return (
    <>
      <form className="completed main-form">
        <p>{t(key + '.panel3_0')}</p>
        <p>{t(key + '.panel3_1')}</p>
        <p>{t(key + '.panel3_2')}</p>
        <p>{t(key + '.panel3_3')}</p>

        <div className="action-group">
            <Button
              onClick={()=> {window.top.close();}}
              variant="contained"
              color="primary"
            >
              {t('v_close')}
            </Button>
        </div>
      </form>
    </>
  );
};
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';

import * as Functions from '../../utils/functions';
import * as Auths from '../../utils/auth';

const key = 'TraderPage';

// Action Creators
export const actionCreators = createActions(
  'SET_VIEW_MODE_REQUEST',
  'LOAD_TRADER_REQUEST',
  'LOAD_TRADER_RESPONSE',
  'LOAD_DEALS_REQUEST',
  'LOAD_DEALS_RESPONSE',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  },
);

export const {
  setViewModeRequest,
  loadTraderRequest,
  loadTraderResponse,
  loadDealsRequest,
  loadDealsResponse,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
  trader: false,
  user: false,
  deals: [],
  transactions: [],
  pairsRates: [],
  dailies: [],
};

export const reducer = handleActions(
  {
    [setViewModeRequest]: (state, { payload }) => ({
      ...state,
      viewMode: payload,
    }),
    [loadTraderRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      trader: false,
      user: false,
    }),
    [loadTraderResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          return {
            ...state,
            trader: payload.data,
            user: payload.data.userData,
            // loading: false,
          };
        }
        return {
          ...state,
          trader: false,
          user: false,
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          trader: false,
          user: false,
          alert: payload,
          loading: false,
        };
      },
    },
    [loadDealsRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      deals: [],
      transactions: [],
      pairsRates: [],
      dailies: [],
    }),
    [loadDealsResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          var dailies = state.trader.daily.filter((daily) => {
            let dailyDate = daily.date;
            let isDisplay = true;
            payload.data.transactions.forEach(transaction => {
              let transactionDate = Functions.formatDateTime(new Date(transaction.mt5_time), 'yyyy-MM-dd');
              if (dailyDate === transactionDate) {
                isDisplay = false;
                console.log("Ignored daily date: ", dailyDate);
              }
            });
            return isDisplay;
          });
          return {
            ...state,
            deals: payload.data.deals,
            transactions: payload.data.transactions,
            pairsRates: payload.data.pairs_rates,
            dailies: dailies,
            loading: false,
          };
        }
        return {
          ...state,
          deals: [],
          transactions: [],
          pairsRates: [],
          dailies: [],
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          deals: [],
          transactions: [],
          pairsRates: [],
          dailies: [],
          alert: payload,
          loading: false,
        };
      },
    },
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

const makeSelectTrader = () =>
  createSelector(
    originSelector,
    state => state.trader
  );

const makeSelectUser = () =>
  createSelector(
    originSelector,
    state => state.user
  );

const makeSelectDeals = () =>
  createSelector(
    originSelector,
    state => state.deals
  );

const makeSelectPairsRates = () =>
  createSelector(
    originSelector,
    state => state.pairsRates
  );

const makeSelectTransactions = () =>
  createSelector(
    originSelector,
    state => state.transactions
  );

const makeSelectDailies = () =>
  createSelector(
    originSelector,
    state => state.dailies
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectViewMode,
  makeSelectTrader,
  makeSelectUser,
  makeSelectDeals,
  makeSelectPairsRates,
  makeSelectTransactions,
  makeSelectDailies,
};

export function* loadTrader() {
  try {
    const values = yield select(makeSelectValues());
  
    var response = yield call(
      Functions.makeRequestCall,
      'GET',
      'traders/' + values,
      {}
    );

    if (response.result) {
      // fetch deals
      yield put(loadDealsRequest(response.data.trader_id));

      // handle data
      var trader = response.data;
      var currentUser = Auths.getUser();
      if (currentUser?.followings) {
        var followingIds = currentUser.followings.map(x => x.trader_id);
        if (followingIds.includes(trader.trader_id)) {
          // followed
          trader.followed = true;
        } else {
          trader.followed = false;
        }
      }
      
      // data dumping
      const dumps = ["yukki", "AutumnSpring", "michi", "KAE", "trabun", "hanahana", "KOHKI"];
      if (dumps.includes(trader.nickname)) {
        let index = dumps.indexOf(trader.nickname) + 3;
        Functions.fillTraderDumpData(trader, index);
      }

      response.data = trader;
    }

    yield put(loadTraderResponse(response));
  } catch (e) {
    console.log('response', e);
    yield put(loadTraderResponse(e));
  }
}

export function* loadDeals() {
  try {
    const values = yield select(makeSelectValues());
  
    var response = yield call(
      Functions.makeRequestCall,
      'GET',
      'traders/' + values + '/deals',
      {}
    );

    yield put(loadDealsResponse(response));
  } catch (e) {
    console.log('response', e);
    yield put(loadDealsResponse(e));
  }
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(loadTraderRequest, loadTrader);
  yield takeLatest(loadDealsRequest, loadDeals);
  yield takeLatest(showDialogChildRequest, showDialogChild);
}
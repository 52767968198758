import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  makeSelectTrader,
  reducer,
  saga,
} from './TraderEditPage.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TraderEditPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import PriceField from '../../components/PriceField/PriceField';

const key = 'TraderEditPage';

const TraderEditPage = props => {
  const {
    loading,
    viewMode,
    trader,
    submitRequest,
    setViewModeRequest,
    loadTraderRequest,
    showDialogChildRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTraderRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      trader_kind_code: trader?.trader_kind_code,
      operation_method_code: trader?.operation_method_code,
      nickname: trader?.nickname,
      message: trader?.message,
      required_fund_unit: trader?.required_fund_unit,
      required_fund: trader?.required_fund,
      success_fee: trader?.success_fee,
      avatar: "",
    },
    validationSchema: Yup.object({
      nickname: Yup
        .string()
        .required(t('Validation.required')),
      message: Yup
        .mixed()
        .required(t('Validation.required')),
      required_fund: Yup
        .string()
        .required(t('Validation.required')),
      avatar: Yup
        .mixed(),
    }),
    onSubmit: (values) => {
      showDialogChildRequest({
        title: t('confirm'),
        variant: "confirm",
        content: (
          <>
            <p>{t(key + '.dialog0_0')}</p>
            <p>{t(key + '.dialog0_1')}</p>
          </>
        ),
        cancelLabel: t('no'),
        acceptLabel: t('yes'),
        nestedCall: true,
        acceptCallback: () => {
          values.successPayload = {
            title: t(key + ".dialog1_0"),
            variant: "info",
            content: (
              <>
                <p>{t(key + ".dialog1_1")}</p>
                <p>{t(key + ".dialog1_2")}</p>
              </>
            ),
            acceptLabel: t('v_close'),
          }
          submitRequest(values);
        }
      });
    },
    enableReinitialize: true,
  });

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <form className="main-form" onSubmit={formik.handleSubmit}>

            <Grid container className="wrapper">
              <Grid className="item" item md={3}>
                <Adapter.FileAdapter
                  name="avatar"
                  label={t('upload')}
                  accept="image/*"
                  url={Functions.getS3URL(trader?.avatar)}
                  viewMode={viewMode}
                  formik={formik}
                />
              </Grid>
              <Grid className="item" item md={6}>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('nickname')}
                    name="nickname"
                    variant="filled"
                    viewMode={true}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextareaAdapter
                    name="message"
                    label={t('message')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <div className="form-input">
                    <PriceField
                      label={t('required_fund')}
                      name="required_fund"
                      formik={formik}
                      viewMode={viewMode}
                    />
                  </div>
                </div>

                <div className="form-input">
                  <Adapter.RangeAdapter
                    name="success_fee"
                    type="input"
                    label={t('success_fee')}
                    marks={Functions.getConstant(Constants.Trader.SuccessFeeMark).inputList}
                    unit={"%"}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="action-group">
                  {
                    viewMode ?
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setViewModeRequest(false)}
                      >
                        {t('v_edit')}
                      </Button> :
                      <>
                        <Button
                          className="backButton"
                          variant="contained"
                          color="default"
                          onClick={() => setViewModeRequest(true)}
                        >
                          {t('v_back')}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {t('v_application')}
                        </Button>
                      </>
                  }
                </div>

              </Grid>
            </Grid>
          </form>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

TraderEditPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
  trader: makeSelectTrader(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TraderEditPage);
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectIsOpen,
  makeSelectPayload,
  makeSelectEditing,
  reducer,
  saga,
} from './MasterDialog.reducer';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  Icon,
  Paper,
  DialogTitle,
} from '@mui/material';
import {
  Close,
} from '@mui/icons-material';
import Draggable from 'react-draggable';

import './MasterDialog.scss';

const key = 'MasterDialog';

const Transition = React.forwardRef(function Transition(propsa, ref) {
  return <Slide direction="up" ref={ref} {...propsa} />;
});

const PaperComponent = props => {
  return (
    <Draggable handle="#MasterDialogTitle" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const ActionComponent = props => {
  const {
    payload,
    isEditing,
    formId,
    hideRequest,
    setEditingRequest,
    showNestedDialogChildRequest,
  } = props;

  const handleCancelCallback = () => {
    if(payload.cancelCallback) {
      payload.cancelCallback();
    }
    hideRequest();
  };

  const handleAcceptCallback = () => {
    if(payload.acceptCallback) {
      payload.acceptCallback();
    }
    if (!payload.nestedCall) {
      hideRequest();
    }
  };

  const handleDeleteCallback = () => {
    if(payload.deleteCallback) {
      showNestedDialogChildRequest({
        title: "削除",
        content: (
          <>
            <p>削除したら復元できません。</p>
            <p>削除してもよろしいでしょうか？</p>
          </>
        ),
        cancelLabel: "キャンセル",
        acceptLabel: "削除する",
        isConfirmation: true,
        acceptCallback: () => {
          payload.deleteCallback();
          hideRequest();
        }
      });
    }
  };

  const EditOrShowElement = () => {
    return (
      <>
        {
          isEditing ?
          <IconButton
            color="secondary"
            aria-label="詳細"
            onClick={() => setEditingRequest(false)}
          >
            <Icon className="icon">visibility</Icon>
          </IconButton> :
          <IconButton
            color="secondary"
            aria-label="編集"
            onClick={() => setEditingRequest(true)}
          >
            <Icon className="icon">edit</Icon>
          </IconButton>
        }
      </>
    );
  }

  const handleSubmitCallback = () => {
    // { cancelable: true } required for Firefox
    // https://github.com/facebook/react/issues/12639#issuecomment-382519193
    const target = document.getElementById(formId);

    if (target) {
      target.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };
  
  switch(payload.variant) {
    case 'create':
      return (
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleSubmitCallback}
          >作成</Button>
        </DialogActions>
      );
    case 'show':
      return (
        <DialogActions>
          <IconButton
            color="secondary"
            aria-label="削除"
            onClick={handleDeleteCallback}
          >
            <Icon className="icon">delete</Icon>
          </IconButton>
          <EditOrShowElement />
          {
            isEditing &&
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmitCallback}
            >変更</Button>
          }
        </DialogActions>
      );
    case 'confirm':
      return (
        <DialogActions>
          <Button onClick={handleCancelCallback} color="primary">
            {payload.cancelLabel}
          </Button>
          <Button onClick={handleAcceptCallback} color="primary" autoFocus>
            {payload.acceptLabel}
          </Button>
        </DialogActions>
      );
    case 'info':
      return (
        <DialogActions>
          <Button onClick={handleAcceptCallback} color="primary" autoFocus>
            {payload.acceptLabel}
          </Button>
        </DialogActions>
      );
    case 'panel':
      return (<></>);
    default:
      return (<></>);
  }
}

const MasterDialog = props => {
  const {
    hideRequest,
    isOpen,
    payload,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const handleCancelCallback = () => {
    if(payload.cancelCallback) {
      payload.cancelCallback();
    }
    hideRequest();
  };

  return (
    <>
      <section className={key}>
        <Dialog
            open={isOpen}
            onClose={handleCancelCallback}
            TransitionComponent={Transition}
            className={key}
            aria-labelledby="MasterDialogTitle"
            PaperComponent={PaperComponent}
          >
            <DialogTitle id="MasterDialogTitle">
              <div>{payload.title}</div>
              <IconButton aria-label="close" onClick={handleCancelCallback} className="close-btn">
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {payload.content}
            </DialogContent>
            <ActionComponent {...props} />
          </Dialog>
      </section>
    </>
  );
};

MasterDialog.propTypes = {
  isEditing: PropTypes.bool,
  isOpen: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isOpen: makeSelectIsOpen(),
  payload: makeSelectPayload(),
  isEditing: makeSelectEditing(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(MasterDialog);
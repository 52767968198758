import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  reducer,
  saga,
} from './AdminTopicCreatePage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdminTopicCreatePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'AdminTopicCreatePage';

const AdminTopicCreatePage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
      <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdminTopicCreatePage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdminTopicCreatePage);

const Panel0Element = props => {
  const {
    confirming,
    activeStep,
    submitRequest,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      type_code: Constants.Topic.Type.Notification.code,
      title: "",
      image: "",
      content: "",
    },
    validationSchema: Yup.object({
      type_code: Yup
        .string()
        .required(t('Validation.required')),
      title: Yup
        .string()
        .required(t('Validation.required')),
      image: Yup
        .mixed()
        .required(t('Validation.required')),
      content: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      if (!confirming) {
        nextStepRequest(values);
        setConfirmRequest(true);
      } else {
        submitRequest(values);
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        {
          !confirming ?
            <p>{t(key + '.panel0_0')}</p> :
            <p>{t(key + '.panel0_1')}</p>
        }

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t('type')}
            name="type_code"
            color="primary"
            row
            items={Functions.getConstant(Constants.Topic.Type).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('title')}
            name="title"
            variant="filled"
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.FileAdapter
            name="image"
            label={t('image')}
            accept="image/*"
            viewMode={confirming}
            formik={formik}
            descriptions={[
              t(key + '.f_image_0'),
              t(key + '.f_image_1'),
            ]}
          />
        </div>

        <div className="form-input">
          <Adapter.TextareaAdapter
            name="content"
            label={t('body')}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="action-group">
          {
            activeStep === 1 &&
            <Button
              onClick={backStepRequest}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('v_back')}
            </Button>
          }

          <Button
              type="submit"
              variant="contained"
              color="primary"
          >
            { confirming ? t('v_register') : t('v_next') }
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <form className="main-form">
        <p>{t(key + '.panel1_0')}</p>

        <div className="action-group">
          <Button
            onClick={() => { window.location.reload() }}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>
    </>
  );
};
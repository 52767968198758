import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectTraders,
  makeSelectFollowingTraders,
  reducer,
  saga,
} from '../Ranking/Ranking.reducer';

import { Chart, LineSeries } from '@devexpress/dx-react-chart-material-ui';
import {
  Button,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Ranking.scss';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import Avatar from '../../components/Avatar/Avatar';

const key = 'Ranking';

const Ranking = props => {
  const {
    traders,
    followingTraders,
    displayFollowings,
    displayMore,
    displayFollow,
    loadTradersRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTradersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      id: 'ranking',
      numeric: true,
      disablePadding: true,
      label: t('ranking'),
    },
    {
      id: 'avatar',
      numeric: false,
      disablePadding: false,
      label: ' ',
      customRender: (value) => {
        return (
          <Avatar path={value} />
        )
      }
    },
    {
      id: 'nickname',
      numeric: false,
      disablePadding: true,
      label: t('nickname'),
      customRender: (value, row) => {
        const traderKind = Functions.getConstant(
          Constants.Trader.TraderKind,
          row.trader_kind_code
        ).this;
        const operationMethod = Functions.getConstant(
          Constants.Trader.OperationMethod,
          row.operation_method_code
        ).this;
        return (
          <div className="nickname">
            <Link className="link" href={"/trader/" + row.trader_id}>
              {value}
            </Link>
            <div className={"tag " + traderKind.className}>
              {t(traderKind.name)}
            </div>
            <div className={"tag " + operationMethod.className}>
              {operationMethod.label}
            </div>
          </div>
        )
      }
    },
    {
      id: 'investment_return_rate',
      numeric: false,
      disablePadding: true,
      label: t('investment_profit_rate'),
      customRender: (value) => {
        return (
          <div>
            {value ? value + '%' : '0%'}
          </div>
        )
      }
    },
    {
      id: 'total_daily_profit',
      numeric: false,
      disablePadding: true,
      label: t('profit_pip'),
      customRender: (value) => {
        return (
          <div>
            {value ? Functions.makeAbbreviation(value) : '0'}
          </div>
        )
      }
    },
    {
      id: 'daily',
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: t('profit_loss'),
      customRender: (value) => {
        return <ChartElement data={value ? value : []} />
      }
    },
    {
      id: 'max_decline_rate',
      numeric: false,
      disablePadding: true,
      label: t('max_decline_rate'),
      customRender: (value) => {
        return (
          <div>
            {value || value === 0 ? value + '%' : '0%'}
          </div>
        )
      }
    },
    {
      id: 'total_trading_period',
      numeric: false,
      disablePadding: true,
      label: t('trading_period'),
      customRender: (value) => {
        return (
          <div>
            {value}{t('days')}
          </div>
        )
      }
    },
    {
      id: 'fake_follower_count',
      numeric: false,
      disablePadding: true,
      label: t('followers'),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: true,
      label: ' ',
      excluded: displayFollow ? false : true,
      customRender: (value, row) => {
        return row.followed ? (
          <Button
            className="action btn-danger"
            href={"/unfollow/" + row.trader_id}
            size="small"
            variant="contained"
            target="_blank"
          >
            {t('release')} －
          </Button>
        ) :
        (
          <Button
            className="action follow"
            href={"/follow/" + row.trader_id}
            size="small"
            variant="contained"
            color="primary"
            target="_blank"
          >
            {t('follow')} ＋
          </Button>
        )
      }
    },
  ];

  return (
    <>
      <section className={key}>
        <Adapter.TableAdapter
          idField="trader_id"
          columns={columns}
          rows={displayFollowings ? followingTraders : traders}
          usePagination
          paginationThreshold={displayMore ? 10 : 5}
        />
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  traders: makeSelectTraders(),
  followingTraders: makeSelectFollowingTraders(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(Ranking);

const ChartElement = props => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Chart
      width={175}
      height={50}
      data={data}
      className="chart"
    >
      <LineSeries
        name={t('pip')}
        valueField="mt5_balance"
        argumentField="date"
      />
    </Chart>
  );
};

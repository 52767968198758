import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  makeSelectTopics,
  reducer,
  saga,
} from './AdminTopicDeletePage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdminTopicDeletePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'AdminTopicDeletePage';

const AdminTopicDeletePage = props => {
  const {
    loading,
    loadTopicsRequest,
  } = props;

  const { t } = useTranslation();
  
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadTopicsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdminTopicDeletePage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
  topics: makeSelectTopics(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdminTopicDeletePage);

const Panel0Element = props => {
  const {
    topics,
    formData,
    confirming,
    activeStep,
    nextStepRequest,
    submitRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;
  
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      topic_ids: [],
    }, formData),   
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      if (!confirming) {
        nextStepRequest(values);
        setConfirmRequest(true);
      } else {
        submitRequest(values);
      }
    },
    enableReinitialize: true,
  });

  const columns = [
    {
      id: 'id',
      excluded: true,
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: t('publish_date'),
      customRender: (value, row) => {
        return (
          <>
            {Functions.formatDateTime(new Date(value), 'yyyy/MM/dd')}
          </>
        );
      }
    },
    {
      id: 'type_code',
      numeric: false,
      disablePadding: false,
      label: t('kind'),
      customRender: (value, row) => {
        const type = Functions.getConstant(Constants.Topic.Type, value).this;

        return (
          <>
            <span className={"tag " + type.className}>
              {t(type.name)}
            </span>
          </>
        );
      }
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: t('title'),
    },
  ];

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>

        {
          confirming ?
          <p>{t(key + '.panel0_0')}</p> :
          <p>{t(key + '.panel0_1')}</p>
        }
        
        <Adapter.TableAdapter 
          columns={columns}
          rows={topics}
          usePagination
          paginationThreshold={50}
          idField="topic_id"
          viewMode={confirming}
          checkboxSelection="topic_ids"
          formik={formik}
        />

        <div className="action-group">
          {
            activeStep === 1 &&
            <Button
              onClick={backStepRequest}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('v_back')}
            </Button>
          }
          <Button
              type="submit"
              variant="contained"
              color="primary"
          >
            { confirming ? t('v_delete') : t('v_next') }
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = () => {
  const { t } = useTranslation();

  return (
    <>
      <form className="main-form">
        <p>{t(key + '.panel1_0')}</p>

        <div className="action-group">
          <Button
            onClick={() => { window.location.reload() }}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>
    </>
  );
};
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  reducer,
  saga,
} from './AdditionalAccountCreatePage.reducer';

import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdditionalAccountCreatePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import { t } from 'i18next';

const key = 'AdditionalAccountCreatePage';

const AdditionalAccountCreatePage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();
  
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdditionalAccountCreatePage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdditionalAccountCreatePage);

const Panel0Element = props => {
  const {
    submitRequest,
  } = props;

  const formik = useFormik({
    initialValues: {
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + ".panel0_0")}</p>

        <div className="action-group">
          <Button type="submit" variant="contained" color="primary">
            {t('v_application')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = () => {

  return (
    <>
      <form className="main-form">
        <p>{t(key + ".panel1_0")}</p>
        <p>{t(key + ".panel1_1")}</p>
        <p>{t(key + ".panel1_2")}</p>
        <p>{t(key + ".panel1_3")}</p>

        <div className="action-group">
          <Button
            onClick={()=> {window.location.replace('/ranking')}}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>
    </>
  );
};
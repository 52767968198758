import React from 'react';
import {
  Grid,
} from '@mui/material';

import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const PriceField = props => {
  const {
    viewMode,
    label,
    name,
    formik,
    descriptions,
  } = props;

  const unitData = Functions.getConstant(Constants.General.Currency).inputList;
  const selectedUnitData = unitData.find(element => element.value === formik.values[name + '_unit']);

  const descriptionLines = !viewMode && descriptions ? descriptions : [];

  return (
    <>
      <Grid container>
        {
          !viewMode &&
          <Grid className="item" item sm={4}>
            <Adapter.RadioGroupAdapter
              name={name + '_unit'}
              row
              items={unitData}
              formik={formik}
            />
          </Grid>
        }
        <Grid className="item" item sm={8}>
          <Adapter.NumberFieldAdapter
            label={label}
            name={name}
            variant="filled"
            prefix={selectedUnitData ? selectedUnitData.label : unitData[0].label}
            thousandSeparator
            viewMode={viewMode}
            fullWidth
            formik={formik}
          />
        </Grid>
      </Grid>
      <br/>
      {descriptionLines.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </>
  );
};

export default PriceField;
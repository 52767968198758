import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './history';
import { reducer as userProviderReducer } from '../containers/AuthProvider/AuthProvider.reducer';

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    auth: userProviderReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });
}
import React from 'react';
import {
  // TextareaAutosize,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material';

import './TextareaAdapter.scss';
import * as Function from '../../../utils/functions';

const key = "TextareaAdapter";

const TextareaAdapter = props => {
  const {
    name,
    label,
    onFieldChange,
    error,
    formik,
    viewMode,
    type,
    ...rest
  } = props;

  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? formik.values[name] : '';
  const displayLabel = label ? label + ": " : "";

  return (
    <>
      <section className={key}>
      {
        viewMode ?
          <div className="view-wrapper">
            <span className="label">{displayLabel}</span>
            <pre className="value">{formik.values[name]}</pre>
          </div> :
          <FormControl error={isInvalid} component="div" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <textarea
              {...rest}
              name={name}
              onChange={e => {
                formik.handleChange(e);
                if (onFieldChange) {
                  onFieldChange(e);
                }
              }}
              value={fieldValue}
              rows={5}
              cols={70}
            />
            {/* Reset form data */}
            {/* <TextareaAutosize
              {...rest}
              name={name}
              onChange={e => {
                formik.handleChange(e);
                if (onFieldChange) {
                  onFieldChange(e);
                }
              }}
              value={fieldValue}
              minRows={3}
            /> */}
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
      }
      </section>
    </>
  );
};

export default TextareaAdapter;
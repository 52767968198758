import React from 'react';
import NumberFormat from 'react-number-format';
import {
  TextField,
} from '@mui/material';

import * as Functions from '../../../utils/functions';

const NumberFieldAdapter = props => {
  const {
    name,
    label,
    error,
    formik,
    onFieldChange,
    maskFormat,
    maskUnit,
    viewMode,
    prefix,
    suffix,
    thousandSeparator,
    ...rest
  } = props;

  const isRecordInvalid = formik.touched[name] && Functions.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? formik.values[name] : '';
  const displayLabel = label ? label + ": " : "";

  const displayValue = thousandSeparator ? Functions.thousandSeparator(fieldValue) : fieldValue;

  return (
    <>
      {
        viewMode ?
          <div className="view-wrapper">
            <span className="label">{displayLabel}</span>
            <span className="value">{prefix}{displayValue}{suffix}</span>
          </div> :
        <NumberFormat
          prefix={prefix}
          suffix={suffix}
          label={label}
          value={fieldValue}
          name={name}
          customInput={TextField}
          isNumericString
          format={maskFormat}
          mask={maskUnit}
          type="text"
          onValueChange={values => {
            formik.setFieldValue(name, values.value);
            formik.setFieldValue(name + "_formatted_value", values.formattedValue);
          }}
          onChange={e => {
            formik.handleChange(e);
            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
          error={isInvalid}
          helperText={helperText}
          thousandSeparator
          {...rest}
        />
      }
    </>
  );
};

export default NumberFieldAdapter;
import React from 'react';
import {
  Button,
} from '@mui/material'
import { useTranslation } from 'react-i18next';

import './RegisterOptions.scss';

const key = 'RegisterOptions';

const RegisterOptions = () => {

  const { t } = useTranslation();

  return (
    <>
      <section className={key}>
        <Button
          className="option"
          href="/register"
          target="_blank"
          variant="outlined"
          color="primary"
          fullWidth
        >
          {t("do_not_have_account_here")}
        </Button>
        <Button
          className="option"
          href="/register-with-account"
          target="_blank"
          variant="outlined"
          color="primary"
          fullWidth
        >
          {t("have_account_here")}
        </Button>
      </section>
    </>
  );
};

export default RegisterOptions;
import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from 'react-helmet-async';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectUser,
  reducer,
  saga,
} from '../AuthProvider/AuthProvider.reducer';
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import {
  AuthenticatedRoute,
} from '../../utils/middleware';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import MasterDialog from '../../components/MasterDialog/MasterDialog';
import {
  HomePage,
  LoginPage,
  ForgotPage,
  ChangePasswordPage,
  ContactPage,
  RegisterPage,
  RegisterWithAccountPage,
  AccountEditPage,

  RankingPage,
  TraderPage,
  TopicListPage,
  TopicPage,
  FollowPage,
  UnfollowPage,
  FollowingPage,
  AboutServicePage,
  FaqPage,

  WithdrawalPage,
  TransferPage,
  TransactionPage,
  DepositPage,
  AdditionalAccountCreatePage,

  TraderCreatePage,
  TraderEditPage,
  TraderDeletePage,
  TraderFollowerPage,

  AdminTraderCreatePage,
  AdminTraderDeletePage,

  AdminTopicCreatePage,
  AdminTopicEditPage,
  AdminTopicDeletePage,

  AdminUserListPage,
  AdminTraderListPage,
  AdminUserShowPage,
  AdminTraderShowPage,

  NotFoundPage,
} from '../../pages';

const App = props => {
  const {
    loading,
    setLocation,
    clearFormData,
  } = props;
  const location = useLocation();
  useEffect(() => {
    setLocation(location);
    clearFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Amplify.configure(awsconfig);

  useInjectReducer({ key: 'auth', reducer });
  useInjectSaga({ key: 'auth', saga: saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <LoadingIndicator loading={loading} />
      <MasterDialog />
      <Switch>
        <Route
          exact
          path="/"
          component={HomePage}
        />

        <Route
          exact
          path="/login"
          component={LoginPage}
        />
        <Route
          exact
          path="/forgot"
          component={ForgotPage}
        />
        <Route
          exact
          path="/change-password"
          component={ChangePasswordPage}
        />
        <Route
          exact
          path="/contact"
          component={ContactPage}
        />
        <Route
          exact
          path="/register"
          component={RegisterPage}
        />
        <Route
          exact
          path="/register-with-account"
          component={RegisterWithAccountPage}
        />
        <AuthenticatedRoute
          exact
          path="/account-edit"
          component={AccountEditPage}
        />

        <Route
          exact
          path="/ranking"
          component={RankingPage}
        />

        <Route
          exact
          path="/trader/:id"
          component={TraderPage}
        />

        <Route
          exact
          path="/topics"
          component={TopicListPage}
        />

        <Route
          exact
          path="/topic/:id"
          component={TopicPage}
        />

        <AuthenticatedRoute
          exact
          path="/follow/:id"
          component={FollowPage}
        />

        <AuthenticatedRoute
          exact
          path="/unfollow/:id"
          component={UnfollowPage}
        />

        <AuthenticatedRoute
          exact
          path="/following"
          component={FollowingPage}
        />

        <Route
          exact
          path="/about-service"
          component={AboutServicePage}
        />

        <Route
          exact
          path="/faq"
          component={FaqPage}
        />

        <AuthenticatedRoute
          exact
          path="/bank/transaction"
          component={TransactionPage}
        />
        <AuthenticatedRoute
          exact
          path="/bank/deposit"
          component={DepositPage}
        />
        <AuthenticatedRoute
          exact
          path="/bank/transfer"
          component={TransferPage}
        />
        <AuthenticatedRoute
          exact
          path="/bank/withdrawal"
          component={WithdrawalPage}
        />
        <AuthenticatedRoute
          exact
          path="/bank/additional-account-create"
          component={AdditionalAccountCreatePage}
        />

        <AuthenticatedRoute
          exact
          path="/trader-create"
          component={TraderCreatePage}
        />
        <AuthenticatedRoute
          exact
          path="/trader-edit"
          component={TraderEditPage}
        />
        <AuthenticatedRoute
          exact
          path="/trader-delete"
          component={TraderDeletePage}
        />
        <AuthenticatedRoute
          exact
          path="/trader-followers"
          component={TraderFollowerPage}
        />

        <AuthenticatedRoute
          exact
          path="/admin/trader-create"
          component={AdminTraderCreatePage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/trader-delete"
          component={AdminTraderDeletePage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/topic-create"
          component={AdminTopicCreatePage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/topic-edit"
          component={AdminTopicEditPage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/topic-delete"
          component={AdminTopicDeletePage}
        />

        <AuthenticatedRoute
          exact
          path="/admin/user-list"
          component={AdminUserListPage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/trader-list"
          component={AdminTraderListPage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/user/:id"
          component={AdminUserShowPage}
        />
        <AuthenticatedRoute
          exact
          path="/admin/trader/:id"
          component={AdminTraderShowPage}
        />
        

        <Route path="" component={NotFoundPage} />
      </Switch>
    </HelmetProvider>
  );
};

App.propTypes = {};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
), memo)(App);
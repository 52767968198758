export const General = {
  S3Directory: {
    Profile: {
      code: "1",
      name: "profile",
      label: "プロフィール",
      path: "profiles/",
    },
    Document: {
      code: "2",
      name: "document",
      label: "資料",
      path: "documents/",
    },
    Topic: {
      code: "3",
      name: "topic",
      label: "トピックス",
      path: "topics/",
    }
  },
  DepositDestination: {
    Bitwallet: {
      code: "1",
      name: "bitwallet",
      label: "bitwallet",
    },
    BankAccount: {
      code: "2",
      name: "bank_account",
      label: "銀行口座",
    }
  },
  DepositMethod: {
    BitwalletTransfer: {
      code: "1",
      name: "bitwallet_transfer",
      label: "bitwallet送金",
    },
    BankTransfer: {
      code: "2",
      name: "bank_transfer",
      label: "銀行振込",
    }
  },
  WithdrawalDestination: {
    Bitwallet: {
      code: "1",
      name: "bitwallet",
      label: "bitwallet",
    },
    BankAccount: {
      code: "2",
      name: "bank_account",
      label: "銀行口座",
    }
  },
  Currency: {
    YEN: {
      code: "￥",
      name: "yen",
      label: "￥",
    },
    USD: {
      code: "$",
      name: "usd",
      label: "$",
    },
  },
  Language: {
    jp: {
      code: "jp",
      name: "jp",
      label: "日本語",
    },
    en: {
      code: "en",
      name: "en",
      label: "English",
    },
  }
}

export const User = {
  AccountType: {
    Individual: {
      code: "個人",
      name: "individual",
      label: "個人",
    },
    Corporation: {
      code: "法人",
      name: "corporation",
      label: "法人",
    }
  },
  Nation: {
    Japan: {
      code: "日本",
      name: "japan",
      label: "日本",
    },
    UnitedStates: {
      code: "United States of America",
      name: "usa",
      label: "United States of America",
    },
  }
}

export const Trader = {
  TraderKind: {
    Trader: {
      code: "トレーダー",
      name: "trader",
      label: "トレーダー",
      className: "tag-green",
    },
    EA: {
      code: "EA",
      name: "ea",
      label: "EA",
      className: "tag-purple",
    }
  },
  OperationMethod: {
    MAM: {
      code: "MAM",
      name: "mam",
      label: "MAM",
      className: "tag-magenta",
    },
    PAMM: {
      code: "PAMM",
      name: "pamm",
      label: "PAMM",
      className: "tag-blue",
    }
  },
  SuccessFeeMark: {
    0: {
      code: 0,
      name: "0",
      label: "0%",
    },
    25: {
      code: 25,
      name: "25",
      label: "25%",
    },
    50: {
      code: 50,
      name: "50",
      label: "50%",
    },
    75: {
      code: 75,
      name: "75",
      label: "75%",
    },
    100: {
      code: 100,
      name: "100",
      label: "100%",
    },
  },
}

export const Topic = {
  Type: {
    Notification: {
      code: "お知らせ",
      name: "notification",
      label: "お知らせ",
      className: "tag-geekblue",
    },
    Event: {
      code: "イベント",
      name: "event",
      label: "イベント",
      className: "tag-orange",
    },
  }
}

export const MT5 = {
  Deals: {
    Action: {
      Buy: {
        code: 0,
        name: "buy",
        label: "Buy"
      },
      Sell: {
        code: 1,
        name: "sell",
        label: "Sell"
      },
      Balance: {
        code: 2,
        name: "balance",
        label: "Balance"
      },
      Credit: {
        code: 3,
        name: "credit",
        label: "Credit"
      },
      Charge: {
        code: 4,
        name: "charge",
        label: "Charge"
      },
      Correction: {
        code: 5,
        name: "correction",
        label: "Correction"
      }
    },
    Type: {
      Deposit: {
        code: 1,
        name: "deposit",
        label: "入金"
      },
      Withdrawal: {
        code: 2,
        name: "withdrawal",
        label: "出金"
      },
    }
  }
}

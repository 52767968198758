import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
} from '@mui/material';
import {
  CloudUpload,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import * as Function from '../../../utils/functions';
import './FileAdapter.scss';

const key = 'FileAdapter';

const FileAdapter = props => {
  const {
    name,
    label,
    error,
    viewMode,
    descriptions,
    onFieldChange,
    url,
    multiple,
    formik,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [fileURLs, setFileURLs] = React.useState([]);

  const handleChange = (e) => {
    formik.handleChange(e);
    formik.setFieldValue('file_' + name, e.target.files[0]);
    formik.setFieldValue('files_' + name, e.target.files);

    var urls = [];
    for (let i = 0; i < e.target.files.length; i++) {
      urls.push(URL.createObjectURL(e.target.files[i]));
    }
    setFileURLs(urls);

    if (onFieldChange) {
      onFieldChange(e);
    }
  };

  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const displayLabel = label ? label + ": " : "";

  const descriptionLines = descriptions ? descriptions : [];

  return (
    <>
      <section className={key}>
        {
          url && fileURLs.length === 0 &&
          <img
            src={url}
            onError={(e) => {
              e.target.src = process.env.REACT_APP_NO_IMAGE_URL;
            }}
            alt="init url" />
        }
        {
          !viewMode &&
          <>
            <FormControl error={isInvalid} component="div">
              <FormLabel component="legend">{displayLabel}</FormLabel>
            <Grid container className="wrapper">
              <Grid className="item" item xs>
              </Grid>
              <Grid className="item" item xs>
              </Grid>
            </Grid>
              <Button color="default" variant="contained" component="label" startIcon={<CloudUpload />}>
                <input
                  hidden
                  type="file"
                  name={name}
                  onChange={handleChange}
                  multiple={multiple}
                  {...rest}
                />
                {t('upload')} {multiple ? t('Message.multiple_selection_possible') : ''}
              </Button>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
            {
              fileURLs.map((url, index) => (
                <div key={index}>
                  <img src={url} alt="upload" />
                </div>
              ))
            }
            {descriptionLines.map((item, index) => (
                <p key={index}>{item}</p>
            ))}
          </>
        }
      </section>
    </>
  );
};

export default FileAdapter;
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import history from '../../utils/history';
import {
  getToken,
  getAWSUser,
} from '../../utils/auth';
import { Auth } from 'aws-amplify';

import {
  // makeSelectLocation,
  makeSelectTempUser,
  loadUserRequest,
  changeAccessToken,
} from '../../containers/AuthProvider/AuthProvider.reducer';

const key = 'ChangePasswordPage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'FAKE_LOGIN_REQUEST',
  {
    prefix: key,
  },
);

export const {
  submitRequest,
  submitResponse,
  fakeLoginRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  accessToken: false,
};

export const reducer = handleActions(
  {
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        // result: payload.result,
        // accessToken: payload.result ? payload.data.access_token: false,
        // alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload.alert,
        loading: false,
      }),
    },
    [fakeLoginRequest]: (state) => ({
      ...state,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
};

export function* submitAWS() {
  try {
    const values = yield select(makeSelectValues());
    const tempUser = yield select(makeSelectTempUser());
    const data = yield call(
      [Auth, 'completeNewPassword'],
      tempUser,
      values.password,
    );

    if (data) {
      const payload = getAWSUser(data);

      yield put(loadUserRequest());
      yield put(submitResponse(payload));

      switch (payload.role) {
        case 'admin':
          history.push("/admin/trader-create");
          break;
        case 'trader':
          history.push("/ranking");
          break;
        default:
          history.push("/ranking");
          break;
          // redirect to indented url
          // const location = yield select(makeSelectLocation());
          // history.replace(location);
      }
    }

  } catch (e) {
    e.alert = {
      type: 'throw',
      message: e.name === 'InvalidPasswordException' ?
      'パスワードのフォーマットが間違っています。' :
      'トークンの有効期限が切れています。',
    }
    yield put(submitResponse(e));
    console.log('Error', e);
  }
}

export function* fakeLogin() {
  const accessToken = getToken();
  yield put(changeAccessToken(accessToken));
  yield put(submitResponse({
  }));

  if (accessToken === 'adminfake') {
    history.push("/admin/trader-create");
  }
  else {
    history.push("/ranking");
  }
}

export function* saga() {
  yield takeLatest(submitRequest, submitAWS);
  yield takeLatest(fakeLoginRequest, fakeLogin);
}
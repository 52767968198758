import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  reducer,
  saga,
} from '../LanguageSelector/LanguageSelector.reducer';

import {
  Button,
  Popover,
  List,
  ListItem,
} from '@mui/material';
import {
  ArrowDropDown,
} from '@mui/icons-material';
import i18next from "i18next";

import './LanguageSelector.scss';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'LanguageSelector';

const LanguageSelector = props => {
  const {
    className
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const defaultCode = Functions.getLanguageCode();
  const languageMap = Functions.getConstant(Constants.General.Language).inputList;

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [selectedCode, setSelectedCode] = React.useState(defaultCode);

  useEffect(() => {
    i18next.changeLanguage(selectedCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const additionClassName = className ? " " + className : "";

  return (
    <>
      <section className={key + additionClassName}>
        <Button
          color="default"
          variant="contained"
          onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        >
          {Functions.getConstant(Constants.General.Language, selectedCode).this.label}
          <ArrowDropDown fontSize="small" />
        </Button>
        <Popover
          open={!!menuAnchor}
          anchorEl={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <div>
            <List>
              {languageMap?.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    setMenuAnchor(null);
                    setSelectedCode(item.value);
                    i18next.changeLanguage(item.value);
                  }}
                >
                  {item.label}
                </ListItem>
              ))}
            </List>
          </div>
        </Popover>
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(LanguageSelector);
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  reducer,
  saga,
} from './ContactPage.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './ContactPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';

const key = 'ContactPage';

const ContactPage = props => {
  const {
    loading,
    submitRequest,
    showDialogChildRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      inquirer: "",
      inquirer_email: "",
      inquiry: "",
    },
    validationSchema: Yup.object({
      inquirer: Yup
        .string()
        .required(t('Validation.required')),
      inquirer_email: Yup
        .string()
        .required(t('Validation.required'))
        .email(t('Validation.invalid_email')),
      inquiry: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      showDialogChildRequest({
        title: t('confirm'),
        variant: "confirm",
        content: (
          <>
            <p>{t(key + ".dialog0_0")}</p>
          </>
        ),
        cancelLabel: t('no'),
        acceptLabel: t('yes'),
        nestedCall: true,
        acceptCallback: () => {
          submitRequest(values);
        }
      });
    },
    enableReinitialize: true,
  });

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h3>{t(key + '.title')}</h3>
          <form className="main-form" onSubmit={formik.handleSubmit}>
            <div className="form-input">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('fullname')}
                name="inquirer"
                variant="filled"
                placeholder={t('ex_fullname')}
                formik={formik}
              />
            </div>
            <div className="form-input">
              <Adapter.TextFieldAdapter
                type="text"
                label={t('email')}
                name="inquirer_email"
                variant="filled"
                placeholder="youremail@email.com"
                formik={formik}
              />
              <small>{t(key + '.0')}</small>
            </div>

            <div className="form-input">
              <Adapter.TextareaAdapter
                name="inquiry"
                label={t('inquiry')}
                formik={formik}
              />
            </div>

            <div className="action-group">
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('v_send')}
              </Button>
            </div>
          </form>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

ContactPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(ContactPage);
import React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import * as Functions from '../../../utils/functions';

const DatePickerAdapter = props => {
  const {
    name,
    label,
    onFieldChange,
    error,
    formik,
    viewMode,
    inputVariant,
  } = props;

  const isRecordInvalid = formik.touched[name] && Functions.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? formik.values[name] : '';
  const displayLabel = label ? label + ": " : "";
  
  return (
    <>
      {
        viewMode ?
          <div className="view-wrapper">
            <span className="label">{displayLabel}</span>
            <span className="value">{fieldValue}</span>
          </div> :
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name={name}
              label={label}
              value={fieldValue}
              onChange={e => {
                formik.setFieldValue(name, Functions.formatDateTime(new Date(e), 'yyyy-MM-dd'));
                if (onFieldChange) {
                  onFieldChange(e);
                }
              }}
              inputFormat="yyyy-MM-dd"
              mask="____-__-__"
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  error={isInvalid}
                  helperText={helperText}
                  variant={inputVariant}
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
      }
    </>
  );
};

export default DatePickerAdapter;
import React from 'react';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './FaqPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';

const key = 'FaqPage';

const FaqPage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>

        <Container>
          <Card className="card">
            <CardHeader className="card-header" title={t(key + '.i0_0')} />

            <CardContent className="card-content">
              <div className="item">
                <p>{t(key + '.i0_1')}</p>
                <p>{t(key + '.i0_2')}</p>
                <p>{t(key + '.i0_3')}</p>
              </div>
            </CardContent>
          </Card>

          <Card className="card">
            <CardHeader className="card-header" title={t(key + '.i1_0')} />

            <CardContent className="card-content">
              <div className="item">
                <p>{t(key + '.i1_1')}</p>
              </div>
            </CardContent>
          </Card>

          <Card className="card">
            <CardHeader className="card-header" title={t(key + '.i2_0')} />

            <CardContent className="card-content">
              <div className="item">
                <p>{t(key + '.i2_1')}</p>
              </div>
            </CardContent>
          </Card>

        </Container>

      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

export default FaqPage;
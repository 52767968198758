import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  actionCreators,
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import AppLayout from '../../layouts/AppLayout/AppLayout';
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';
import AdminDashboardLayout from '../../layouts/AdminDashboardLayout/AdminDashboardLayout';
import UserDashboardLayout from '../../layouts/UserDashboardLayout/UserDashboardLayout';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

const LayoutAdapter = props => {
  const { loading, user, injectElement, persistLayout } = props;

  var Layout = AppLayout;

  if (user) {
    switch (user.role) {
      case "admin":
        Layout = AdminDashboardLayout;
        break;
      case "trader":
        Layout = DashboardLayout;
        break;
      case "user":
        Layout = UserDashboardLayout;
        break;
      default:
        Layout = AppLayout;
    }
  }

  if (persistLayout) {
    switch (persistLayout) {
      case "AppLayout":
        Layout = AppLayout;
        break;
      case "AdminDashboardLayout":
        Layout = AdminDashboardLayout;
        break;
      case "DashboardLayout":
        Layout = DashboardLayout;
        break;
      case "UserDashboardLayout":
        Layout = UserDashboardLayout;
        break;
      default:
        Layout = AppLayout;
    }
  }

  return (
    <>
      <LoadingIndicator loading={loading} />
      <Layout injectElement={injectElement} />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(LayoutAdapter);
import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectIsToggled,
  reducer,
  saga,
} from './UserDashboardLayout.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import Sidebar from '../../components/Sidebar/Sidebar';
import Footer from '../../components/Footer/Footer';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import './UserDashboardLayout.scss';

const key = 'UserUserDashboardLayout';

const theme = createTheme({
  palette: {
    primary: {
      main: '#203864'
    },
    default: {
      main: '#e0e0e0',
      contrastText: '#203864',
    }
  }
});

const UserDashboardLayout = props => {
  const {
    injectElement,
    isToggled,
    setToggle,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    if (window.screen.width >= 800) {
      setToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className={key}>
        <ThemeProvider theme={theme}>
          <Sidebar variant="user" />

          <main className={isToggled ? "main main-menu" : "main no-menu"}>
            <div className="toolbar" />
            {injectElement}
            <Footer />
          </main>
        </ThemeProvider>
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isToggled: makeSelectIsToggled(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(UserDashboardLayout);
// @flow
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import history from '../../utils/history';
import request from '../../utils/request';
import * as Auths from '../../utils/auth';
import * as Functions from '../../utils/functions';

const scope = 'Auth';
const key = 'auth';

// Action Creators
export const actionCreators = createActions(
  'CHANGE_ACCESS_TOKEN',
  'LOAD_USER_REQUEST',
  'LOAD_USER_RESPONSE',
  'LOGOUT_REQUEST',
  'LOGOUT_RESPONSE',
  'CLEAR_ALERT_REQUEST',
  'SET_TEMP_USER',
  'SET_LOCATION',
  'CHARGE_FORM_DATA',
  'CLEAR_FORM_DATA',
  'INITIALIZE',
  {
    prefix: scope,
  },
);

export const {
  changeAccessToken,
  loadUserRequest,
  loadUserResponse,
  logoutRequest,
  logoutResponse,
  clearAlertRequest,
  setTempUser,
  setLocation,
  chargeFormData,
  clearFormData,
  initialize,
} = actionCreators;

// Reducer
export const initialState = {
  accessToken: false,
  loading: true,
  alert: false,
  user: false,
  tempUser: false,
  location: false,
  formData: {},
};

export const reducer = handleActions(
  {
    [changeAccessToken]: (state, { payload }) => {
      Auths.setToken(payload);
      return {
        ...state,
        loading: true,
        accessToken: payload,
      };
    },
    [loadUserRequest]: state => ({
      ...state,
      loading: true,
      alert: false,
      user: false,
    }),
    [loadUserResponse]: {
      next: (state, { payload }) => {
        if (payload.signInUserSession) {
          Auths.setToken(payload.signInUserSession.idToken?.jwtToken);
          return {
            ...state,
            user: payload,
            loading: false,
          };
        }
        Auths.removeToken();
        return {
          ...state,
          user: false,
          accessToken: false,
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        Auths.removeToken();
        return {
          ...state,
          user: false,
          accessToken: false,
          alert: payload,
          loading: false,
        };
      },
    },
    [logoutRequest]: state => ({
      ...state,
      loading: true,
      alert: false,
    }),
    [logoutResponse]: {
      next: (state, { payload }) => {
        if (true) {
          Auths.removeToken();
          return {
            ...state,
            user: false,
            accessToken: false,
            loading: false,
          };
        }
        return {
          ...state,
          alert: payload.alert,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          alert: payload,
          loading: false,
        };
      },
    },
    [clearAlertRequest]: state => ({
      ...state,
      alert: false,
    }),
    [setTempUser]: (state, { payload }) => {
      return {
        ...state,
        tempUser: payload,
      };
    },
    [setLocation]: (state, { payload }) => {
      return {
        ...state,
        location: payload,
      };
    },
    [chargeFormData]: (state, { payload }) => {
      return {
        ...state,
        formData: Object.assign(state.formData, payload),
      };
    },
    [clearFormData]: (state) => {
      return {
        ...state,
        formData: {},
      };
    },
    [initialize]: () => {
      Auths.removeToken();
      return {
        ...initialState,
        loading: false,
      };
    },
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectAccessToken = () =>
  createSelector(originSelector, state => state.accessToken);

const makeSelectLoading = () =>
  createSelector(originSelector, state => state.loading);

const makeSelectAlert = () =>
  createSelector(originSelector, state => state.alert);

const makeSelectUser = () =>
  createSelector(originSelector, state => state.user);

const makeSelectTempUser = () =>
  createSelector(originSelector, state => state.tempUser);
  
const makeSelectLocation = () =>
  createSelector(originSelector, state => state.location);

const makeSelectFormData = () =>
  createSelector(originSelector, state => state.formData);

export {
  originSelector,
  makeSelectAccessToken,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectUser,
  makeSelectTempUser,
  makeSelectLocation,
  makeSelectFormData,
};

export function* loadAWSUser() {
  try {
    const data = yield call(
      [Auth, 'currentAuthenticatedUser'],
      {
        bypassCache: true
      }
    );

    if (data) {
      var payload = Auths.getAWSUser(data);
      
      const userData = yield call(
        Functions.makeRequestCall,
        'GET',
        'users/' + payload.userId,
        {}
      );

      payload.userData = userData?.data;
      // payload.userData = JSON.parse(userData?.data?.replace(/\\/g, ''));

      Auths.setUser(payload.userData);
      yield put(loadUserResponse(payload));
    }
  } catch (e) {
    console.log('response', e);
    yield put(loadUserResponse(e));
  }
}

export function* logoutAWS() {
  try {
    const data = yield call(
      [Auth, 'signOut'],
    );

    yield put(logoutResponse(data));
  } catch (e) {
    console.log('response', e);
    yield put(logoutResponse(e));
  }
}

export function* loadFakeUser() {
  const accessToken = yield select(makeSelectAccessToken());
  if (!accessToken) {
    yield put(loadUserResponse({}));
    return;
  }
  if (accessToken === 'adminfake') {
    yield put(loadUserResponse({
      result: true,
      data: {
        id: 1,
        role_code: 1,
      },
    }));
    return;
  }
  if (accessToken === 'traderfake') {
    yield put(loadUserResponse({
      result: true,
      data: {
        id: 2,
        role_code: 2,
      },
    }));
    return;
  }
  if (accessToken === 'fake') {
    yield put(loadUserResponse({
      result: true,
      data: {
        id: 3,
        role_code: 3,
      },
    }));
    return;
  }
}

export function* logoutFake() {
  const accessToken = yield select(makeSelectAccessToken());
  if (accessToken === 'fake' || accessToken === 'adminfake' || accessToken === 'traderfake') {
    yield put(logoutResponse({
      result: true,
    }));
    history.push("/");
    return;
  }
}

export function* loadUser() {
  try {
    const accessToken = yield select(makeSelectAccessToken());
    const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/current';
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: accessToken ? 'Bearer ' + accessToken : undefined,
      },
    };
    const data = yield call(request, requestURL, requestOptions);
    yield put(loadUserResponse(data));
  } catch (e) {
    console.log('response', e);
    yield put(loadUserResponse(e));
  }
}

export function* logout() {
  try {
    const accessToken = yield select(makeSelectAccessToken());
    const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/logout';
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: accessToken ? 'Bearer ' + accessToken : undefined,
      },
    };
    const data = yield call(request, requestURL, requestOptions);
    yield put(logoutResponse(data));
  } catch (e) {
    console.log('response', e);
    yield put(logoutResponse(e));
  }
}

export function* saga() {
  yield takeLatest(loadUserRequest, loadAWSUser);
  yield takeLatest(logoutRequest, logoutAWS);
  // yield takeLatest(changeAccessToken, loadUser);
  // yield takeLatest(loadUserRequest, loadUser);
  // yield takeLatest(logoutRequest, logout);
}
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import request from '../../utils/request';
import { getCsrfToken } from '../../utils/functions';
import {
  changeAccessToken,
  clearAlertRequest as clearAuthAlert,
} from '../../containers/AuthProvider/AuthProvider.reducer';

const key = 'RankingPage';

// Action Creators
export const actionCreators = createActions(
  'LOAD_USERS_REQUEST',
  'LOAD_USERS_RESPONSE',
  'CREATE_TEST_REQUEST',
  'LOAD_TEST_RESPONSE',
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  {
    prefix: key,
  },
);

export const {
  loadTestRequest,
  loadTestResponse,
  loadUsersRequest,
  loadUsersResponse,
  submitRequest,
  submitResponse
} = actionCreators;

// Reducer
export const initialState = {
  users: [],
  values: false,
  loading: false,
  alert: false,
  result: false,
  accessToken: false,
};

export const reducer = handleActions(
  {
    [loadUsersRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      result: false,
    }),
    [loadUsersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        loading: false,
        users: payload,
      }),
      throw: (state) => ({
        ...state,
        loading: false,
      }),
    },
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        accessToken: payload.result ? payload.data.access_token: false,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectUsers = () =>
  createSelector(
    originSelector,
    state => state.users,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectUsers,
};

export function* loadUsers() {


  try {
    // hit api
    // const apiData = API.graphql({ query: listBlogs });

    // handle response
    // yield put(loadUsersResponse(apiData.data));

  } catch (e) {
    console.log('response', e);
    yield put(loadUsersResponse(e));
  }
}

export function* submit() {
  try {

    // clear auth alert
    yield put(clearAuthAlert());

    const values = yield select(makeSelectValues());
    const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/login';
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': yield call(getCsrfToken),
      },
      body: JSON.stringify(values),
    };

    // hit api
    const data = yield call(request, requestURL, requestOptions);

    // handle response
    yield put(submitResponse(data));
    var result = yield select(makeSelectResult());

    if (result === true) {
      const accessToken = yield select(makeSelectAccessToken());
      yield put(changeAccessToken(accessToken));
    }

  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* saga() {
  yield takeLatest(loadUsersRequest, loadUsers);
  yield takeLatest(submitRequest, submit);
}
import React, { useEffect, useLayoutEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// import { getToken } from '../../utils/auth';

import { actionCreators } from './AuthProvider.reducer';

export function AuthProvider({ children, loadUserRequest }) {
  useLayoutEffect(() => {
    loadUserRequest();
    // changeAccessToken(getToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadUserRequest();
    // changeAccessToken(getToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.Children.only(children);
}

AuthProvider.propTypes = {
  changeAccessToken: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  memo,
)(AuthProvider);
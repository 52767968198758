import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  reducer,
  saga,
} from './RankingPage.reducer';
import {
  makeSelectLoading as makeSelectLoadingRanking,
} from '../../components/Ranking/Ranking.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Grid,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './RankingPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import Ranking from '../../components/Ranking/Ranking';

const key = 'RankingPage';

const RankingPage = props => {
  const {
    loading,
    loadingRanking,
    user,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="ranking">
          <div className="wrapper">
            <Grid container className="grid head">
              <Grid className="item title" item md={6}>
                <h4>{t(key + '.title')}</h4>
              </Grid>
              <Grid className="item back" item md={6}>
                {
                  !user &&
                  <Link href="/" underline="always">{t('home')} ＞</Link>
                }
              </Grid>
            </Grid>

            <Ranking
              displayMore={true}
              displayFollow={true}
            />
          </div>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading || loadingRanking} />
    </>
  );
};

RankingPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingRanking: makeSelectLoadingRanking(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(RankingPage);
import React from 'react';
import {
  Box,
  Slider,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material'

import * as Function from '../../../utils/functions';
import './RangeAdapter.scss';

const key = 'RangeAdapter';

const RangeAdapter = props => {
  const {
    name,
    label,
    error,
    viewMode,
    onFieldChange,
    marks,
    initValue,
    unit,
    formik,
    ...rest
  } = props;

  const handleChange = (e, value) => {
    formik.setFieldValue(name, value);

    formik.handleChange(e);
    if (onFieldChange) {
      onFieldChange(e, value);
    }
  };

  const isRecordInvalid = formik.touched[name] && Function.isValidatorFailed(formik.values[name], error);
  const isFieldInvalid = formik.touched[name] && Boolean(formik.errors[name]);
  const recordHelperText = isRecordInvalid ? error.validators[name] : '';
  const fieldHelperText = isFieldInvalid ? formik.errors[name] : '';
  const isInvalid = isRecordInvalid || isFieldInvalid;
  const helperText = isRecordInvalid ? recordHelperText : fieldHelperText;
  const fieldValue = formik.values[name] ? Number(formik.values[name]) : 0;
  const displayLabel = label ? label + ": " : "";

  return (
    <>
      <section className={key}>
        {
            viewMode ?
              <div className="view-wrapper">
                <span className="label">{displayLabel}</span>
                <span className="value">{fieldValue + unit}</span>
              </div> :
              <FormControl error={isInvalid} component="div" fullWidth>
                <FormLabel component="legend">{label}</FormLabel>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      name={name}
                      onChange={handleChange}
                      value={fieldValue}
                      valueLabelDisplay="on"
                      marks={marks}
                      {...rest}
                    />
                  </Box>
                <FormHelperText>{helperText}</FormHelperText>
              </FormControl>
          }
      </section>
    </>
  );
};

export default RangeAdapter;
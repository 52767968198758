import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  reducer,
  saga,
} from './HomePage.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Link,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './HomePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import Ranking from '../../components/Ranking/Ranking';
import Topics from '../../components/Topics/Topics';

const key = 'HomePage';


const HomePage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // loadUsersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="FTP-ホーム" />
      </Helmet>
      <section className={key}>
        
        <img className="eye-catching" alt="eye-catching" src={t('Path.banner')} />

        <Container className="ranking">
          <h2>{t(key + '.0')}</h2>

          <Container className="wrapper">
            <p>{t(key + '.1')}</p>
            <div className="wrapper-table">
              <Link href="/ranking">
                <h4>{t(key + '.2')}</h4>
              </Link>
              <Ranking />
            </div>
            <div className="view-all">
              <Link href="/ranking">{t('see_all')}</Link>
            </div>
          </Container>
        </Container>

        <Container className="topics">
          <Link href="/topics">
            <h2>{t(key + '.3')}</h2>
          </Link>

          <Topics limit={3} />

          <div className="view-all">
            <Link href="/topics">{t('see_all')}</Link>
          </div>
        </Container>

      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

HomePage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(HomePage);
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  TablePagination,
  TableSortLabel,
  Paper,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon
} from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import './TableAdapter.scss';
import * as Adapter from '../../adapters';

const key = "TableAdapter";

const TableAdapter = props => {
  const {
    idField,
    columns,
    rows,
    checkboxSelection,
    radioSelection,
    usePagination,
    paginationThreshold,
    sort,
    formik,
    viewMode,
    onFieldChange,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    setPage(0);
  }, [viewMode]);

  const idFieldName = idField ? idField : "id";
  const tableSort = {
    order: sort?.order ? sort.order : 'asc',
    orderBy: sort?.orderBy ? sort.orderBy : columns[0].id,
  };
  const [order, setOrder] = React.useState(tableSort.order);
  const [orderBy, setOrderBy] = React.useState(tableSort.orderBy);
  const [selected, setSelected] = React.useState(radioSelection ? formik.values[radioSelection] : []);

  const perPageOptionsThreshold = paginationThreshold ? paginationThreshold : 5;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPageOptionsThreshold);
  const makePerPageOptions = (threshold) => {
    const optionCount = 4;
    var options = [];
    for (var i = 1; i <= optionCount; i++) {
      options.push(Number(threshold) * Number(i));
    }
    return options;
  };
  const rowsPerPageOptions = makePerPageOptions(perPageOptionsThreshold);

  // handle view mode
  const tableRows = viewMode ? rows.filter((item) => selected.includes(item[idFieldName])) : rows;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (onFieldChange) {
      onFieldChange();
    }
    
    if (event.target.checked) {
      const newSelecteds = tableRows.map((n) => n[idFieldName]);
      setSelected(newSelecteds);

      formik.setFieldValue(checkboxSelection, newSelecteds);
      return;
    }

    setSelected([]);
    formik.setFieldValue(checkboxSelection, []);
  };

  const handleClick = (event, name) => {
    if (viewMode) {return;}

    if (radioSelection) {
      setSelected(name);
      formik.setFieldValue(radioSelection, name);
      if (onFieldChange) {
        onFieldChange();
      }
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (checkboxSelection) {
      formik.setFieldValue(checkboxSelection, newSelected);
      if (onFieldChange) {
        onFieldChange();
      }
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => {
    if (radioSelection) {
      return selected === name;
    }

    return selected.indexOf(name) !== -1;
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;
  const displayColumns = columns.filter((item) => !item.excluded);

  return (
    <>
      <section className={key}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
          >
            <EnhancedTableHead
              idFieldName
              radioSelection={radioSelection}
              checkboxSelection={checkboxSelection}
              columns={displayColumns}
              numSelected={radioSelection ? selected ? 1 : 0 : selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRows.length}
              viewMode={viewMode}
            />
            <TableBody>
              {stableSort(tableRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row[idFieldName]);

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row[idFieldName])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={'row-' + index}
                      selected={isItemSelected}
                    >
                      {
                        checkboxSelection && !viewMode &&
                        <TableCell padding="checkbox">
                          <Adapter.CheckboxAdapter
                            multiple
                            name={checkboxSelection}
                            value={row[idFieldName]}
                            formik={formik}
                            onFieldChange={onFieldChange}
                          />
                        </TableCell>
                      }
                      {
                        radioSelection && !viewMode &&
                        <TableCell padding="checkbox">
                          <Adapter.RadioAdapter
                            name={radioSelection}
                            value={row[idFieldName]}
                            formik={formik}
                            onFieldChange={onFieldChange}
                          />
                        </TableCell>
                      }
                      {displayColumns.map((column, columnIndex) => (
                        <TableCell
                          key={'column-' + columnIndex}
                          className={"td-" + column.id}
                          align="left"
                        >
                          {
                            column.customRender ?
                              column.customRender(row[column.id], row, index) :
                              row[column.id]
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 10 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {
          usePagination &&
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={tableRows.length}
            rowsPerPage={rowsPerPage}
            page={page * rowsPerPage > tableRows.length ? 0 : page}
            labelRowsPerPage={t('rows_per_page')}
            labelDisplayedRows={
              ({ from, to, count, page }) => {
                var totalPages = Number(count / rowsPerPage);
                totalPages = totalPages > Math.round(totalPages) ? Math.round(totalPages) + 1 : Math.round(totalPages);

                return '' + Number(page + 1) + ' / ' + totalPages;
              }
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </section>
    </>
  );
};

export default TableAdapter;

const EnhancedTableHead = props => {
  const {
    checkboxSelection,
    radioSelection,
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    viewMode,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {
          checkboxSelection && !viewMode &&
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        }
        {
          radioSelection  && !viewMode &&
          <TableCell padding="checkbox">
          </TableCell>
        }
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.id ? order : false}
              className="table-th"
            >
              {
                column.disableSort ?
                column.label:
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              }

            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

import React from 'react';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AboutServicePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';

const key = 'AboutServicePage';

const AboutServicePage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();
  
  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>

        <Container>
          <Card>
            <CardHeader className="card-header" title={t(key + '.title')} />

            <CardContent className="card-content">
              <div className="item">
                <h5>{t('company_name')}</h5>
                <p>Thank You Holdings</p>
              </div>

              <div className="item">
                <h5>{t('head_office')}</h5>
                <p>Suite 203 Tsuneo Professional Building, Koror, Koror State, Republic of Palau 96940 P.O. Box 10088, Koror, Republic of Palau 96940</p>
              </div>
            </CardContent>
          </Card>
        </Container>

      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

export default AboutServicePage;
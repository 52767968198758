import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectUsers,
  reducer,
  saga,
} from './TopicListPagereducer';
import {
  makeSelectLoading as makeSelectLoadingAuth,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Grid,
  Link
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TopicListPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import Topics from '../../components/Topics/Topics';

const key = 'TopicListPage';

const TopicListPage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // loadUsersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="topics">
          <Grid container className="grid head">
            <Grid className="item title" item md={6}>
              <h2>{t(key + '.title')}</h2>
            </Grid>
            <Grid className="item back" item md={6}>
              <Link href="/" underline="always">{t('home')} ＞</Link>
            </Grid>
          </Grid>

          <Topics />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

TopicListPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  alertAuth: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingAuth: makeSelectLoadingAuth(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  users: makeSelectUsers(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TopicListPage);
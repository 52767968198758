import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  makeSelectConfirming,
  reducer,
  saga,
} from './DepositPage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
  Link
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './DepositPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import * as Auths from '../../utils/auth'
import PriceField from '../../components/PriceField/PriceField';
import MT5Accounts from '../../components/MT5Accounts/MT5Accounts';

const key = 'DepositPage';

const DepositPage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
              t(key + '.step3'),
              t(key + '.step4'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
              <Panel2Element
                {...props}
              />,
              <Panel3Element
                {...props}
              />,
              <Panel4Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

DepositPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(DepositPage);

const Panel0Element = props => {
  const {
    formData,
    nextStepRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      account: accounts[0]?.mt5_login ? accounts[0]?.mt5_login : 0,
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel0_0')}</p>

        <MT5Accounts
          formik={formik}
          fieldName={"account"}
        />
     
        <div className="action-group">
          <Button type="submit" variant="contained" color="primary">
            {t("v_next")}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = props => {
  const {
    formData,
    confirming,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();
  
  const formik = useFormik({
    initialValues: Functions.fillFormData({
      deposit_destination_code: Constants.General.DepositDestination.Bitwallet.code,
      deposit_method_code: Constants.General.DepositMethod.BitwalletTransfer.code,
      bitwallet_mail: "",
      account_number: "",
      account_holder: "",
    }, formData),
    validationSchema: Yup.object({
      deposit_destination_code: Yup
        .string()
        .required(t('Validation.required')),
      bitwallet_mail: Yup
        .string()
        .when('deposit_destination_code', {
          is: Constants.General.DepositDestination.Bitwallet.code,
          then: Yup.string().required(t('Validation.required'))
        }),
      account_number: Yup
        .string()
        .when('deposit_destination_code', {
          is: Constants.General.DepositDestination.BankAccount.code,
          then: Yup.string().when('deposit_method_code', {
            is: Constants.General.DepositMethod.BankTransfer.code,
            then: Yup.string().required(t('Validation.required'))
          })
        }),
      account_holder: Yup
        .string()
        .when('deposit_destination_code', {
          is: Constants.General.DepositDestination.BankAccount.code,
          then: Yup.string().when('deposit_method_code', {
            is: Constants.General.DepositMethod.BankTransfer.code,
            then: Yup.string().required(t('Validation.required'))
          })
        }),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel1_0')}</p>

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t(key + '.f_deposit_method')}
            name="deposit_method_code"
            color="primary"
            row
            items={Functions.getConstant(Constants.General.DepositMethod).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t(key + '.f_deposit_destination')}
            name="deposit_destination_code"
            color="primary"
            row
            items={Functions.getConstant(Constants.General.DepositDestination).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        {
          formik.values.deposit_destination_code === Constants.General.DepositDestination.Bitwallet.code ||
          formik.values.deposit_method_code === Constants.General.DepositMethod.BitwalletTransfer.code ?
          <>
            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="text"
                label={t(key + '.f_bitwallet_mail')}
                name="bitwallet_mail"
                variant="filled"
                placeholder="youremail@email.com"
                viewMode={confirming}
                formik={formik}
              />
            </div>
            <p>{t(key + '.panel1_1')}</p>
            <p>{t(key + '.panel1_2', {0: formik.values.bitwallet_mail})}</p>
          </> :
          <>
            <div className="form-input half-width">
              <Adapter.TextFieldAdapter
                type="text"
                label={t(key + '.f_account_holder')}
                name="account_holder"
                variant="filled"
                placeholder="TAROU YAMADA"
                viewMode={confirming}
                formik={formik}
              />
            </div>
            <div className="form-input width-25">
              <Adapter.TextFieldAdapter
                type="text"
                label={t(key + '.f_account_number')}
                name="account_number"
                variant="filled"
                placeholder="9401234"
                viewMode={confirming}
                formik={formik}
              />
            </div>
          </>
        }
        
        <div className="form-input">
          <p>{t(key + '.panel1_3')}</p>
          <Link href="https://gs-ltd.hk/ja/faq/" target="_blank">https://gs-ltd.hk/ja/faq/</Link>
          <p>{t(key + '.panel1_4')}</p>
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            color="default"
            variant="contained"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {confirming ? t('v_application') : t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel2Element = props => {
  const {
    formData,
    confirming,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      amount_unit: "￥",
      amount: "",
    }, formData),
    validationSchema: Yup.object({
      amount: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
      if (!confirming) {
        setConfirmRequest(true);
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel2_0')}</p>

        <p>{t('account')}</p>
        <p className="number">{formData.account}</p>

        <div className="form-input half-width">
          <PriceField
            label={t('deposit_amount')}
            name="amount"
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {confirming ? t('v_application') : t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel3Element = props => {
  const {
    formData,
    submitRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel3_0')}</p>

        <p>{t('account')}</p>
        <p className="number">{formData.account}</p>
        <p>{t('deposit_amount')}</p>
        <p className="number">{formData.amount_formatted_value}</p>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_application')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel4Element = props => {
  const {
    formData,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="completed main-form">
        <p>{t(key + '.panel4_0')}</p>
        <p>{t('account')}: {formData.account}</p>
        {
          formData.deposit_destination_code === Constants.General.DepositDestination.Bitwallet.code ||
          formData.deposit_method_code === Constants.General.DepositMethod.BitwalletTransfer.code ?
          <>
            <p>{t(key + '.panel4_1')}</p>
            <p>{t(key + '.panel4_2')}{formData.email}</p>
            <p>{t(key + '.panel4_3')}{formData.amount_formatted_value}</p>
            <p>{t(key + '.panel4_4')}。</p>
            <Link href="https://gs-ltd.hk/ja/faq/" target="_blank">https://gs-ltd.hk/ja/faq/</Link>
            <p>{t(key + '.panel4_5')}</p>
            <p>{t(key + '.panel4_6')}</p>
            <p>{t(key + '.panel4_7', {0: formData.email})}</p>
            <p>bitwallet</p>
            <Link href="https://secure.bitwallet.com/signin/" target="_blank">https://secure.bitwallet.com/signin/</Link>
          </> :
          <>
            <p>{t(key + '.panel4_8')}</p>
            <p>{t(key + '.panel4_9')}{formData.account_holder}</p>
            <p>{t(key + '.panel4_10')}{formData.account_number}</p>
            <p>{t(key + '.panel4_11')} {formData.amount_formatted_value}</p>
            <p>{t(key + '.panel4_12')}</p>
            <Link href="https://gs-ltd.hk/ja/faq/" target="_blank">https://gs-ltd.hk/ja/faq/</Link>
            <p>{t(key + '.panel4_13')}</p>
          </>
        }
        <p>{t(key + '.panel4_14')}</p>
        
        <div className="action-group">
          <Button
            onClick={()=> {window.location.replace('/ranking')}}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </div>
    </>
  );
};
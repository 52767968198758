// @flow
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const key = 'TabAdapter';

// Action Creators
export const actionCreators = createActions(
  'SET_VALUE',
  {
    prefix: key,
  }
);

export const {
  setValue,
} = actionCreators;

// Reducer
export const initialState = {
  value: 0,
  values: false,
};

export const reducer = handleActions({
  [setValue]: (state, { payload }) => ({
    ...state,
    value: payload,
  }),
}, initialState);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectValue = () =>
  createSelector(
    originSelector,
    state => state.value,
  );

export { 
  originSelector,
  makeSelectValues,
  makeSelectValue,
};

// Redux Saga
export function* showNestedDialogChild() {
}

export function* saga() {}
// @flow
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const key = 'StepperAdapter';

// Action Creators
export const actionCreators = createActions(
  'SET_ACTIVE_STEP',
  {
    prefix: key,
  }
);

export const {
  setActiveStep,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  activeStep: 0
};

export const reducer = handleActions({
  [setActiveStep]: (state, { payload }) => ({
    ...state,
    activeStep: payload,
  }),
}, initialState);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectActiveStep = () =>
  createSelector(
    originSelector,
    state => state.activeStep,
  );

export { 
  originSelector,
  makeSelectValues,
  makeSelectActiveStep,
};

// Redux Saga
export function* saga() {}
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  reducer,
  saga,
} from './RegisterWithAccountPage.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './RegisterWithAccountPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';

const key = 'RegisterWithAccountPage';

const RegisterWithAccountPage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
    
        <Container className="register">
          <h3>{t(key + '.title')}</h3>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
            ]}
          />
        </Container>

      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

RegisterWithAccountPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  confirming: makeSelectConfirming(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(RegisterWithAccountPage);

const Panel0Element = props => {
  const {
    alert,
    formData,
    confirming,
    submitRequest,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      email: "",
      account: "",
    }, formData),
    validationSchema: Yup.object({
      email: Yup
        .string()
        .required(t('Validation.required'))
        .email(t('Validation.invalid_email')),
      account: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      if (!confirming) {
        nextStepRequest(values);
        setConfirmRequest(true);
      } else {
        submitRequest(values);
      }
    },
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <Adapter.AlertAdapter alert={alert} />
        </div>

        {
          !confirming &&
          <>
            <p>{t(key + '.panel0_0')}</p>
            <p>{t(key + '.panel0_1')}</p>
          </>
        }

        <div className="form-input half-width">
          <Adapter.TextFieldAdapter
            type="text"
            label={t(key + '.f_account')}
            name="account"
            variant="filled"
            placeholder="12345"
            viewMode={confirming}
            formik={formik}
          />
        </div>
        
        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t(key + '.f_email')}
            name="email"
            variant="filled"
            placeholder="youremail@email.com"
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="action-group">
          {
            confirming ?
            <Button
              onClick={backStepRequest}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('v_back')}
            </Button> :
            <Button
              onClick={()=> {window.top.close();}}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('cancel')}
            </Button>
          }
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {confirming ? t('v_application') : t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = () => {

  const { t } = useTranslation();

  return (
    <>
      <form className="completed main-form">
        <p>{t(key + '.panel1_0')}</p>
        <p>{t(key + '.panel1_1')}</p>
        <p>{t(key + '.panel1_2')}</p>

        <div className="action-group">
            <Button
              onClick={()=> {window.top.close();}}
              variant="contained"
              color="primary"
            >
              {t('close')}
            </Button>
        </div>
      </form>
    </>
  );
};
// @flow
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const key = 'MasterDialog';

// Action Creators
export const actionCreators = createActions(
  'SHOW_REQUEST',
  'HIDE_REQUEST',
  'SET_EDITING_REQUEST',
  {
    prefix: 'MasterDialog',
  }
);

export const {
  showRequest,
  hideRequest,
  setEditingRequest,
  showNestedDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  isOpen: false,
  payload: false,
  isEditing: false,
  values: false,
};

export const reducer = handleActions({
  [showRequest]: (state, { payload }) => ({
    ...state,
    isOpen: true,
    payload: payload,
    isEditing: false,
  }),
  [hideRequest]: (state) => ({
    ...state,
    isOpen: false,
    isEditing: false,
  }),
  [setEditingRequest]: (state, { payload }) => ({
    ...state,
    isEditing: payload,
  }),
}, initialState);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectIsOpen = () =>
  createSelector(
    originSelector,
    state => state.isOpen,
  );

const makeSelectPayload = () =>
  createSelector(
    originSelector,
    state => state.payload,
  );

const makeSelectEditing = () =>
  createSelector(
    originSelector,
    state => state.isEditing,
  );

export { 
  originSelector,
  makeSelectValues,
  makeSelectIsOpen,
  makeSelectPayload,
  makeSelectEditing,
};

// Redux Saga
export function* saga() {
}
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  makeSelectConfirming,
  reducer,
  saga,
} from './TransferPage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TransferPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Auths from '../../utils/auth';
import PriceField from '../../components/PriceField/PriceField';
import MT5Accounts from '../../components/MT5Accounts/MT5Accounts';

const key = 'TransferPage';

const TransferPage = props => {
  const {
    loading,
  } = props;

  const { t } = useTranslation()

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
              t(key + '.step3'),
              t(key + '.step4'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
              <Panel2Element
                {...props}
              />,
              <Panel3Element
                {...props}
              />,
              <Panel4Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

TransferPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
  formData: makeSelectFormData(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TransferPage);

const Panel0Element = props => {
  const {
    formData,
    nextStepRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      from_account: accounts[0]?.mt5_login ? accounts[0]?.mt5_login : 0,
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel0_0')}</p>

        <MT5Accounts
          formik={formik}
          fieldName={"from_account"}
        />

        <div className="action-group">
          <Button type="submit" variant="contained" color="primary">
            {t("v_next")}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = props => {
  const {
    formData,
    confirming,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      to_account: accounts[0]?.mt5_login ? accounts[0]?.mt5_login : 0,
    }, formData),
    validationSchema: Yup.object({
      // to_account: Yup.mixed()
      //   .notOneOf(
      //     [formData.from_account],
      //     t(key + '.m_0')
      //   ),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
      if (!confirming) {
        setConfirmRequest(true);
      }
    },
    enableReinitialize: true,
  });

  const alert = formik.errors.to_account ? {
    type: "error",
    message: formik.errors.to_account,
  } : false;

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <Adapter.AlertAdapter alert={alert} />
        </div>

        <p>{t(key + '.panel1_0')}</p>
        <p className="number">{formData.from_account}</p>
        <p>{t(key + '.panel1_1')}</p>

        <MT5Accounts
          formik={formik}
          fieldName={"to_account"}
        />

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t("v_back")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {confirming ? t('v_application') : t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel2Element = props => {
  const {
    formData,
    nextStepRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];
  const account = accounts.filter(x => x.mt5_login === formData.from_account)[0];

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      amount_unit: "￥",
      amount: "",
    }, formData),
    validationSchema: Yup.object({
      amount: Yup
      .string()
      .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      nextStepRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel2_0')}</p>

        <p>{t(key + '.panel2_1')}</p>
        <p className="number">{formData.from_account}</p>
        <p>{t(key + '.panel2_2')}</p>
        <p className="number">{Functions.thousandSeparator(account?.available_balance)}</p>
        <p>{t(key + '.panel2_3')}</p>
        <p className="number">{formData.to_account}</p>

        <div className="form-input half-width">
          <PriceField
            label={t(key + '.f_amount')}
            name="amount"
            formik={formik}
            descriptions={[
              t(key + '.f_amount_0'),
              t(key + '.f_amount_1'),
            ]}
          />
        </div>

        <div className="action-group">
          <Button
            onClick={backStepRequest}
            className="backButton"
            variant="contained"
            color="default"
          >
            {t('v_back')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel3Element = props => {
  const {
    formData,
    submitRequest,
    backStepRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
    }, formData),
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="confirm main-form" onSubmit={formik.handleSubmit}>
        <p>{t(key + '.panel3_0')}</p>

        <p>{t(key + '.panel3_1')}</p>
        <p className="number">{formData.from_account}</p>
        <p>{t(key + '.panel3_2')}</p>
        <p className="number">{formData.to_account}</p>
        <br/>
        <p>{t(key + '.panel3_3')}</p>
        <p className="number">{formData.amount_formatted_value}</p>

        <div className="action-group">
            <Button
              onClick={backStepRequest}
              variant="contained"
              className="backButton"
              color="default"
            >
              {t('v_back')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('v_application')}
            </Button>
          </div>
      </form>
    </>
  );
};
const Panel4Element = props => {
  const {
    formData,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="completed main-form">
        <p>{t(key + '.panel4_0')}</p>
        <p>{t(key + '.panel4_1')}</p>
        <p>{t(key + '.panel4_2')}{formData.from_account}</p>
        <p>{t(key + '.panel4_3')}{formData.to_account}</p>
        <p>{t(key + '.panel4_4')}{formData.amount_formatted_value}</p>
        <p>{t(key + '.panel4_5')}</p>
        <p>{t(key + '.panel4_6')}</p>
        <p>{t(key + '.panel4_7')}</p>

        <div className="action-group">
          <Button
            onClick={()=> {window.location.replace('/ranking')}}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </div>
    </>
  );
};
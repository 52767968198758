import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectViewMode,
  reducer,
  saga,
} from './AccountEditPage.reducer';
import {
  makeSelectUser,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Button,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AccountEditPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';

const key = 'AccountEditPage';

const AccountEditPage = props => {
  const {
    loading,
    user,
    viewMode,
    setViewModeRequest,
    submitRequest,
    showDialogChildRequest,
  } = props;

  const { t } = useTranslation();
  const data = user ? user.userData : {};

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: data.first_name,
      last_name: data.last_name,
      fullname: data.first_name + data.last_name,
      username: data.username,
      email: data.email,
      head_phone_number: data.head_phone_number,
      phone_number: data.phone_number,
      nation_code: data.nation_code,
      postal_code: data.postal_code,
      prefecture: data.prefecture,
      municipality: data.municipality,
      address: data.address,
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .required(t('Validation.required')),
      head_phone_number: Yup
        .string()
        .required(t('Validation.required')),
      phone_number: Yup
        .string()
        .required(t('Validation.required')),
      nation_code: Yup
        .string()
        .required(t('Validation.required')),
      postal_code: Yup
        .string()
        .required(t('Validation.required')),
      prefecture: Yup
        .string()
        .required(t('Validation.required')),
      municipality: Yup
        .string()
        .required(t('Validation.required')),
      address: Yup
        .string()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      showDialogChildRequest({
        title: t('confirm'),
        variant: "confirm",
        content: (
          <>
            <p>{t(key + ".dialog0_0")}</p>
            <p>{t(key + ".dialog0_1")}</p>
          </>
        ),
        cancelLabel: t('no'),
        acceptLabel: t('yes'),
        nestedCall: true,
        acceptCallback: () => {
          values.successPayload = {
            title: t(key + ".dialog1_0"),
            variant: "info",
            content: (
              <>
                <p>{t(key + ".dialog1_1")}</p>
                <p>{t(key + ".dialog1_2")}</p>
              </>
            ),
            acceptLabel: t('v_close'),
          }
          submitRequest(values);
        }
      });
    },
    enableReinitialize: true,
  });

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <form className="main-form" onSubmit={formik.handleSubmit}>
            <Grid container className="wrapper">
              <Grid className="item" item md={3}>
                <Adapter.FileAdapter
                  name="avatar"
                  label=""
                  accept="image/*"
                  url={Functions.getS3URL(data.avatar)}
                  viewMode={viewMode}
                  formik={formik}
                />
              </Grid>
              <Grid className="item" item md={9}>

                {
                  viewMode ?
                  <div className="form-input">
                    <Adapter.TextFieldAdapter
                      type="text"
                      label={t('fullname')}
                      name="fullname"
                      variant="filled"
                      viewMode={true}
                      formik={formik}
                    />
                  </div> :
                  <>
                    <div className="form-input">
                      <Adapter.TextFieldAdapter
                        type="text"
                        label={t('first_name')}
                        name="first_name"
                        variant="filled"
                        placeholder={t('ex_first_name')}
                        viewMode={viewMode}
                        formik={formik}
                      />
                    </div>
                    <div className="form-input">
                      <Adapter.TextFieldAdapter
                        type="text"
                        label={t('last_name')}
                        name="last_name"
                        variant="filled"
                        placeholder={t('ex_last_name')}
                        viewMode={viewMode}
                        formik={formik}
                      />
                    </div>
                  </>
                }


                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('email')}
                    name="email"
                    variant="filled"
                    placeholder="youremail@email.com"
                    viewMode={true}
                    formik={formik}
                  />
                </div>

                <div className="form-input half-width">
                  <PhoneNumber
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('username')}
                    name="username"
                    variant="filled"
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input half-width">
                  <Adapter.SelectAdapter
                    name="nation_code"
                    label={t('residence_country')}
                    variant="filled"
                    items={Functions.getConstant(Constants.User.Nation).inputList}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input half-width">
                  <Adapter.NumberFieldAdapter
                    label={t('postal_code')}
                    name="postal_code"
                    variant="filled"
                    placeholder="100-8791"
                    maskFormat="###-#####"
                    viewMode={viewMode}
                    formik={formik}
                    fullWidth
                  />
                </div>

                <div className="form-input half-width">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('prefecture')}
                    name="prefecture"
                    variant="filled"
                    placeholder={t('ex_prefecture')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('municipality')}
                    name="municipality"
                    variant="filled"
                    placeholder={t('ex_municipality')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="form-input">
                  <Adapter.TextFieldAdapter
                    type="text"
                    label={t('address')}
                    name="address"
                    variant="filled"
                    placeholder={t('ex_address')}
                    viewMode={viewMode}
                    formik={formik}
                  />
                </div>

                <div className="action-group">
                  {
                    viewMode ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setViewModeRequest(false)}
                    >
                      {t('v_edit')}
                    </Button> :
                      <>
                        <Button
                          className="backButton"
                          variant="contained"
                          onClick={() => setViewModeRequest(true)}
                          color="default"
                        >
                          {t('v_back')}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {t('v_application')}
                        </Button>
                      </>
                  }
                </div>
              </Grid>
            </Grid>

          </form>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AccountEditPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  viewMode: makeSelectViewMode(),
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AccountEditPage);
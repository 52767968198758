import React from 'react';

import { useLocation } from "react-router-dom";
import classNames from "classnames";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Collapse,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Sidebar.scss';
import RegisterOptions from '../../components/RegisterOptions/RegisterOptions';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import * as Auths from '../../utils/auth';

const key = 'Sidebar';

const Sidebar = props => {

  const {
    isToggled,
    variant,
    user,
    setToggle,
    logoutChildRequest,
    showDialogChildRequest,
  } = props;

  const { t } = useTranslation();

  const curentUser = Auths.getUser();
  const location = useLocation();
  const homeMenus = !user ? [
    {
      "id": 1,
      "link": "/login",
      "icon": "person",
      "text": t('login')
    },
    {
      "id": 2,
      "link": "/register",
      "icon": "account_circle",
      "text": t('register'),
      callback: () => {
        showDialogChildRequest({
          title: t('register'),
          variant: "panel",
          content: (<RegisterOptions />),
        })
      },
    },
  ] : [];
  const userMenus = [
    {
      "id": 1,
      "link": "/ranking",
      "icon": "trending_up",
      "text": t('ranking')
    },
    {
      "id": 2,
      "link": "/account-edit",
      "icon": "account_circle",
      "text": t('user_register_info')
    },
    {
      "id": 3,
      "link": "/bank",
      "icon": "account_balance",
      "text": t('account_management'),
      "nestedItems": [
        {
          "id": 100,
          "link": "/bank/transaction",
          "icon": "account_balance_wallet",
          "text": t('deposit_withdrawal_details')
        },
        {
          "id": 101,
          "link": "/bank/deposit",
          "icon": "library_books",
          "text": t('deposit_application')
        },
        {
          "id": 102,
          "link": "/bank/transfer",
          "icon": "note",
          "text": t('transfer_application')
        },
        {
          "id": 103,
          "link": "/bank/withdrawal",
          "icon": "money",
          "text": t('withdrawal_application')
        },
        {
          "id": 104,
          "link": "/bank/additional-account-create",
          "icon": "payment",
          "text": t('add_account_application')
        },
      ]
    },
    {
      "id": 4,
      "link": "/following",
      "icon": "person_add",
      "text": t('followings')
    },
  ];
  const traderManagementMenus = curentUser.is_trader_registered ?
  [
    {
      "id": 201,
      "link": "/trader-edit",
      "icon": "edit",
      "text": t('register_info')
    },
    {
      "id": 202,
      "link": "/trader-delete",
      "icon": "delete",
      "text": t('delete_application')
    },
    {
      "id": 203,
      "link": "/trader/" + curentUser.user_id,
      "icon": "attach_money",
      "text": t('operation_results')
    },
    {
      "id": 204,
      "link": "/trader-followers",
      "icon": "group",
      "text": t('followers')
    },
  ] :
  [
    {
      "id": 200,
      "link": "/trader-create",
      "icon": "note_add",
      "text": t('register_application')
    },
    {
      "id": 203,
      "link": "/trader/" + curentUser.user_id,
      "icon": "attach_money",
      "text": t('operation_results')
    },
    {
      "id": 204,
      "link": "/trader-followers",
      "icon": "group",
      "text": t('followers')
    },
  ];
  const traderMenus = [
    {
      "id": 1,
      "link": "/ranking",
      "icon": "trending_up",
      "text": t('ranking')
    },
    {
      "id": 2,
      "link": "/account-edit",
      "icon": "account_circle",
      "text": t('user_register_info')
    },
    {
      "id": 3,
      "link": "/bank",
      "icon": "account_balance",
      "text": t('account_management'),
      "nestedItems": [
        {
          "id": 100,
          "link": "/bank/transaction",
          "icon": "account_balance_wallet",
          "text": t('deposit_withdrawal_details')
        },
        {
          "id": 101,
          "link": "/bank/deposit",
          "icon": "library_books",
          "text": t('deposit_application')
        },
        {
          "id": 102,
          "link": "/bank/transfer",
          "icon": "note",
          "text": t('transfer_application')
        },
        {
          "id": 103,
          "link": "/bank/withdrawal",
          "icon": "money",
          "text": t('withdrawal_application')
        },
        {
          "id": 104,
          "link": "/bank/additional-account-create",
          "icon": "payment",
          "text": t('add_account_application')
        },
      ]
    },
    {
      "id": 4,
      "link": "/following",
      "icon": "person_add",
      "text": t('followings')
    },
    {
      "id": 5,
      "link": "/trader",
      "icon": "explicit",
      "text": t('trader_ea_management'),
      "nestedItems": traderManagementMenus,
    },
  ];
  const adminMenus = [
    {
      "id": 3,
      "link": "/admin/user-list",
      "icon": "group",
      "text": t('user_register_info')
    },
    {
      "id": 4,
      "link": "/admin/trader-list",
      "icon": "assignment_ind",
      "text": t('trader_ea_register_info')
    },
    {
      "id": 1,
      "link": "/admin/trader",
      "icon": "explicit",
      "text": t('trader_ea_management'),
      "nestedItems": [
        {
          "id": 100,
          "link": "/admin/trader-create",
          "icon": "note_add",
          "text": t('add')
        },
        {
          "id": 101,
          "link": "/admin/trader-delete",
          "icon": "delete",
          "text": t('delete')
        },
      ]
    },
    {
      "id": 2,
      "link": "/admin/topic",
      "icon": "topic",
      "text": t('topics_management'),
      "nestedItems": [
        {
          "id": 200,
          "link": "/admin/topic-create",
          "icon": "note_add",
          "text": t('publish')
        },
        {
          "id": 201,
          "link": "/admin/topic-edit",
          "icon": "edit",
          "text": t('edit')
        },
        {
          "id": 202,
          "link": "/admin/topic-delete",
          "icon": "delete",
          "text": t('delete')
        },
      ]
    },
  ];

  var menus = homeMenus;
  var homeVariant = variant === 'home';
  switch (variant) {
    case 'home':
      menus = homeMenus;
      break;
    case 'user':
      menus = userMenus;
      break;
    case 'trader':
      menus = traderMenus;
      break;
    case 'admin':
      menus = adminMenus;
      break;
    default:
      menus = homeMenus;
  }

  setActivePrefix(menus, location);

  return (
    <>
      <div className={key}>
        {
          !homeVariant &&
          <Icon
            className={isToggled ? 'toggle-icon toggle-open' : 'toggle-icon toggle-close'}
            onClick={setToggle}
          >
            {isToggled ? 'close' : 'menu'}
          </Icon>
        }
        
        <Drawer
          anchor={homeVariant ? "right" : "left"}
          variant="permanent"
          className="drawer"
          classes={{
            paper: classNames("drawer-paper", {
              "drawer-open": isToggled,
              "drawer-close": !isToggled,
            })
          }}
          open={isToggled}
        >
          <div>
            <div className="drawer-header">
              <div className="brand">
                <Link href="/">
                  <img src="/images/logo.png" alt="alt" />
                </Link>
              </div>
            </div>
            <div className="menu-container">
              <List>
                {menus.map((item, index) => (
                  item.nestedItems ?
                  <NestedListElement
                    key={index}
                    icon={item.icon}
                    text={item.text}
                    nestedItems={item.nestedItems}
                  /> :
                  <Link
                    className="btn-link"
                    href={item.callback ? "#" : item.link}
                    onClick={item.callback ? item.callback : () => {}}
                    key={index}
                  >
                    <ListItem
                      button
                      className={classNames(
                        "list-item",
                        item.actived && "actived"
                      )}
                    >
                      <ListItemIcon><Icon className="icon">{item.icon}</Icon></ListItemIcon>
                      <ListItemText className="text" primary={item.text} />
                    </ListItem>
                  </Link>
                ))}

                {
                  user &&
                  <ListItem button onClick={logoutChildRequest}>
                    <ListItemIcon><Icon className="icon">exit_to_app</Icon></ListItemIcon>
                    <ListItemText className="text" primary={t('logout')} />
                  </ListItem>
                }
              </List>
            </div>

            <LanguageSelector className={"variant " + variant} />
          </div>
        </Drawer>
      </div>
    </>
  )
}

export default Sidebar;

const NestedListElement = props => {
  const { icon, text, nestedItems } = props;

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon">{icon}</Icon>
        </ListItemIcon>
        <ListItemText className="text" primary={text} />
        {
          open ?
          <Icon className="icon">expand_less</Icon> :
          <Icon className="icon">expand_more</Icon> 
        }
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nestedItems.map((item, index) => (
            <Link href={item.link} className="btn-link" key={index}>
              <ListItem
                button
                className={classNames(
                  "list-item nested",
                  item.actived && "actived"
                )}
              >
                <ListItemIcon><Icon className="icon">{item.icon}</Icon></ListItemIcon>
                <ListItemText className="text" primary={item.text} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const setActivePrefix = (menus, location) => {
  var activedCount = 0;

  menus.forEach(function(menu) {
    if (location.pathname === '/') {
      // menus[0].actived = true;
      activedCount++;
    }

    if (location.pathname.includes(menu.link)) {
      menu.actived = true;
      activedCount++;
    } else {
      menu.actived = false;
    }

     // check nested menus
     if (menu.nestedItems) {
      menu.nestedItems.forEach(function(nestedItem) {
        if (location.pathname.includes(nestedItem.link)) {
          nestedItem.actived = true;
          activedCount++;
        } else {
          nestedItem.actived = false;
        }
      })
     }
  })

  // check homepage
  if (activedCount - 1 !== menus.length) {
    // menus[0].actived = false;
  }
}


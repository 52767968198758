import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';
import {
  setActiveStep,
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
  chargeFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import * as Auths from '../../utils/auth';

const key = 'UnfollowPage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'LOAD_TRADER_REQUEST',
  'LOAD_TRADER_RESPONSE',
  'SET_OPERATION_REQUEST',
  'NEXT_STEP_REQUEST',
  'BACK_STEP_REQUEST',
  'SET_CONFIRM_REQUEST',
  'SHOW_DIALOG_CHILD_REQUEST',
  'SET_STEP_REQUEST',
  {
    prefix: key,
  },
);

export const {
  submitRequest,
  submitResponse,
  loadTraderRequest,
  loadTraderResponse,
  setOperationRequest,
  nextStepRequest,
  backStepRequest,
  setConfirmRequest,
  showDialogChildRequest,
  setStepRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  viewMode: true,
  confirming: false,
  operation: "a",
};

export const reducer = handleActions(
  {
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [loadTraderRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      trader: false,
      user: false,
    }),
    [loadTraderResponse]: {
      next: (state, { payload }) => {
        if (payload.result) {
          return {
            ...state,
            trader: payload.data,
            loading: false,
          };
        }
        return {
          ...state,
          trader: false,
          user: false,
          alert: false,
          loading: false,
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          trader: false,
          user: false,
          alert: payload,
          loading: false,
        };
      },
    },
    [setOperationRequest]: (state, { payload }) => ({
      ...state,
      operation: payload,
    }),
    [nextStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [backStepRequest]: (state) => ({
      ...state,
    }),
    [setConfirmRequest]: (state, { payload }) => ({
      ...state,
      confirming: payload,
    }),
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [setStepRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectOperation = () =>
  createSelector(
    originSelector,
    state => state.operation,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectViewMode = () =>
  createSelector(
    originSelector,
    state => state.viewMode,
  );

const makeSelectConfirming = () =>
  createSelector(
    originSelector,
    state => state.confirming,
  );

const makeSelectTrader = () =>
  createSelector(
    originSelector,
    state => state.trader
  );


export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectOperation,
  makeSelectAccessToken,
  makeSelectViewMode,
  makeSelectConfirming,
  makeSelectTrader,
};

export function* submit() {
  try {
    // charge data
    const values = yield select(makeSelectValues());
    yield put(chargeFormData(values));

    var formData = yield select(makeSelectFormData());
  
    // store
    var requestType = 1;
    switch (formData.action) {
      case "a": 
        requestType = 1; // 資金移動なし
        break;
      case "b": 
        requestType = 2; // 資金を口座間で移動
        break;
      case "c": 
      requestType = // 資金を出金
        formData.withdrawal_destination_code === Constants.General.WithdrawalDestination.Bitwallet.code ?
        3 : 4; // bitwallet | 銀行口座
        break;
      default:
        requestType = 1;
    }
    formData.request_type = requestType;
    const data = yield call(
      Functions.makeRequestCall,
      'DELETE',
      'follow/' + formData.trader_id,
      {
        body: formData
      }
    );

    if (data.result) {
      yield put(nextStepRequest());
    }
  
    yield put(submitResponse(data));
  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* loadTrader() {
  try {
    const values = yield select(makeSelectValues());
  
    var response = yield call(
      Functions.makeRequestCall,
      'GET',
      'traders/' + values,
      {}
    );

    // handle data
    var currentUser = Auths.getUser();
    var trader = response.data;
    if (currentUser.followings.includes(trader.trader_id)) {
      // followed
      trader.followed = true;
    } else {
      trader.followed = false;
    }
    trader.userData = response.data.user;

    response.data = trader;

    yield put(loadTraderResponse(response));
  } catch (e) {
    console.log('response', e);
    yield put(loadTraderResponse(e));
  }
}

export function* nextStep() {
  const values = yield select(makeSelectValues());
  yield put(chargeFormData(values));

  var activeStep = yield select(makeSelectActiveStep());
  yield put(setActiveStep(activeStep + 1));

  window.scrollTo(0, 0);
}

export function* backStep() {
  var activeStep = yield select(makeSelectActiveStep());
  if (activeStep === 1) {
    yield put(setConfirmRequest(false));
  }
  yield put(setActiveStep(activeStep - 1));
  
  window.scrollTo(0, 0);
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* setStep() {
  const values = yield select(makeSelectValues());
  yield put(setActiveStep(values));
}

export function* saga() {
  yield takeLatest(submitRequest, submit);
  yield takeLatest(loadTraderRequest, loadTrader);
  yield takeLatest(nextStepRequest, nextStep);
  yield takeLatest(backStepRequest, backStep);
  yield takeLatest(showDialogChildRequest, showDialogChild);
  yield takeLatest(setStepRequest, setStep);
}
import React from 'react';
import {
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Adapter from '../adapters';

const key = 'PhoneNumber';

const PhoneNumber = props => {
  const {
    formik,
    viewMode,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <section className={key}>
        <Grid container className="wrapper">
          <Grid className="item" item md={4}>
            <Adapter.NumberFieldAdapter
              label={t('country_code')}
              name="head_phone_number"
              variant="filled"
              placeholder="+81"
              maskFormat="+###"
              fullWidth
              viewMode={viewMode}
              formik={formik}
            />
          </Grid>

          <Grid className="item" item md={8}>
            <Adapter.NumberFieldAdapter
              label={t('phone_number')}
              name="phone_number"
              variant="filled"
              placeholder="80-1234-5678"
              maskFormat="##-####-######"
              fullWidth
              viewMode={viewMode}
              formik={formik}
            />
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default PhoneNumber;
import { v4 as uuidv4 } from 'uuid';
import { call } from 'redux-saga/effects';
import request from './request';
import { skippedRequest } from './request';
import * as Auths from './auth';
import * as Constants from './constants';

export function makeFormData(values) {

  var formData = new FormData();
  
  for (var key in values) {
    formData.append(key, convertToFormBool(values[key]));
  }

  return formData;
}

export function convertToFormBool(value) {

  if (value === true) {
    return 1;
  }
  if (value === false) {
    return 0;
  }

  return value;
}

export function* makeRequestCall(method, url, payload = {}) {
  const env = {
    "current_id": Auths.getUser()?.user_id ? Auths.getUser()?.user_id : false,
    "current_email": Auths.getUser()?.email ? Auths.getUser()?.email : false,
    "env_user_pool_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "env_s3_bucket": process.env.REACT_APP_AWS_S3_BUCKET,
    "env_mail_source": process.env.REACT_APP_MAIL_SOURCE,
    "env_mail_admin": process.env.REACT_APP_MAIL_ADMIN,
    "env_mail_gst": process.env.REACT_APP_MAIL_GST,
  };
  const headers = {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
    'Content-Type': payload.useFormData ? 'multipart/form-data' : 'application/json',
    // 'Authorization': Auths.getToken(),
  };
  
  const requestAdapter = payload.useSkippedRequest ? skippedRequest : request;
  const requestUrl = payload.useRawUrl ? url : getUrl(url, payload.query);
  const requestHeaders = headers;
  var response = null;

  switch (method.toUpperCase()) {
    case 'GET':
      response = yield call(requestAdapter, requestUrl, {
        method: method,
        headers: requestHeaders,
      });
      break;
    default:
      const requestBody = payload.useFormData ?
        makeFormData(Object.assign(payload.body, env)) :
        JSON.stringify(Object.assign(payload.body, env));
      response = yield call(requestAdapter, requestUrl, {
        method: method,
        headers: requestHeaders,
        body: requestBody,
      });
      break;   
  }

  return response;
}

export function* presignedUploadS3(directory, file) {
  const path =  directory.path + generateFilename(file);

  const presignedUrl = yield call(
    makeRequestCall,
    'POST',
    's3/presigned-url',
    {
      body: {
        key: path,
      },
    }
  );

  if (presignedUrl.data) {
    const headers = {
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Credentials': true,
      'Content-Type': 'image/*',
    };
    yield call(skippedRequest, presignedUrl.data, {
      method: 'PUT',
      headers: headers,
      body: file,
    });
  
    return path;
  }

  return false;
}

export function* getCsrfToken() {
  const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/csrf';
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  // hit api
  const payload = yield call(request, requestURL, requestOptions);

  return payload.result ? payload.data.csrf_token : 'notset';
}

export function isEmpty(obj) {

  if (!obj) {
    return true;
  }

  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function getUrl(endpoint, request) {

  if (!request) {
    return process.env.REACT_APP_API_ENDPOINT + endpoint;
  }

  var pagingString = !isEmpty(request.page) ?
    'page=' + Number(request.page + 1) : '';
  var sortString = !isEmpty(request.sortOrder) ?
    'sort=' + request.sortOrder.name +
    '&direction=' + request.sortOrder.direction : '';
  var limitString = !isEmpty(request.limit) ?
    'limit=' + Number(request.limit) : '';
    
  var filterString = '';
  var filterList = request.filterList ? request.filterList : [];
  filterList.forEach(function (item, index) {
    if (item.length !== 0) {
      filterString += index === 0 ? '' : '&';
      filterString += 'filter_' + request.columns[index].name + '=' + item[0];
    }
  });

  if (pagingString === '' && sortString === '' && limitString ==='' && filterString === '') {
    return process.env.REACT_APP_API_ENDPOINT + endpoint;
  }

  return process.env.REACT_APP_API_ENDPOINT + endpoint + '?'  + pagingString + sortString + limitString + filterString;
}

export function getConstant(path, code) {

  var result = {
    'name': 'notset',
    'label': 'notset',
    'this': {},
    'inputList': []
  };

  Object.keys(path).forEach(function (key) {
    let item = path[key];

    // Mapping input list
    result.inputList.push({
      name: item.name,
      value: item.code,
      label: item.label,
      checked: item.code === code ? true : false,
    })
  });

  if (code || code === 0) {
    Object.keys(path).forEach(function (key) {
      let item = path[key];

      // Set value and label
      if (item.code === code) {
        result.name = item.name;
        result.label = item.label;
        result.this = item;
      }
    });
  }

  return result;
}

export function formatDateTime(datetime, format) {
  format = format.replace(/yyyy/g, datetime.getFullYear());
  format = format.replace(/MM/g, ('0' + (datetime.getMonth() + 1)).slice(-2));
  format = format.replace(/M/g, datetime.getMonth() + 1);
  format = format.replace(/dd/g, ('0' + datetime.getDate()).slice(-2));
  format = format.replace(/d/g, datetime.getDate());
  format = format.replace(/HH/g, ('0' + datetime.getHours()).slice(-2));
  format = format.replace(/H/g, datetime.getHours());
  format = format.replace(/mm/g, ('0' + datetime.getMinutes()).slice(-2));
  format = format.replace(/m/g, datetime.getMinutes());
  format = format.replace(/ss/g, ('0' + datetime.getSeconds()).slice(-2));

  return format;
}

export function makeAbbreviation(value, digits = 1) {

  var number = isFloat(value) ? Math.round(value) : value;

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return number >= item.value;
  });
  return item ? (number / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : number;
}

export function addDays(datetime, days) {
  var result = new Date(datetime);
  result.setDate(result.getDate() + days);
  return result;
}

export function isValidatorFailed(fieldName, alert) {
  if (!alert) {
    return false;
  }
  if (alert.type !== "validate") {
    return false;
  }

  var isInvalid = false;
  Object.keys(alert.validators).forEach(function (key) {
    if (key === fieldName) {
      isInvalid = true;
    }
  });

  return isInvalid;
}

export function fillFormData(initialValues, formData) {
  Object.keys(initialValues).forEach(function (key) {
    initialValues[key] = formData.hasOwnProperty(key) ? formData[key] : initialValues[key];
  });

  return initialValues;
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function generatePassword() {
  var password = "";
  var length = 3;
  var charset = "notset";

  charset = "abcdefghijklmnopqrstuvwxyz";
  for (var i = 0, q = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * q));
  }

  charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var j = 0, w = charset.length; j < length; ++j) {
    password += charset.charAt(Math.floor(Math.random() * w));
  }

  charset = "0123456789";
  for (var k = 0, e = charset.length; k < length; ++k) {
    password += charset.charAt(Math.floor(Math.random() * e));
  }

  charset = "!@#$%^&*()<>";
  charset = "@";
  for (var l = 0, r = charset.length; l < length; ++l) {
    password += charset.charAt(Math.floor(Math.random() * r));
  }

  return password;
}

export function generateFilename(file) {
  if (file.name) {
    return uuidv4() + "_" + file.name;
  }
  
  return uuidv4();
}

export function getS3URL(path) {
  return path ?
    process.env.REACT_APP_S3_URL + path :
    process.env.REACT_APP_NO_IMAGE_URL;
}

export function makeItemList(list, labelField, valueField) {
  var result = [];

  list.forEach(function (item) {
    result.push({
      label: item[labelField],
      value: item[valueField]
    });
  });

  return result;
}

export function thousandSeparator(number) {
  if (number) {
    number = Number(number);
    return (number).toLocaleString('en');
  }

  return number;
}

export function fillTradersDumpData(traders) {
  const realIndex = 3;

  traders.forEach(function (trader, index) {
    if (index > realIndex) {
      fillTraderDumpData(trader, index);
    }
  });

  return traders;
}

export function getFakeFollowers(ranking) {
  var dump = [
    35,
    35,
    52,
    94,
    73,
    12,
    9,
    8,
    6,
    7,
    8,
  ];

  return dump[ranking] ? dump[ranking] : 0;
}

export function fillTraderDumpData(trader, index) {
  const rateSeed = 20;
  const profitSeed = 20000;
  const beginDate = new Date("2021-9-10");

  trader.total_daily_profit_rate = rateSeed + (10.3 - (2 * index));
  trader.total_daily_profit = profitSeed + (12434 - (2343 * index));
  trader.max_decline_rate = rateSeed - (10.3 - (2 * index));
  trader.total_trading_period = Math.floor(index * 10);
  trader.investment_return_rate = 25.7 - index;

  var daily = [];
  for (let i = 0; i < 10; i++) {
    let loopDate = addDays(beginDate, (20 + i));
    let loopProfit = profitSeed + (2434 - (343 * index)) + (2434 + (343 * i));
    let loopRate = rateSeed + (1.3 - (1 * index)) + (1.3 + (1 * i));
    let randomProfitSeed = Math.floor(Math.random() * 3000);
    let randomRateSeed = Math.floor(Math.random() * 5);
    daily.push({
      "date": formatDateTime(loopDate, "yyyy-MM-dd"),
      "mt5_daily_profit": loopProfit - randomProfitSeed,
      "daily_profit_rate": loopRate - randomRateSeed,
      "mt5_balance": loopRate - randomRateSeed,
    });
  }
  trader.daily = daily;

  return trader;
}

export function getLanguageCode() {
  const i18Code = localStorage.getItem("i18nextLng");
  const langList = getConstant(Constants.General.Language).inputList;
  const codeExisting = langList.some(item => item.value === i18Code);
  const defaultCode = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;

  return codeExisting ? i18Code : defaultCode;
}
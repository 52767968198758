import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  makeSelectUsers,
  reducer,
  saga,
} from './AdminTraderCreatePage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './AdminTraderCreatePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';

const key = 'AdminTraderCreatePage';

const AdminTraderCreatePage = props => {
  const {
    loading,
    loadUsersRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadUsersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

AdminTraderCreatePage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
  users: makeSelectUsers(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(AdminTraderCreatePage);

const Panel0Element = props => {
  const {
    users,
    confirming,
    activeStep,
    submitRequest,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      emails: [],
    },
    validationSchema: Yup.object({
      emails: Yup
        .array()
        .min(1, t('Validation.required')),
    }),
    onSubmit: (values) => {
      if (!confirming) {
        nextStepRequest(values);
        setConfirmRequest(true);
      } else {
        submitRequest(values);
      }
    },
    enableReinitialize: true,
  });

  const filledUsers = users.filter(user => user.cognito_role === 'user');
  const columns = [
    {
      id: 'first_name',
      numeric: false,
      disablePadding: false,
      label: t('fullname'),
      customRender: (value, row) => {
        return (
          <>
            {row.first_name + row.last_name}
          </>
        );
      }
    },
    {
      id: 'detail',
      numeric: false,
      disablePadding: false,
      label: t('details'),
      customRender: (value, row) => {
        return (
          <>
            <Link href={"/admin/user/" + row.user_id} target="_blank">{t('user_register_info')}</Link>
          </>
        );
      }
    },
  ];

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        {
          !confirming ?
            <p>{t(key + '.panel0_0')}</p> :
            <p>{t(key + '.panel0_1')}</p>
        }

        <div className="form-input">
          <Adapter.TableAdapter 
            columns={columns}
            rows={filledUsers}
            usePagination
            paginationThreshold={50}
            idField="email"
            checkboxSelection="emails"
            viewMode={confirming}
            formik={formik}
          />
        </div>
        
        <div className="action-group">
          {
            activeStep === 1 &&
            <Button
              onClick={backStepRequest}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('v_back')}
            </Button>
          }

          <Button
              type="submit"
              variant="contained"
              color="primary"
          >
            { confirming ? t('v_add') : t('v_next') }
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = () => {
  const { t } = useTranslation();

  return (
    <>
      <form className="main-form">

        <p>{t(key + '.panel1_0')}</p>
        <p>{t(key + '.panel1_1')}</p>
        <p>{t(key + '.panel1_2')}</p>
        <p>{t(key + '.panel1_3')}</p>

        <div className="action-group">
          <Button
            onClick={()=> {window.location.reload()}}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>
    </>
  );
};
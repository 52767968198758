import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import App from './containers/App/App';
import * as serviceWorker from './utils/serviceWorker';
import configureStore from './utils/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import AuthProvider from './containers/AuthProvider/AuthProvider';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable'
import './utils/i18n';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => { };
}

const store = configureStore({}, history);

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="...">
      <AuthProvider>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="" component={App} />
          </Switch>
        </ConnectedRouter>
      </AuthProvider>
    </Suspense>
  </Provider>,
  MOUNT_NODE
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './LoadingIndicator.scss';

const LoadingIndicator = props => {
  const { loading } = props;

  return (
    <>
      {
        loading &&
        <div className="wrapper">
          <div className="overlay"></div>
          <div className="loading-indicator">
            <CircularProgress />
          </div>
        </div>
      }
    </>
  );
};

export default LoadingIndicator;
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  showRequest as showMasterDialogRequest,
} from '../../components/MasterDialog/MasterDialog.reducer';

import * as Auths from '../../utils/auth';

const key = 'TransactionPage';

// Action Creators
export const actionCreators = createActions(
  'SET_FILTER_REQUEST',
  'SET_TRANSACTIONS_REQUEST',
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'SHOW_DIALOG_CHILD_REQUEST',
  {
    prefix: key,
  },
);

export const {
  setFilterRequest,
  setTransactionsRequest,
  submitRequest,
  submitResponse,
  showDialogChildRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  transactions: [],
};

export const reducer = handleActions(
  {
    [setFilterRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [setTransactionsRequest]: (state, { payload }) => ({
      ...state,
      transactions: payload,
    }),
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        result: payload.result,
        alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
        loading: false,
      }),
    },
    [showDialogChildRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

const makeSelectTransactions = () =>
  createSelector(
    originSelector,
    state => state.transactions,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
  makeSelectTransactions,
};

export function* setFilter() {
  try {
    const values = yield select(makeSelectValues());
    var transactions = Auths.getUser().transactions ? Auths.getUser().transactions : [];
    transactions = transactions.filter(item => item.mt5_login === values.account);

    switch (Number(values.filter_type)) {
      case 1: // 最新20明細
        transactions = transactions.slice(0, 20);
        break;
      case 2: // 今月
        transactions = transactions.filter((item) => {
          var itemDate = new Date(item.mt5_time);
          var matchYear = itemDate.getFullYear() === new Date().getFullYear();
          var matchMonth = itemDate.getMonth === new Date().getMonth();

          return matchYear && matchMonth;
        })
        break;
      case 3: // 前月
        transactions = transactions.filter((item) => {
          var itemDate = new Date(item.mt5_time);
          var matchYear = itemDate.getFullYear() === new Date().getFullYear();
          var matchMonth = itemDate.getMonth === new Date().getMonth() - 1;

          return matchYear && matchMonth;
        })
        break;
      case 4: // 期間指定
        transactions = transactions.filter((item) => {
          var itemDate = new Date(item.mt5_time);
          var fromDate = new Date(values.filter_date_from);
          var toDate = new Date(values.filter_date_to);

          return itemDate.getTime() > fromDate.getTime() && itemDate.getTime() < toDate.getTime();
        })
        break;
      default:
        break;
    }

    yield put(setTransactionsRequest(transactions));

  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* submit() {
  try {

  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* showDialogChild() {
  const values = yield select(makeSelectValues());
  yield put(showMasterDialogRequest(values));
}

export function* saga() {
  yield takeLatest(setFilterRequest, setFilter);
  yield takeLatest(submitRequest, submit);
  yield takeLatest(showDialogChildRequest, showDialogChild);
}
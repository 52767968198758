import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectTransactions,
  reducer,
  saga,
} from './TransactionPage.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TransactionPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import * as Auths from '../../utils/auth';
import MT5Accounts from '../../components/MT5Accounts/MT5Accounts';

const key = 'TransactionPage';

const TransactionPage = props => {
  const {
    loading,
    transactions,
    setFilterRequest,
  } = props;

  const { t } = useTranslation();
  const accounts = Auths.getUser().accounts ? Auths.getUser().accounts : [];
  const initialValues = {
    account: accounts ? accounts[0]?.mt5_login : 0,
    filter_type: 1,
    filter_date_from: Functions.formatDateTime(new Date(), "yyyy-MM-dd"),
    filter_date_to: Functions.formatDateTime(Functions.addDays(new Date(), 1), "yyyy-MM-dd"),
  };

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  
  useEffect(() => {
    setFilterRequest(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      setFilterRequest(values);
    },
    enableReinitialize: true,
  });

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <form className="main-form" onSubmit={formik.handleSubmit}>

            <p>{t(key + '.0')}</p>

            <MT5Accounts
              formik={formik}
              fieldName={"account"}
              onFieldChange={formik.submitForm}
            />

            <br />

            <p>{t(key + '.1')}</p>
            <div className="filter-bar">
              <Adapter.RadioGroupAdapter
                className="filter-type"
                name="filter_type"
                color="primary"
                row
                items={[
                  {
                    name: "latest_20_records",
                    value: 1,
                  },
                  {
                    name: "this_month",
                    value: 2,
                  },
                  {
                    name: "last_month",
                    value: 3,
                  },
                  {
                    name: "specify_period",
                    value: 4,
                  },
                ]}
                formik={formik}
                onFieldChange={formik.submitForm}
              />
            </div>

            <div className="filter-date half-width form-input">
              {
                Number(formik.values.filter_type) === 4 &&
                <Grid container className="grid">
                  <Grid className="item" item md={5}>
                    <Adapter.DatePickerAdapter
                      name="filter_date_from"
                      label={t('date_from')}
                      format="yyyy-MM-dd"
                      inputVariant="standard"
                      formik={formik}
                      onFieldChange={formik.submitForm}
                    />
                  </Grid>

                  <Grid className="item" item md={5}>
                    <Adapter.DatePickerAdapter
                      name="filter_date_to"
                      label={t('date_to')}
                      format="yyyy-MM-dd"
                      inputVariant="standard"
                      formik={formik}
                      onFieldChange={formik.submitForm}
                    />
                  </Grid>
                </Grid>
              }
            </div>

            <Adapter.TableAdapter 
              columns={[
                {
                  id: 'mt5_time',
                  numeric: false,
                  disablePadding: false,
                  label: t('date'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {Functions.formatDateTime(new Date(value), 'yyyy-MM-dd')}
                      </>
                    );
                  }
                },
                {
                  id: 'type_code',
                  numeric: false,
                  disablePadding: false,
                  label: t('content'),
                  customRender: (value, row) => {
                    const type = Functions.getConstant(Constants.MT5.Deals.Type, value).this;

                    return (
                      <>
                        {t(type.name)}
                      </>
                    );
                  }
                },
                {
                  id: 'mt5_profit_deposit',
                  numeric: false,
                  disablePadding: false,
                  label: t('deposit'),
                  customRender: (value, row) => {
                    const profit = Functions.thousandSeparator(row.mt5_profit);

                    return (
                      <>
                        {row.type_code === Constants.MT5.Deals.Type.Deposit.code ? profit : ""}
                      </>
                    );
                  }
                },
                {
                  id: 'mt5_profit_withdrawal',
                  numeric: false,
                  disablePadding: false,
                  label: t('withdrawal'),
                  customRender: (value, row) => {
                    const profit = Functions.thousandSeparator(row.mt5_profit);

                    return (
                      <>
                        {row.type_code === Constants.MT5.Deals.Type.Withdrawal.code ? profit : ""}
                      </>
                    );
                  }
                },
                {
                  id: 'balance',
                  numeric: false,
                  disablePadding: false,
                  label: t('balance'),
                  customRender: (value, row) => {
                    return (
                      <>
                        {Functions.thousandSeparator(value)}
                      </>
                    );
                  }
                },
              ]}
              rows={transactions}
              usePagination
              paginationThreshold={50}
              idField="mt5_login"
              formik={formik}
            />

          </form>
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

TransactionPage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  transactions: makeSelectTransactions(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TransactionPage);
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectMailSent,
  reducer,
  saga,
} from './ForgotPage.reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './ForgotPage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';

const key = 'ForgotPage';

const ForgotPage = props => {
  const {
    loading,
    alert,
    mailSent,
    submitEmailRequest,
    submitPasswordRequest,
  } = props;

  const { t } = useTranslation();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container className="forgot">
          <h3>{t(key + '.title')}</h3>

          {
            !mailSent ?
            <FormEmailElement
              alert={alert}
              submitRequest={submitEmailRequest}
            /> :
            <FormPasswordElement
              alert={alert}
              submitRequest={submitPasswordRequest}
            />
          }

        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter 
        injectElement={injectElement}
        loading={loading}
        persistLayout="AppLayout"
      />
    </>
  );
};

ForgotPage.propTypes = {
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  mailSent: makeSelectMailSent(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(ForgotPage);

const FormEmailElement = props => {
  const {
    alert,
    submitRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email(t('Validation.invalid_email'))
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });


  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <Adapter.AlertAdapter alert={alert} />
        </div>

        <p>{t(key + '.0')}</p>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('email')}
            name="email"
            placeholder="youremail@email.com"
            variant="filled"
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            className="backButton"
            variant="contained"
            color="default"
            onClick={() => { window.top.close(); }}
          >
            {t('cancel')}
          </Button>

          <Button type="submit" variant="contained" color="primary">
            {t('send')}
          </Button>
        </div>
      </form>
    </>
  );
};

const FormPasswordElement = props => {
  const {
    alert,
    submitRequest,
  } = props;

  const { t } = useTranslation();
  
  const formik = useFormik({
    initialValues: {
      code: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      code: Yup
        .string()
        .required(t('Validation.required')),
      password: Yup
        .string()
        .required(t('Validation.required')),
      password_confirmation: Yup
        .string()
        .required(t('Validation.required'))
        .oneOf([Yup.ref('password'), null], t('Validation.password_not_match')),
    }),
    onSubmit: (values) => {
      submitRequest(values);
    },
    enableReinitialize: true,
  });


  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <Adapter.AlertAdapter alert={alert} />
        </div>

        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('code')}
            name="code"
            variant="filled"
            formik={formik}
          />
        </div>
        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="password"
            label={t('password')}
            name="password"
            variant="filled"
            formik={formik}
          />
        </div>
        <div className="form-input">
          <Adapter.TextFieldAdapter
            type="password"
            label={t('password_confirmation')}
            name="password_confirmation"
            variant="filled"
            formik={formik}
          />
        </div>

        <div className="action-group">
          <Button
            className="backButton"
            variant="contained"
            color="default"
            onClick={() => { window.top.close(); }}
          >
            {t('v_cancel')}
          </Button>

          <Button type="submit" variant="contained" color="primary">
            {t('v_reset')}
          </Button>
        </div>
      </form>
    </>
  );
};
import React from 'react';

import * as Functions from '../../utils/functions';

import './Avatar.scss';

const key = 'Avatar';

const Avatar = props => {
  const {
    path,
    scales,
  } = props;

  const imageScales = scales ? scales : 1;

  return (
    <>
      <section className={key}>
        <img
          className={"scales-" + imageScales}
          alt="avatar"
          src={Functions.getS3URL(path)}
          onError={(e) => {
            e.target.src = process.env.REACT_APP_NO_IMAGE_URL;
          }}
        />
      </section>
    </>
  );
};

export default Avatar;
import React from 'react';

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  SwipeableDrawer,
  Link,
} from '@mui/material'
import {
  Menu,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import './Header.scss';
import RegisterOptions from '../../components/RegisterOptions/RegisterOptions';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';

const key = 'Header';

const Header = props => {
  const {
    anchor,
    isToggled,
    user,
    setToggle,
    logoutChildRequest,
    showDialogChildRequest,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className={key}>
        <AppBar className="app-bar" position="static">
          <Toolbar>
            <Typography className="logo">
              <Link href="/">
                <img alt="logo" src="/images/logo.png" />
              </Link>
            </Typography>

            {
              user ?
                <Button
                  className="pc-el menu-btn"
                  color="default"
                  variant="contained"
                  onClick={logoutChildRequest}
                >
                  {t('logout')}
                </Button> :
                <>
                  <Button className="pc-el menu-btn" color="default" variant="contained" href="/login" target="_blank">
                    {t('login')}
                  </Button>
                  <Button
                    className="pc-el menu-btn"
                    color="default"
                    variant="contained"
                    onClick={() => showDialogChildRequest({
                      title: t('register'),
                      variant: "panel",
                      content: (<RegisterOptions />),
                    })
                    }
                  >
                    {t('register')}
                  </Button>
                </>
            }

            <LanguageSelector className="pc-el menu-btn" />

            <IconButton
              className="menu-button mb-el"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setToggle()}
            >
              <Menu />
            </IconButton>
            <SwipeableDrawer
              anchor={anchor ? anchor : "right"}
              open={isToggled}
              onClose={() => setToggle()}
              onOpen={() => setToggle()}
            >
            </SwipeableDrawer>

          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default Header;
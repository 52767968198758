import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectValues,
  makeSelectConfirming,
  makeSelectMt5Logins,
  reducer,
  saga,
} from './TraderCreatePage.reducer';
import {
  makeSelectActiveStep,
} from '../../components/adapters/StepperAdapter/StepperAdapter.reducer';
import {
  makeSelectFormData,
} from '../../containers/AuthProvider/AuthProvider.reducer';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import {
  Container,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './TraderCreatePage.scss';
import LayoutAdapter from '../../layouts/LayoutAdapter/LayoutAdapter';
import * as Adapter from '../../components/adapters';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import PriceField from '../../components/PriceField/PriceField';

const key = 'TraderCreatePage';

const TraderCreatePage = props => {
  const {
    loading,
    loadMt5LoginsRequest,
  } = props;

  const { t } = useTranslation();
  
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    loadMt5LoginsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectElement = (
    <>
      <Helmet>
        <title>{t(key + '.title')}</title>
        <meta name="description" content="システム" />
      </Helmet>
      <section className={key}>
        <Container>
          <h4>{t(key + '.title')}</h4>
          <Adapter.StepperAdapter
            steps={[
              t(key + '.step0'),
              t(key + '.step1'),
              t(key + '.step2'),
            ]}
            stepPanels={[
              <Panel0Element
                {...props}
              />,
              <Panel0Element
                {...props}
              />,
              <Panel1Element
                {...props}
              />,
            ]}
          />
        </Container>
      </section>
    </>
  );

  return (
    <>
      <LayoutAdapter injectElement={injectElement} loading={loading} />
    </>
  );
};

TraderCreatePage.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  values: makeSelectValues(),
  alert: makeSelectAlert(),
  formData: makeSelectFormData(),
  confirming: makeSelectConfirming(),
  activeStep: makeSelectActiveStep(),
  mt5Logins: makeSelectMt5Logins(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TraderCreatePage);

const Panel0Element = props => {
  const {
    formData,
    confirming,
    activeStep,
    mt5Logins,
    submitRequest,
    nextStepRequest,
    backStepRequest,
    setConfirmRequest,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: Functions.fillFormData({
      trader_kind_code: Constants.Trader.TraderKind.Trader.code,
      operation_method_code: Constants.Trader.OperationMethod.MAM.code,
      mt5_login: mt5Logins ? mt5Logins[0] : "",
      nickname: "",
      message: "",
      required_fund_unit: Constants.General.Currency.YEN.code,
      required_fund: "",
      success_fee: 30,
      avatar: "",
    }, formData),
    validationSchema: Yup.object({
      trader_kind_code: Yup
        .string()
        .required(t('Validation.required')),
      operation_method_code: Yup
        .string()
        .required(t('Validation.required')),
      mt5_login: Yup
        .string()
        .required(t('Message.mt5_login_not_existing')),
      nickname: Yup
        .string()
        .required(t('Validation.required')),
      message: Yup
        .mixed()
        .required(t('Validation.required')),
      required_fund: Yup
        .string()
        .required(t('Validation.required')),
      avatar: Yup
        .mixed()
        .required(t('Validation.required')),
    }),
    onSubmit: (values) => {
      if (!confirming) {
        nextStepRequest(values);
        setConfirmRequest(true);
      } else {
        submitRequest(values);
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form className="main-form" onSubmit={formik.handleSubmit}>
        {
          confirming ?
          <p>{t(key + '.panel0_0')}</p> :
          <p>{t(key + '.panel0_1')}</p>
        }

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t('register_type')}
            name="trader_kind_code"
            color="primary"
            row
            items={Functions.getConstant(Constants.Trader.TraderKind).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t('operation_method')}
            name="operation_method_code"
            color="primary"
            row
            items={Functions.getConstant(Constants.Trader.OperationMethod).inputList}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.RadioGroupAdapter
            label={t('mt5_login')}
            name="mt5_login"
            color="primary"
            row
            items={mt5Logins.map((item) => {
              console.log(item);
              return {
                name: item,
                value: item,
              }
            })}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        {
          !confirming &&
          <p>{t(key + '.panel0_2')}</p>
        }

        <div className="form-input one-third-width">
          <Adapter.TextFieldAdapter
            type="text"
            label={t('nickname')}
            name="nickname"
            variant="filled"
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input half-width">
          <Adapter.TextareaAdapter
            name="message"
            label={t('message')}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input half-width">
          <PriceField
            label={t('required_fund')}
            name="required_fund"
            formik={formik}
            viewMode={confirming}
          />
        </div>

        <div className="form-input">
          <Adapter.RangeAdapter
            name="success_fee"
            type="input"
            label={t('success_fee')}
            marks={Functions.getConstant(Constants.Trader.SuccessFeeMark).inputList}
            unit={"%"}
            viewMode={confirming}
            formik={formik}
          />
        </div>

        <div className="form-input">
          <Adapter.FileAdapter
            name="avatar"
            label={t('avatar')}
            accept="image/*"
            viewMode={confirming}
            formik={formik}
            descriptions={[
              t(key + '.f_avatar_0'),
              t(key + '.f_avatar_1'),
            ]}
          />
        </div>

        <div className="action-group">
          {
            activeStep === 1 &&
            <Button
              onClick={backStepRequest}
              className="backButton"
              variant="contained"
              color="default"
            >
              {t('v_back')}
            </Button>
          }

          <Button
              type="submit"
              variant="contained"
              color="primary"
          >
            {confirming ? t('v_application') : t('v_next')}
          </Button>
        </div>
      </form>
    </>
  );
};
const Panel1Element = () => {

  const { t } = useTranslation();

  return (
    <>
      <form className="main-form">
        <p>{t(key + '.panel1_0')}</p>
        <p>{t(key + '.panel1_1')}</p>
        <p>{t(key + '.panel1_2')}</p>
        <p>{t(key + '.panel1_3')}</p>

        <div className="action-group">
          <Button
            onClick={()=> {window.location.replace('/ranking')}}
            variant="contained"
            color="primary"
          >
            {t('v_close')}
          </Button>
        </div>
      </form>
    </>
  );
};
import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import history from '../../utils/history';
import request from '../../utils/request';
import {
  getAWSUser,
} from '../../utils/auth';
import { Auth } from 'aws-amplify';

import {
  // makeSelectLocation,
  loadUserRequest,
  setTempUser,
  changeAccessToken,
} from '../../containers/AuthProvider/AuthProvider.reducer';

const key = 'LoginPage';

// Action Creators
export const actionCreators = createActions(
  'SUBMIT_REQUEST',
  'SUBMIT_RESPONSE',
  'FAKE_LOGIN_REQUEST',
  {
    prefix: key,
  },
);

export const {
  submitRequest,
  submitResponse,
  fakeLoginRequest,
} = actionCreators;

// Reducer
export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  accessToken: false,
};

export const reducer = handleActions(
  {
    [submitRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
      loading: true,
      alert: false,
      result: false,
      accessToken: false,
    }),
    [submitResponse]: {
      next: (state, { payload }) => ({
        ...state,
        // result: payload.result,
        // accessToken: payload.result ? payload.data.access_token: false,
        // alert: !payload.result ? payload.alert : false,
        loading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload.alert,
        loading: false,
      }),
    },
    [fakeLoginRequest]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

// Selectors
const originSelector = state => state[key] || initialState;

const makeSelectValues = () =>
  createSelector(
    originSelector,
    state => state.values,
  );

const makeSelectLoading = () =>
  createSelector(
    originSelector,
    state => state.loading,
  );

const makeSelectAlert = () =>
  createSelector(
    originSelector,
    state => state.alert,
  );

const makeSelectResult = () =>
  createSelector(
    originSelector,
    state => state.result,
  );

const makeSelectAccessToken = () =>
  createSelector(
    originSelector,
    state => state.accessToken,
  );

export {
  originSelector,
  makeSelectValues,
  makeSelectLoading,
  makeSelectAlert,
  makeSelectResult,
  makeSelectAccessToken,
};


export function* submitAWS() {
  try {     
    const values = yield select(makeSelectValues());
    const data = yield call(
      [Auth, 'signIn'],
      values.email,
      values.password,
    );

    if (data) {
      const payload = getAWSUser(data);

      yield put(loadUserRequest());
      yield put(submitResponse(payload));

      if (payload.challengeName === "NEW_PASSWORD_REQUIRED") {
        yield put(setTempUser(payload));
        history.push('/change-password');
      }
      else {
        switch (payload.role) {
          case 'admin':
            history.push("/admin/user-list");
            break;
          case 'trader':
            history.push("/ranking");
            break;
          default:
            history.push("/ranking");
            break;
            // redirect to indented url
            // const location = yield select(makeSelectLocation());
            // history.replace(location);
        }
      }
    }
  } catch (e) {
    e.alert = {
      type: 'throw',
      message: 'メールアドレス又はパスワードが間違っています。',
    }
    yield put(submitResponse(e));
    console.log('Error', e);
  }
}

export function* fakeLogin() {
  const values = yield select(makeSelectValues());
  var accessToken = 'fake';
  switch (values.email) {
    case 'admin@gmail.com':
      accessToken = 'adminfake';
      break;
    case 'trader@gmail.com':
      accessToken = 'traderfake';
      break;
    default:
      accessToken = 'fake';
  }
  yield put(changeAccessToken(accessToken));
  yield put(submitResponse({
  }));

  history.push("/change-password");
}

export function* submit() {
  try {
    const values = yield select(makeSelectValues());
    const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/login';
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': "csrf-token",
      },
      body: JSON.stringify(values),
    };

    // hit api
    const data = yield call(request, requestURL, requestOptions);

    // handle response
    yield put(submitResponse(data));
    var result = yield select(makeSelectResult());

    if (result === true) {
      const accessToken = yield select(makeSelectAccessToken());
      yield put(changeAccessToken(accessToken));
    }
  } catch (e) {
    console.log('Error', e);
    yield put(submitResponse(e));
  }
}

export function* saga() {
  yield takeLatest(submitRequest, submitAWS);
  yield takeLatest(fakeLoginRequest, fakeLogin);
}
import React, { useEffect } from 'react';

import {
  Alert,
  IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const key = 'AlertAdapter';

const AlertAdapter = props => {
  const { alert } = props;

  const { t } = useTranslation();
  const [isOpen, setOpen] = React.useState(true);

  useEffect(() => {
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);
  

  return (
    <>
      {
        isMatched(alert) && isOpen &&
        <Alert
          className={key}
          severity={alert.type === 'success' ? 'success' : 'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {
            alert.code ? 
              t('Message.' + alert.code)
            : alert.message
          }
        </Alert>
      }
    </>
  );
};

function isMatched(alert) {
  return alert && alert.type !== "validate";
};

export default AlertAdapter;
import React from 'react';

import {
  Grid,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Footer.scss';

const Footer = props => {
  const { homeVariant } = props;
  const { t } = useTranslation();
  
  return (
    <>
      <section className="footer">
        {
          homeVariant &&
            <Grid container className="wrapper">
              <Grid className="item" item xs>
                <Link href="/about-service">{t('operating_company')}</Link>
                <Link href="https://gs-ltd.hk/" target="_blank">{t('about_gst_trade')}</Link>
              </Grid>
              <Grid className="item" item xs>
                <Link href="https://gs-ltd.hk/ja/privacy-policy/" target="_blank">{t('privacy_policy')}</Link>
                <Link href="https://gs-ltd.hk/ja/trading-execution-risk/" target="_blank">{t('trading_risk')}</Link>
              </Grid>
              <Grid className="item" item xs>
                <Link href="https://gs-ltd.hk/ja/aml-policy/" target="_blank">{t('aml_policy')}</Link>
                <Link href="https://gs-ltd.hk/risk-warning/" target="_blank">{t('risk_warning')}</Link>
              </Grid>
              <Grid className="item" item xs>
                <Link href="/faq">{t('faq')}</Link>
                <Link href="/contact" target="_blank">{t('contact')}</Link>
              </Grid>
            </Grid>
        }
        <p className="copyright text-center">© Copyright 2021 FTP Ltd.</p>
      </section>
    </>
  );
};

export default Footer;
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../../utils/injectors';
import {
  actionCreators,
  makeSelectValue,
  reducer,
  saga,
} from './TabAdapter.reducer';

import {
  Tabs,
  Tab,
} from '@mui/material';

import SwipeableViews from 'react-swipeable-views';

const key = "TabAdapter";

const TabAdapter = props => {
  const {
    data,
    initialIndex,
    centered,
    setValue,
    value,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    setValue(initialIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const direction = 'x-reverse'; // 'x'
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <section className={key}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          centered={centered}
          value={value}
          onChange={handleChange}
        >
          {data.map((item, index) => (
            <Tab key={index} label={item.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        <SwipeableViews
          axis={direction}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {data.map((item, index) => (
            <TabPanel key={index} value={value} index={index} dir={direction}>
              {item.panel}
            </TabPanel>
          ))}
        </SwipeableViews>
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  value: makeSelectValue(),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(TabAdapter);


function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};